<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<div class="k-main-collection">
		<div class="d-flex">
			<h2 class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-diagram-project</v-icon><b>{{site_config.resourcerepos_noun}} Repositories</b></h2>
			<v-spacer/>
			<v-btn v-if="signed_in" color="secondary" class="k-tight-btn k-nocaps-btn" @click="go_to_favorites"><v-icon small class="mr-2">fas fa-diagram-project</v-icon>Favorite Collections</v-btn>
		</div>
		<div v-show="initialized">
			<div v-if="!new_lp">
				<div v-if="collection_lps.length==0">There are currently no {{site_config.resourcerepos_noun}} Repositories available.</div>
				<div v-if="collection_lps.length>0" style="width:500px; margin:auto;"><v-text-field v-model="search_string" prepend-inner-icon="fa fa-search" rounded clearable clear-icon="fa fa-times-circle" label="Search repository titles…" single-line hide-details outlined dense background-color="#fff"></v-text-field></div>

				<div class="k-main-collection-body">
					<div v-for="(lp) in collection_lps_to_show" @click="go_to_lp(lp)" class="k-elevated k-main-collection-item text-left k-lp-tile" :class="lp_css(lp)" v-html="lp_title(lp)"></div>
				</div>

			</div>

			<div v-if="!new_lp&&is_repo_admin&&!small_screen" class="mt-4">
				<v-btn large class="k-tight-btn k-nocaps-btn" @click="create_lp('repo')"><v-icon class="mr-3 ml-6">fas fa-diagram-project</v-icon><span class="mr-6">Create a New Resource Repository</span></v-btn>
				<v-btn large class="k-tight-btn k-nocaps-btn ml-4" @click="create_lp('pd')"><v-icon class="mr-3 ml-6">fas fa-user-graduate</v-icon><span class="mr-6">Create a New PD Repository</span></v-btn>
			</div>

			<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null"></CollectionEdit>

		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from '../collections/CollectionEdit'
import MiniNav from '../main/MiniNav'

export default {
	components: { CollectionEdit, MiniNav },
	data() { return {
		initialized: false,
		search_string:'',
		search_terms: [],
		new_lp: null,
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['small_screen', 'signed_in', 'studentish_role']),
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		collection_lps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
				if (lp.active == 'no') {
					if (!lp.user_can_view_lp()) return false		// && !this.is_repo_admin
				}
				
				// only include collection_types 'repo' and 'pd' here...
				if (!['repo', 'pd'].includes(lp.collection_type)) return false

				// and parents can only see repos that are designed for viewing by them
				if (this.studentish_role) {
					if (lp.collection_type == 'pd') return false
				}

				return true
			})
			return arr
		},
		collection_lps_to_show() {
			let arr = this.collection_lps.filter(lp => {
				// if we have repositories_to_include, limit to those
				if (this.site_config.repositories_to_include && !this.site_config.repositories_to_include.includes(lp.course_code)) {
					// but *DO* include repositories updated after include_lps_updated_after (if set), even if they aren't in the list to include
					if (empty(this.site_config.include_lps_updated_after) || lp.updated_at < this.site_config.include_lps_updated_after) {
						return false
					}
				}

				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				return true
			})

			arr.sort((a,b)=>{
				// // featured collections first -- NO, not using featured in Inspire for now
				// if (a.featured && !b.featured) return -1
				// if (b.featured && !a.featured) return 1

				// // now sequence if there
				// if (a.sequence != 0 && b.sequence == 0) return -1
				// else if (b.sequence != 0 && a.sequence == 0) return 1
				// else if (a.sequence != b.sequence) return a.sequence - b.sequence

				// // if both are featured, most-recently-featured first
				// if (a.featured && b.featured) return b.featured - a.featured

				// default sort by title
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		is_repo_admin() {
			return vapp.has_admin_right('repo')
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		}
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses', 'initial').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
					// console.log('all_courses', this.all_courses)
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	methods: {
		go_to_favorites() {
			window.history.replaceState(null, '', '/repos')
			this.$store.commit('lst_set', ['index_view_flavor', 'favorites'])
		},

		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			let s = U.subject_tile_css(lp)

			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}

			return s
		},

		lp_title(lp) {
			let s = ''
			s += '<div>'
			// start with icon indicating collection type
			if (lp.collection_type == 'course') s += '<i class="fas fa-chalkboard"></i>'
			else if (lp.collection_type == 'repo') s += '<i class="fas fa-diagram-project"></i>'
			else if (lp.collection_type == 'pd') s += '<i class="fas fa-user-graduate"></i>'
			else s += '<i class="fas fa-cubes-stacked"></i>'
			// add <wbr> tags after slashes
			s += lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'

					// s += ' <b class="red--text">[Inactive]</b>'
				}
			s += '</div>'

			return s
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: lp.vue_route() })
		},

		create_lp(collection_type) {
			// lps created from here are definitionally agency_sanctioned, and use the "tree" layout
			this.new_lp = new Learning_Progression({
				subject:'', 
				agency_sanctioned: true, 
				lp_layout: 'tree',
				collection_type: collection_type,
				active: 'no',
				use_unit_numbers: false,
				units: [new LP_Unit()],
			})

			return
		},
	}
}
</script>

<style lang="scss">

.k-main-collection-sub-collection {
	border-radius: 40px;
	padding:4px;
	background-color:rgba(255, 255, 255, 0.5);
	margin-bottom:16px;
	display:inline-block;	// make it shrink to fit whatever is inside of it
	min-width:460px;
}

.k-main-collection-sub-collection-featured {
	background-color:rgba(255, 240, 200, 0.5);
	border:0;
}

.k-main-collection--course-showing {
	margin:0!important;
	padding:0px 10px!important;

	.k-lp-tile {
		width:100px!important;
		height:100px!important;
		opacity:0.6;
		margin:10px;
		background-color:#f8f8f8;
		-webkit-box-shadow: 0 0 0 0 !important;
	    box-shadow: 0 0 0 0 !important;
		font-size:12px!important;
		line-height:14px!important;
		font-weight:normal!important;
	}

	.k-lp-tile--repos-showing, .k-lp-tile--pd-showing {
		font-weight:900!important;
		background-color:#eee;
		opacity:1.0;
		-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.k-main-collection-sub-collection {
		-webkit-box-shadow: none!important;
		box-shadow:none!important;
		background-color:transparent;
		padding:0;
		margin-bottom:0;
		border:0px;
	}
}

</style>
