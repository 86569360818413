<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-collection k-home-home-wrapper">
	<h2 class="k-page-title d-flex">
		<v-icon large color="primary" class="mr-4" style="margin-top:-2px">fas fa-home</v-icon>
		<b>Home</b>
		<v-spacer/>
	</h2>

	<div class="k-home-home-wrapper-inner">
		<div class="fr-view" v-html="$store.state.home_page_content"></div>
		<div v-if="oidc_info" class="mt-3 pt-2 mb-1" style="border-top:1px solid #ccc; font-size:16px;" v-html="oidc_info"></div>
		<div v-if="!signed_in&&site_config.show_banner_signin_btn=='no'" class="text-center mt-4 mb-2"><v-btn color="primary" @click="sign_in">Sign In to {{ site_config.app_name }}<v-icon small class="ml-2">fas fa-sign-in</v-icon></v-btn></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: {  },
	data() { return {
		initialized: false,
		grade: '',
		subject: '',
		search_string:'',
		search_terms: [],
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters(['signed_in']),
		oidc_info() {
			let s = ''
			if (this.user_info.first_name) s += sr('<div>You are signed in as $1 $2 ($3)</div>', this.user_info.first_name, this.user_info.last_name, this.user_info.email)
			
			if (this.site_config.show_user_system_data_on_home == 'yes') {
				s += '<ul class="ml-4">'
				if (this.user_info.oidc_data?.system_name) s += sr('<li>School system: $1</li>', this.user_info.oidc_data.system_name)
				if (this.user_info.oidc_data?.school_name) s += sr('<li>School: $1</li>', this.user_info.oidc_data.school_name)
				if (this.user_info.oidc_data?.user_role) s += sr('<li>Role: $1</li>', this.user_info.oidc_data.user_role)
				if (this.user_info.first_name) s += '</ul>'
			}
			return s
		}
	},
	watch: {
	},
	created() {
		// retrieve the home_page_content, if necessary
		this.$store.dispatch('get_home_page_content')
	},
	mounted() {
	},
	methods: {
		sign_in($evt) {
			// if user holds down shift and cmd (mac, prob. alt on windows), always show native cureum login; this allows, e.g., for us to sign in using the magic password to Inspire
			if ($evt && $evt.shiftKey && $evt.metaKey) {
				vapp.app_mode = 'login'

			} else {
				vapp.redirect_to_login()
			}
		},
	}
}
</script>

<style lang="scss">
.k-home-home-wrapper {
	min-height:300px;
}

.k-home-home-wrapper-inner {
	max-width:920px;
	margin:0 auto;
	text-align:left;
	p {
		max-width:800px;
	}

	.fr-view {
		font-size:18px;
		line-height:1.4em;
	}
}

.k-home__logo-img {
	width:300px;
	float:right;
	margin-left:12px;
}

.k-home-tassle-logo {
	font-family: var(--agency-name-font-family);
	font-size: 24px;
	font-weight:bold;
	color:$v-doe-hot-pink;
	background: -webkit-linear-gradient($v-doe-orange, $v-doe-hot-pink);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
</style>
