<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" :max-width="dialog_max_width" persistent scrollable>
	<v-card class="k-resource-editor-outer">

		<v-card-title style="border-bottom:1px solid #999"><b style="font-weight:900" v-html="header_text"></b></v-card-title>

		<v-card-text class="pt-3" style="background-color:#eee">
			<div v-if="is_new_resource&&enable_resource_search">
				<div style="border-bottom:1px solid #999;" class="pb-4 mb-4">
					<div class="d-flex align-center mb-2 grey--text text--darken-3" style="font-size:16px;" :style="selected_resource_pool=='new'?'opacity:0.7':''">
						<div class="mr-2"><b><nobr>Choose an existing resource from:</nobr></b></div>
						<v-select v-model="selected_resource_pool" :items="resource_selector_pool_items" label="" outlined hide-details dense background-color="#fff" @change=""></v-select>
						<v-icon color="light-blue" class="ml-2" @click="resource_id_tip">fas fa-circle-question</v-icon>
					</div>
					<div class="d-flex align-center" v-if="selected_resource_pool&&selected_resource_pool!='new'">
						<v-autocomplete v-model="selected_resource" prepend-inner-icon="fas fa-search" :items="resource_selector_items" :filter="resource_selector_filter" :search-input.sync="autocomplete_search" label="Find a resource to use…" :no-data-text="selected_resource_no_data_text" outlined hide-details dense background-color="#fff" @change="existing_resource_selected"></v-autocomplete>
						<v-btn small color="secondary" class="ml-2" @click="preview_selected_resource" :disabled="!selected_resource">Preview<v-icon small class="ml-1">fas fa-up-right-from-square</v-icon></v-btn>
					</div>
				</div>
				<div v-if="selected_resource_pool!='new'" class="mt-6 text-center grey--text text--darken-3" style="font-size:16px" :style="(selected_resource_pool!='new'&&selected_resource_pool!='')?'opacity:0.7':''"><b class="mr-2">OR</b> <v-btn small color="primary" @click="selected_resource_pool='new'"><v-icon small class="mr-2">fas fa-plus</v-icon>Add a new resource</v-btn></div>
			</div>

			<div v-if="!is_new_resource||selected_resource_pool=='new'||!enable_resource_search" class="mt-4">
				<div class="d-flex align-center mb-2">
					<b v-if="is_new_resource" class="grey--text text--darken-3 mr-2">New Resource:</b>
					<v-select v-model="edited_resource.type" :items="resource_types" label="Type" tabindex="1" outlined hide-details dense background-color="#fff" style="max-width:150px" @change="resource_type_selected"></v-select>
					<v-text-field v-show="resource_type!='none'&&resource_type!='html'" hide-details outlined clearable :label="url_field_label" v-model="edited_resource.url" background-color="#fff" dense tabindex="2" class="ml-2"></v-text-field>
					<v-btn v-show="resource_type=='upload'" @click="upload_file" class="ml-2" fab small color="light-blue" dark><v-icon>fas fa-file-upload</v-icon></v-btn>
				</div>

				<v-textarea v-show="resource_type!='none'" class="my-4" hide-details outlined label="Resource title/description" v-model="edited_resource.description" background-color="#fff" auto-grow dense rows="1" tabindex="3"></v-textarea>

				<div v-if="resource_type=='assessment'" class="d-flex mb-2">
					<v-text-field hide-details outlined clearable label="Assessment Blueprint URL (optional)" class="mr-1" v-model="blueprint_url" background-color="#fff" dense></v-text-field>
					<v-text-field hide-details outlined clearable label="Teacher Test Booklet URL (optional)" class="ml-1" v-model="paper_url" background-color="#fff" dense></v-text-field>
				</div>

				<div class="my-4"><froala-wrapper v-show="resource_type=='html'" :config="{placeholderText: 'Enter text here', zIndex: 1000}" v-model="resource_html" /></div>

				<div class="my-4 d-flex justify-center align-center flex-wrap">
					<div v-if="show_teacher_facing_control"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="edited_resource.teacher_facing" mandatory>
						<v-btn small light :value="false" style="letter-spacing:0" @click.stop="set_teacher_facing_default(false)">Student-Facing</v-btn>
						<v-btn small light :value="true" style="letter-spacing:0" @click.stop="set_teacher_facing_default(true)">Teacher-Facing</v-btn>
					</v-btn-toggle></div>

					<v-btn small color="#666" dark class="ml-4 k-tight-btn" @click="additional_metadata_showing=!additional_metadata_showing">Additional Tagging Options <v-icon small class="ml-2">fas fa-caret-{{additional_metadata_showing?'up':'down'}}</v-icon></v-btn>
				</div>

				<div class="my-4 d-flex justify-center align-center flex-wrap" v-if="additional_metadata_showing&&show_teacher_facing_control">
					<div v-if="!edited_resource.teacher_facing">
						Target Students:
						<v-menu bottom left>
							<template v-slot:activator="{on}"><span v-on="on" class="ml-1">
								<v-btn small v-if="edited_resource.target_students=='all'" color="amber lighten-2">All Students</v-btn>
								<v-btn small v-if="edited_resource.target_students=='ese'" dark color="teal">ESE Students</v-btn>
								<v-btn small v-if="edited_resource.target_students=='adv'" dark color="purple">Advanced Learners</v-btn>
							</span></template>
							<v-list dense>
								<v-list-item @click="edited_resource.target_students='all'"><v-list-item-title>All Students</v-list-item-title></v-list-item>
								<v-list-item @click="edited_resource.target_students='ese'"><v-list-item-title>ESE Students</v-list-item-title></v-list-item>
								<v-list-item @click="edited_resource.target_students='adv'"><v-list-item-title>Advanced Learners</v-list-item-title></v-list-item>
							</v-list>
						</v-menu>
					</div>

					<div>
						<v-menu bottom left>
							<template v-slot:activator="{on}"><span v-on="on" class="ml-3">
								<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category==''">Special Resource Category…</v-btn>
								<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='unit_planning'" dark color="green">Unit Planning Resource</v-btn>
								<!-- <v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='course_guidance'" dark color="cyan">Resources for Course Guidance</v-btn> -->
								<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='stem_resource'" dark color="lime">STEM Resource</v-btn>
								<v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='leader_resource'" dark color="brown">Leader Resource</v-btn>
								<!-- <v-btn small class="k-tight-btn k-nocaps-btn" v-if="edited_resource.lp_category=='course_ebook'" dark color="indigo">Course Ebook</v-btn> -->
							</span></template>
							<v-list dense>
								<v-list-item @click="edited_resource.lp_category=''"><v-list-item-title>-- NONE --</v-list-item-title></v-list-item>
								<v-list-item @click="edited_resource.lp_category='unit_planning'"><v-list-item-title>Unit Planning Resource</v-list-item-title></v-list-item>
								<v-list-item @click="edited_resource.lp_category='leader_resource'"><v-list-item-title>Leader Resource</v-list-item-title></v-list-item>
								<!-- <v-list-item @click="edited_resource.lp_category='course_guidance'"><v-list-item-title>Resources for Course Guidance (shown in all units)</v-list-item-title></v-list-item> -->
								<v-list-item @click="edited_resource.lp_category='stem_resource'"><v-list-item-title>STEM Resource</v-list-item-title></v-list-item>
								<!-- <v-list-item @click="edited_resource.lp_category='course_ebook'"><v-list-item-title>Course Ebooks (shown in all units)</v-list-item-title></v-list-item> -->
							</v-list>
						</v-menu>
					</div>
					<!-- <div v-if="unit&&edited_resource.teacher_facing">
						<div class="mx-4"><v-checkbox class="mt-0 pt-0" :label="'Unit planning resource'" v-model="unit_planning" hide-details></v-checkbox></div>
					</div> -->
				</div>

				<div v-if="additional_metadata_showing" class="d-flex my-4">
					<v-autocomplete chips deletable-chips multiple small-chips outlined background-color="#fff" hide-details class="mb-0 pb-0 mr-1" label="Subject(s)" placeholder="(type to search tags)" :items="subject_tag_options" v-model="subject_mappings"></v-autocomplete>
					<v-autocomplete chips deletable-chips multiple small-chips outlined background-color="#fff" hide-details class="mb-0 pb-0 mx-1" label="Grade(s)" placeholder="(type to search tags)" :items="grade_tag_options" v-model="grade_mappings"></v-autocomplete>
					<v-autocomplete chips deletable-chips multiple small-chips outlined background-color="#fff" hide-details class="mb-0 pb-0 ml-1" label="Course(s)" placeholder="(type to search tags)" :items="course_tag_options" v-model="course_mappings"></v-autocomplete>
				</div>

				<!-- STANDARDS -->
				<div v-if="additional_metadata_showing">
					<draggable v-bind="drag_options" v-model="edited_resource.standards" @end="drag_complete" class="d-flex flex-wrap align-center my-1">
						<div><v-icon class="mr-1" small style="margin-top:-3px" color="primary">fas fa-bullseye</v-icon><b style="font-size:16px">Standards:</b></div>
						<v-btn small color="green darken-3" outlined class="mx-2" @click="align_to_standard(null)"><v-icon small class="mr-1">fas fa-plus</v-icon> Add</v-btn>
						<CASEItemBtn v-for="(s) in edited_resource.standards" :key="s.identifier" :item="s" @click="align_to_standard(s)" @delete="remove_standard(s)" small show_move_icon show_delete_icon :outer_class="'ma-1'" />
					</draggable>
				</div>

			</div>
			<div v-if="course.collection_type === 'pd'" class="k-inline-checkbox-holder"><v-checkbox class="ma-0 pa-0" :label="'Require Completion'" v-model="edited_resource.todo" hide-details tabindex="5"></v-checkbox></div>
		</v-card-text>
		<v-card-actions class="pa-3" style="border-top:1px solid #999">
			<v-btn small class="mr-2" color="secondary" @click="$emit('edit_resource_cancel')" tabindex="6"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-spacer/>
			<v-btn v-if="selected_resource||resource_type!='none'" small color="primary" @click="save_resource" tabindex="5"><v-icon small class="mr-2">fas fa-check</v-icon>{{save_btn_text}}</v-btn>
		</v-card-actions>

	</v-card>
</v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	components: { CASEItemBtn, draggable },
	props: {
		lesson: { type: Object, required: false, default() { return null } },
		unit: { type: Object, required: false, default() { return null } },
		course: { type: Object, required: false, default() { return null } },

		original_resource: { required: true },	// if this is type string, we're creating a new resource
		enable_resource_search: { type: Boolean, required: false, default() { return true } },
	},
	data() { return {
		dialog_open: true,
		edited_resource: null,
		subject_mappings: [],
		grade_mappings: [],
		course_mappings: [],
		resource_html: '',

		// the following 2 are used for assessments
		blueprint_url: '',
		paper_url: '',

		uploaded_file: null,
		uploaded_file_name: '',

		selected_resource_pool: '',
		selected_resource: null,
		selected_resource_no_data_text: '',

		autocomplete_loading: false,
		autocomplete_search: '',
		autocomplete_items: [],

		drag_options: {
			animation: 200,
			handle: ".k-move-handle",
		}
	}},
	computed: {
		...mapState(['all_courses', 'site_config']),
		additional_metadata_showing: {
			get() { return this.$store.state.lst.resource_editor_additional_metadata_showing },
			set(val) { this.$store.commit('lst_set', ['resource_editor_additional_metadata_showing', val]) }
		},
		resource_editor_case_framework_identifier: {
			get() { return this.$store.state.lst.resource_editor_case_framework_identifier },
			set(val) { this.$store.commit('lst_set', ['resource_editor_case_framework_identifier', val]) }
		},
		resource_types() {
			let arr = []
			if (this.site_config.file_storage_system == 'equella') {
				arr.push({ value: 'equella', text: 'OpenEQUELLA Resource' })
			}
			arr.push({ value: 'website', text: 'Website' })
			arr.push({ value: 'document', text: 'Web document (e.g. Google Drive, OneDrive, etc.)' })
			arr.push({ value: 'video', text: 'Video' })
			arr.push({ value: 'interactive', text: 'Interactive/Simulation' })
			arr.push({ value: 'upload', text: 'Uploaded File' })
			arr.push({ value: 'html', text: `Text (entered in ${this.site_config.app_name})` })
			return arr
		},
		dialog_max_width() {
			if (!this.edited_resource) return 640
			if (this.edited_resource.type == 'html') return 820
			if (this.additional_metadata_showing) return 820
			return 640
		},
		is_new_resource() { 
			// new resources will be marked with an `is_new_resource` flag. but this flag won't be propagated in edited_resource, because it's not part of the Resource class constructor
			return this.original_resource.is_new_resource 
		},
		resource_type() { return this.edited_resource.type },
		header_text() { return this.is_new_resource ? 'Add Resource' : 'Edit Resource' },
		save_btn_text() { return this.is_new_resource ? 'Add Resource' : 'Save Resource' },
		subject_tag_options() {
			let arr = [{value: 'subject-all', text: 'All subjects'}]
			for (let s in this.$store.state.subjects) {
				arr.push({value: ('subject-' + s).toLowerCase(), text: s})
			}
			return arr
		},
		grade_tag_options() {
			let arr = [
				{value: 'grade-all', text: 'All grades'},
				{value: 'grade-elem', text: 'Grades K-5'},
				{value: 'grade-mid', text: 'Grades 6-8'},
				{value: 'grade-high', text: 'Grades 9-12'},
			]
			for (let g of this.$store.state.grades) {
				arr.push({value: ('grade-' + g).toLowerCase(), text: 'Grade ' + g})
			}
			return arr
		},
		course_tag_options() {
			let arr = []
			for (let lp of this.all_courses) {
				if (lp.collection_type !== 'pd') arr.push({value: 'course-' + lp.course_code, text: U.html_to_text(lp.title)})
			}
			arr.sort((a,b)=>{
				if (a.text < b.text) return -1
				if (a.text > b.text) return 1
				return 0
			})

			return arr
		},
		url_field_label() {
			if (this.resource_type == 'sparkl') return 'Sparkl Activity ID'
			if (this.resource_type == 'upload') return 'Resource File Identifier'
			if (this.resource_type == 'assessment') return 'Illuminate Assessment ID'
			return 'Resource URL'
		},
		resource_selector_pool_items() {
			let arr = []
			if (this.lesson) arr.push({value:'lesson', text: 'Resources tagged to this lesson'})
			if (this.unit) arr.push({value:'unit', text: 'Resources tagged to this unit'})
			if (this.course) arr.push({value:'course', text: 'Resources tagged to this course'})
			if (true) arr.push({value:'all', text: `All resources tagged in ${this.site_config.app_name}`})
			return arr
		},
		resource_selector_items() {
			// this fn is for traversing a resource collection generated from a common cartridge (i.e. HMH resources in HenryConnects)
			function traverse(resource_arr, collection_id, node, collection_inclusions, parent_included) {
				// we assume here that each node is *either* a resource (where the resource_id is specified by 'r') or a "folder" (with children in 'c')
				if (!empty(node.r)) {
					// if this resource's parent is included, include the resource
					if (parent_included) resource_arr.push(new Resource({
						resource_id: node.r,
						type: 'collection_item',
						teacher_facing: (node.i == 1),	//
						district_sanctioned: true,
						description: node.t,
					}))

				} else if (!empty(node.c)) {
					// this is a folder; if it is either implicitly or explicitly included, its child resources should be included
					let node_included_explicitly = empty(collection_inclusions) || !empty(collection_inclusions.find(x=>x==node.f))	// node.f = identifier, from CC file
					let node_included_implicitly = !node_included_explicitly && parent_included

					for (let child of node.c) {
						// traverse child; parent_included is true if nn is either explicitly or implicitly included
						traverse(resource_arr, collection_id, child, collection_inclusions, (node_included_explicitly || node_included_implicitly))
					}
				}
			}


			let arr = []
			if (this.selected_resource_pool == 'lesson') {
				for (let r of this.lesson.resources) {
					let item = {value: r, text: sr('$1 ($2)', r.description, r.type_label())}
					item.text_lc = item.text.toLowerCase()
					arr.push(item)
				}

			} else if (this.selected_resource_pool == 'unit') {
				for (let r of this.unit.resources) {
					let item = {value: r, text: sr('$1 ($2)', r.description, r.type_label())}
					item.text_lc = item.text.toLowerCase()
					arr.push(item)
				}

				// add common cartridge items
				for (let rc of this.course.resource_collections) {
					let rcis = this.unit.resource_collection_inclusions[rc.resource_id]
					if (!empty(rcis)) {
						// traverse to get a filtered version of these resources, pulling in items for this unit from unit.resource_collection_inclusions
						let col_arr = []
						traverse(col_arr, rc.resource_id, rc.collection_json, rcis, false)
						for (let r of col_arr) {
							let item = {value: r, text: sr('TEXTBOOK: $1', r.description)}
							item.text_lc = item.text.toLowerCase()
							arr.push(item)
						}
					}
				}

			} else if (this.selected_resource_pool == 'course') {
				for (let unit of this.course.units) {
					for (let r of unit.resources) {
						let item = {value: r, text: sr('$1: $2 ($3)', unit.title, r.description, r.type_label())}
						item.text_lc = item.text.toLowerCase()
						arr.push(item)
					}
				}

				// add common cartridge items
				for (let rc of this.course.resource_collections) {
					let col_arr = []
					traverse(col_arr, rc.resource_id, rc.collection_json, null, true)
					for (let r of col_arr) {
						let item = {value: r, text: sr('TEXTBOOK: $1', r.description)}
						item.text_lc = item.text.toLowerCase()
						arr.push(item)
					}
				}

			} else if (this.selected_resource_pool == 'all') {
				// results of live search
				return this.autocomplete_items
			}

			// easy natural sort algorithm that actually seems to work!
			// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
			arr.sort((a, b) => a.text.localeCompare(b.text, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}))

			return arr
		},
		autocomplete_search_lc() {
			let s = this.autocomplete_search.replace(/[^\w-]/g, '')
			return s.toLowerCase()
		},

		// checkbox for "Unit planning resource" -- only used when adding/editing resources aligned to units
		unit_planning: {
			get() { return this.edited_resource.lp_category == 'unit_planning' },
			set(val) {
				if (val) this.edited_resource.lp_category = 'unit_planning'
				else this.edited_resource.lp_category = ''
			}
		},
		show_teacher_facing_control() {
			if (this.course.collection_type === 'pd') return false
			return true
		}
	},
	watch: {
		autocomplete_search(val) {
			// if user pastes in a link, shrink to the resource_id
			if (this.autocomplete_search && this.autocomplete_search.search(/\/resource\/(.*)/) > -1) {
				this.autocomplete_search = RegExp.$1
			}

			// we only do autocomplete if selected_resource_pool == 'all'
			if (!this.selected_resource_pool == 'all') return

			// items have already been requested
			if (this.autocomplete_loading) return

			// don't search until we have X characters; then if the value drops back below X characters, clear autocomplete_items so we'll search again when we get back to X
			if (!val || val.length < 4) {
				this.selected_resource_no_data_text = val ? 'Type at least 4 characters to search' : 'Type to search'
				this.autocomplete_items = []
				return
			} else if (this.autocomplete_items.length > 0 && val.length > 4) {
				// if we don't have any items, the search service may have overloaded, so keep trying if the user keeps typing
				return
			}
			this.selected_resource_no_data_text = 'Searching…'

			// if we get to here, search for items
			this.autocomplete_loading = true
			let payload = {
				user_id: vapp.user_info.user_id,
				return_abbrev: false,
				stem: val,
			}
			U.ajax('resource_stem_search', payload, result=>{
				if (result.entries.length == 0) {
					if (result.overflow) this.selected_resource_no_data_text = 'Type more characters to narrow search results'
					else this.selected_resource_no_data_text = 'No resources found'
				} else {
					this.selected_resource_no_data_text = 'xxx'	// this shouldn't ever be seen
				}

				// parse results, which should be in result.entries
				let arr = []
				for (let entry of result.entries) {
					let r = new Resource(entry)
					let description = sr('$1 ($2)', r.description, r.type_label())

					// avoid duplicates
					if (!arr.find(x=>x.text == description)) {
						arr.push({value: r, text: description, text_lc: description.toLowerCase()})
					}
				}
				this.autocomplete_items = arr
				this.autocomplete_loading = false
			})
		},
	},
	created() {
		if (this.enable_resource_search == false) this.selected_resource_pool = 'new'
		
		// console.log('ResourceEditor', this.original_resource, this.is_new_resource)
		// create edited_resource, based on original_resource (if we received one)
		this.edited_resource = new Resource(this.original_resource)

		// TODO: ???
		// retrieve resource_html if we should already have it
		if (this.edited_resource.type == 'html' && !empty(this.edited_resource.url)) {
			let html_url = '/user-files/' + this.edited_resource.url
			$.ajax({
				url: html_url,
				success: (html)=>{
					this.resource_html = html
				},
				cache: false,
			})
		}

		// split mappings into groups for editing
		for (let m of this.edited_resource.mappings) {
			if (m.indexOf('subject-') == 0) this.subject_mappings.push(m)
			else if (m.indexOf('grade-') == 0) this.grade_mappings.push(m)
			else if (m.indexOf('course-') == 0) this.course_mappings.push(m)
		}

		// for assessment, get blueprint_url and paper_url if there
		if (this.edited_resource.type == 'assessment') {
			let bp = this.edited_resource.supp_links.find(x=>x.type=='blueprint')
			if (!empty(bp)) this.blueprint_url = bp.url

			let p = this.edited_resource.supp_links.find(x=>x.type=='paper')
			if (!empty(p)) this.paper_url = p.url
		}
	},
	mounted() {
	},
	methods: {
		editor_config(text) {
			return U.get_froala_config({
				placeholderText: 'Enter text here',
				zIndex: 2501,	// this is needed to show the image editor properly, because it's in a dialog
			})
		},

		resource_selector_filter(item, queryText, itemText) {
			// console.log(item, this.autocomplete_search)
			// https://henryconnects.henry.k12.ga.us/reslink/RESOURCE_6_SCI_GA19ED_ESE_G03U00L00_001_EN
			return item.text_lc.indexOf(this.autocomplete_search_lc) > -1 || (this.autocomplete_search && this.autocomplete_search.indexOf(item.value.resource_id) > -1)
		},

		resource_id_tip() {
			let msg = `You can also add a link to any resource, from anywhere in ${this.site_config.app_name}, by:<ul class="mt-2"><li>Clicking the kebab (<i class="fas fa-ellipsis-v"></i>) icon on the resource and choosing “Copy link to clipboard”</li><li>In this interface, choosing “All resources tagged in ${this.site_config.app_name} and pasting the resource link in the “Find a resource to use...” box.</li></ul>`
			this.$alert({title:'Tip', text:msg, dialogMaxWidth:600})
		},

		upload_file() {
			// prompt for file to import
			this.$prompt({
				text: '<p>Select the file you’d like to upload:</p>',
				promptType: 'file',
				acceptText: 'Choose File',
				cancelText: 'Cancel'
			}).then(file => {
				// console.log(file)
				// TODO: check for appropriate file types -- file.type
				// TODO: check for file size -- file.size
				// make sure file has an extension
				if (file.name.search(/.*(\.\w+)$/) == -1) {
					this.$alert('Uploaded files must have a valid file name extension, such as “.pdf” or “.jpg”')
					return
				}

				this.uploaded_file = file
				this.uploaded_file_name = file.name.replace(/.*(\.\w+)$/, U.new_uuid() + '$1')

				// set url to this.uploaded_file_name
				this.edited_resource.url = this.uploaded_file_name

				// if description is empty, set to the actual file name
				if (empty(this.edited_resource.description)) {
					this.edited_resource.description = file.name
				}

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		set_teacher_facing_default(new_default) {
			this.$store.commit('lst_set', ['resource_editor_teacher_facing', new_default])
			// U.local_storage_set('district_portal_default_teacher_facing', new_default)
		},

		resource_type_selected() {
			if (this.resource_type == 'upload') {
				this.upload_file()
			}
		},

		preview_selected_resource() {
			vapp.open_resource_link(this.selected_resource)
		},

		existing_resource_selected() {
			console.log('existing_resource_selected', this.selected_resource)
		},

		///////////////////////////////////
		// Standards
		align_to_standard(start_item) {
			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// if we have a resource_editor_case_framework_identifier, send it
				if (this.resource_editor_case_framework_identifier) data.framework_identifier = this.resource_editor_case_framework_identifier
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// we could set some kind of default here...
			}

			// add current lesson standards as selected items
			if (this.edited_resource.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.edited_resource.standards) data.selected_items.push(standard.identifier)
			}

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($('.k-resource-editor-outer').length == 0) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
						// if we already have this item aligned, remove the standard
						let i = this.edited_resource.standards.findIndex(o=>o.identifier==aligned_item.cfitem.identifier)
						if (i > -1) {
							this.edited_resource.standards.splice(i, 1)
							// re-initialize the chooser, showing the framework for the item we removed
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})

						} else {
							// Add the standard and the framework_identifier. use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
							let o = new CASE_Item(aligned_item.cfitem)
							o.framework_identifier = aligned_item.framework_identifier
							this.edited_resource.standards.push(o)
							// re-initialize the chooser, showing the framework for the item we added
							this.align_to_standard({framework_identifier: aligned_item.framework_identifier})
						}
					})
				})
			})
		},

		remove_standard(s, confirmed) {
			console.log(s)
			let i = this.edited_resource.standards.findIndex(o=>o.identifier==s.identifier)
			if (i > -1) {
				if (!this.confirmed_standard_delete && !confirmed) {
					this.$confirm({
						title: 'Remove Standard Alignment',
						text: 'Are you sure you want to remove this standard alignment from the Lesson Plan?',
						acceptText: 'Remove',
						acceptColor: 'red',
					}).then(y => {
						this.remove_standard(s, true)
						this.confirmed_standard_delete = true
					}).catch(n=>{console.log(n)}).finally(f=>{})
					return
				}

				this.edited_resource.standards.splice(i, 1)
			}
		},

		drag_complete(evt) {
			// standards will have been reordered; save whenever the user clicks save
		},

		///////////////////////////////////////////////
		save_resource(flag) {
			// if the user was adding a resource, and an existing resource was selected, ...
			if (this.is_new_resource && this.selected_resource_pool != 'new') {
				// if the user changed the "Unit planning resource" checkbox, confirm that they want to make the change, then save
				let confirm_msg, confirm_btn
				if (this.original_resource == 'unit_planning' && this.selected_resource.lp_category != 'unit_planning') {
					// confirm that the user wants to change this to a unit_planning resource
					confirm_msg = 'This resource was <b>not</b> previously designated as a “unit planning” resource. Do you want to change it to a unit planning resource?'
					confirm_btn = 'Change to “unit planning” resource'
				} else if (this.original_resource == 'new' && this.selected_resource.lp_category == 'unit_planning') {
					confirm_msg = 'This resource was previously designated as a “unit planning” resource. Do you want to change it to an “additional” resource?'
					confirm_btn = 'Change to “additional” resource'
				}

				if (confirm_msg) {
					// if user already confirmed, fall through to save below
					if (flag != 'confirmed') {
						this.$confirm({
						    title: 'Are you sure?',
						    text: confirm_msg,
						    acceptText: confirm_btn,
							dialogMaxWidth: 600,
							focusBtn: true,		// focus on the accept btn when dialog is rendered
						}).then(y => {
							// if confirmed, set edited_resource to a copy of selected_resource, change edited_resource's lp_category, and save
							this.edited_resource = new Resource(this.selected_resource)
							this.edited_resource.lp_category = (this.original_resource == 'unit_planning') ? 'unit_planning' : ''
							console.log(this.edited_resource)
							this.save_resource('confirmed')
						}).catch(n=>{console.log(n)}).finally(f=>{})

						return
					}

				} else {
					// if we get to here, all we have to do is emit the edit_resource_saved event
					if (this.selected_resource) {
						this.$emit('edit_resource_saved', this.selected_resource)
					} else {
						// this shouldn't happen, but just in case...
						this.$emit('edit_resource_cancel')
					}
					return
				}
			}

			// combine mappings
			this.edited_resource.mappings = this.subject_mappings.concat(this.grade_mappings, this.course_mappings)

			// convert from resource_type to type list from resources.js
			if (this.resource_type == 'google_drive' || this.resource_type == 'google_assignment' || this.resource_type == 'onedrive') this.edited_resource.type = 'document'
			if (this.resource_type == 'equella') this.edited_resource.type = 'website'

			// for an assessment save supplemental links
			this.edited_resource.supp_links = []
			if (this.resource_type == 'assessment') {
				if (!empty(this.blueprint_url)) {
					this.edited_resource.supp_links.push({type: 'blueprint', url: this.blueprint_url})
				}
				if (!empty(this.paper_url)) {
					this.edited_resource.supp_links.push({type: 'paper', url: this.paper_url})
				}
			}

			let uploaded_file = null

			// if type is upload, we must have a resource guid in the url field
			if (this.edited_resource.type == 'upload') {
				if (!U.is_uuid(this.edited_resource.url.replace(/\.\w+$/, ''))) {
					this.$alert('For an Uploaded File resource, you must either choose a file to upload, or enter a previously-uploaded Resource File Identifier.')
					return
				}
				// if the user specified a file to be uploaded, include it in the resource save operation
				// (the user can alternatively specify an already-existing filename -- though this is risky and will probably be undocumented)
				if (this.edited_resource.url == this.uploaded_file_name) {
					uploaded_file = this.uploaded_file
				}

			} else if (this.edited_resource.type == 'html') {
				if (empty(this.resource_html)) {
					this.$alert('Please enter the text you’d like to save for this resource.')
					return
				}
				uploaded_file = this.resource_html
				// generate a filename for the html, if not already specified
				if (!U.is_uuid(this.edited_resource.url.replace(/\.\w+$/, ''))) {
					this.edited_resource.url = U.new_uuid() + '.html'
				}

			} else {
				if (empty(this.edited_resource.url)) {
					this.$alert('You must specify the URL for the resource.')
					return
				}
			}

			if (empty(this.edited_resource.description)) {
				this.$alert('Please enter a brief title or description for the resource.')
				return
			}

			if (this.subject_mappings.length > 0 && this.grade_mappings.length == 0) {
				this.$alert('When tagging resources to one or more subjects, please also choose one or more grade tags. (Choose “All grades” if the resource is relevant for all grades.)')
				return
			}
			if (this.grade_mappings.length > 0 && this.subject_mappings.length == 0) {
				this.$alert('When tagging resources to one or more grades, please also choose one or more subject tags. (Choose “All subjects” if the resource is relevant for all subjects.)')
				return
			}

			this.$store.dispatch('save_resource', {resource: this.edited_resource, uploaded_file: uploaded_file}).then((resource_data)=>{
				// once resource is saved, emit edit_resource_saved, sending on the resource data sent back from the service
				this.$emit('edit_resource_saved', resource_data)
			}).catch(error=>{
				console.log(error)
				this.$alert(sr('A problem occurred when attempting to save the resource. Message from server:<div class="mt-2">$1</div>', error))
			})
		},
	}
}
</script>

<style lang="scss">
.k-resource-editor-outer {
	background-color:#fbe4d4;

	.v-input--checkbox .v-label {
		color:#222;
	}
}

.k-inline-checkbox-holder {
	display: inline-block;
	background-color:#fff;
	padding:4px 6px;
	border:1px solid #9E9E9E;
	border-radius:4px;
}
</style>
