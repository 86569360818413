<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div class="k-collections-units-vertical-outer-wrapper elevation-1" :class="vertical_units_outer_wrapper_class">
	<div v-if="collection.units.length>1" class="k-collections-units-vertical-list">
		<div v-for="(unit, i) in collection.units" class="k-collections-unit-list-item" :class="unit_toc_listing_class(unit,i)">
			<div class="k-collections-unit-title" @click="$emit('toggle_unit',unit)">
				<!-- <v-btn color="#111" icon small class="mx-1" style="align-self:flex-start"><v-icon :class="folder_icon_css" small :style="(unit_showing==unit)?'transform:rotate(90deg)':''">fas fa-play</v-icon></v-btn> -->
				<v-btn v-if="unit_showing!=unit" color="#111" icon small class="mx-1" style="align-self:flex-start"><v-icon :class="folder_icon_css" small>fas fa-play</v-icon></v-btn>
				<v-btn v-if="unit_showing==unit" color="#111" icon small class="mx-1" style="align-self:flex-start"><v-icon :class="folder_icon_css" small>fas fa-forward</v-icon></v-btn>
				<div class="k-collections-unit-list-item-title">
					<b v-if="unit.display_number" class="mr-1" v-html="unit.display_number"></b><span v-html="unit.title"></span><span v-if="unit.duration>0" class="ml-2" style="font-size:0.8em;color:#666;font-weight:bold">[{{unit.duration}} WEEK{{unit.duration!=1?'S':''}}]</span>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-icon v-on="on" class="k-collections-unit-list-item-search-result" @click.stop="$emit('execute_search_clear');$emit('clear_collection_last_search_results')" v-show="search_results_units&&search_results_units.includes(unit)">fas fa-search</v-icon></template><div style="text-align:center; font-size:12px; line-height:14px;">Item(s) in this unit were found<br>in your last resource search<br>(click to clear)</div></v-tooltip>
					<i v-show="!search_results_units" style="color:#666; font-size:14px; margin-left:4px;" v-html="unit_resource_count_msg(unit)"></i>
				</div>
				<v-spacer />
				<v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" v-show="unit_todo_count[unit.lp_unit_id]>0" class="k-collections-unit-list-item-assignment-total">{{unit_todo_count[unit.lp_unit_id]}}</div></template>{{unit_todo_count[unit.lp_unit_id]}} {{unit_todo_count[unit.lp_unit_id]==1?'Resource':'Resources'}} To Do</v-tooltip>
			</div>
		</div>
		<div class="k-collections-unit-list-item k-collections-unit--last"></div>
		<div v-if="is_collection_admin&&!is_my_default_collection" class="my-2 text-center"><v-btn small text color="" @click="$emit('add_unit')"><v-icon small class="mr-2">fas fa-plus</v-icon>Add a New Unit</v-btn></div>
	</div>

	<CollectionUnit v-if="unit_showing" ref="collection_unit"
		:collection="collection" :unit="unit_showing" :search_results_units="search_results_units" :search_results_items="search_results_items" :unit_descriptions_collapsed="unit_descriptions_collapsed" :unit_show_full_description_btn="unit_show_full_description_btn" :unit_description_style="unit_description_style"
		:is_collection_admin="is_collection_admin"
		@hide_unit="$emit('hide_unit')" @add_unit="$emit('add_unit')"
		@clear_collection_last_search_results="$emit('clear_collection_last_search_results')"
		@lesson_shift_update="$emit('lesson_shift_update',$event)"
	></CollectionUnit>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionUnit from './CollectionUnit'

export default {
	components: { CollectionUnit },
	props: {
		collection: { type: Object, required: true },
		unit_showing: { required: true },
		is_collection_admin: { type: Boolean, required: true },
		unit_descriptions_collapsed: { type: Boolean, required: true },
		unit_show_full_description_btn: { type: Boolean, required: true },
		unit_description_style: { type: String, required: true },
		search_results_items: { type: Array, required: false, default() { return [] }},
		search_results_units: { required: false, default() { return null }},
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		vertical_units_outer_wrapper_class() {
			let s = U.subject_tile_css(this.collection) + '-light'
			s += ' ' + U.subject_tile_css(this.collection) + '-border'

			if (this.unit_showing) s += ' k-collections-units-vertical-outer-wrapper--unit-showing'
			if (this.collection.units.length == 1) {
				s += ' k-collections-units-vertical-outer-wrapper-single-unit'
				// for single-unit collections, limit the width of the area if we're in list mode or if we have <= 10 items
				if (this.$store.state.lst.collection_view_mode == 'list' || (this.collection.units[0].lessons.length + this.collection.units[0].resources.length) <= 10) {
					s += ' k-collections-units-vertical-outer-wrapper-limited-width'
				}
			}

			return s
		},
		unit_css_class() {
			if (empty(this.collection.units)) return []
			let arr = []
			for (let i = 0; i < this.collection.units.length; ++i) {
				let s = ''
				if (i == 0) s += ' k-collections-unit--first'
				// if (i == this.collection.units.length-1) s += ' k-collections-unit--last'

				arr.push(s)
			}
			return arr
		},
		folder_icon_css() {
			return U.subject_tile_css(this.collection) + '-text'
		},
		unit_todo_count() {
			let o = {}

			// for each unit
			for (let unit of this.collection.units) {
				o[unit.lp_unit_id] = 0

				// for each resource in the unit
				for (let resource of unit.resources) {
					// if the resource is marked todo
					if (resource.todo) {
						// then if the user hasn't completed it, add to the count
						// note that the todo_status for a video may be 5-95, indicating partial completion
						if (!(this.user_info.todo_status[resource.resource_id] > 100)) {
							++o[unit.lp_unit_id]
						}
					}
				}
			}

			return o
		},
		is_my_default_collection() { return this.collection == this.$store.getters.my_default_collection },
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		unit_toc_listing_class(unit, i) {
			let s = this.unit_css_class[i]
			if (this.unit_showing == unit) s += ' k-collections-unit-list-item--showing'
			if (this.search_results_units && !this.search_results_units.includes(unit)) s += ' k-collections-unit-list-item-no-search-match'
			return s
		},
		unit_resource_count_msg(unit) {
			let count = unit.lessons.length + unit.resources.length
			if (this.unit_showing) return `(${count})`
			else return `(${count} ${U.ps('resource', count)})`
		},


	}
}
</script>

<style lang="scss">
.k-collections-units-vertical-outer-wrapper {
	display:flex;
	background-color:rgba(255, 240, 200, 0.5);
	border:1px solid;
	border-radius:10px;
	// padding:8px;
	max-width:800px;
	margin:20px auto 16px auto;
}

.k-collections-units-vertical-outer-wrapper-single-unit {
	// max-width:1000px!important;
}

.k-collections-units-vertical-outer-wrapper-limited-width {
	// when showing a single unit in list mode, or when we only have a few items, limit width
	max-width:860px!important;
}

.k-collections-units-vertical-outer-wrapper--unit-showing {
	max-width:none;
}

.k-collections-units-vertical-list {
	flex:1 1 auto;
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
}

.k-collections-units-vertical-outer-wrapper--unit-showing .k-collections-units-vertical-list {
	// max-width:350px;
	// min-width:270px;
	max-width:300px;
	min-width:300px;
	
	.k-collections-unit-title {
		font-weight:normal;
		font-size:14px;
		line-height:18px;
	}
	.k-collections-unit-list-item--showing .k-collections-unit-list-item-title {
		// font-weight:bold!important;
	}
}

.k-collections-unit-list-item {
	padding:2px 0;
}

.k-collections-unit-list-item--showing {
	background-color:#fff;
}

.k-collections-unit-list-item-no-search-match {
	color:#999;
	.fa-folder-open { color:#999!important }
	.k-learning-progression-unit-header { color:#999!important; }
}

.k-collections-unit-list-item-no-search-match.k-learning-progression-unit-td {
	background-color:#eee!important;
}

.k-collections-unit-title {
	display:flex;
	align-items: center;
	cursor:pointer;
	// font-weight:bold;
	padding-left:4px;
	font-size:18px;
	line-height:22px;
}

.k-collections-unit--first {
	// border-radius:10px 0 0 0;
	// padding-top:4px;
	margin-top:8px;
}

.k-collections-unit--last {
	flex:1 1 auto;
	border-radius:0 0 0 10px;
	// padding-bottom:8px;
	// background-color:red;
	min-height:8px;
}

.k-collections-unit-list-item--showing.k-collections-unit--last {
}

.k-collections-unit-list-item-search-result {
	font-size:14px!important;
	margin-top:0px;
	margin-left:4px;
	margin-right:4px;
	color:#666!important;
}

.k-collections-unit-list-item-assignment-total {
	margin-right:8px;
	margin-left:4px;
	font-size:12px;
	font-weight:bold;
	background-color:$v-pink-accent-3;
	color:#fff;
	flex:0 0 20px;
	width:20px;
	height:20px;
	border-radius:20px;
	line-height:20px;
	text-align:center;
}

</style>