<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-card-text :id="print_lesson_dom_id" class="k-lesson-card-text">
		<div class="k-lesson-grouping elevation-2" v-if="false&&lesson.student_description">
			<div class="d-flex" @click="toggle_student_description" style="cursor:pointer">
				<v-btn icon x-small color="indigo darken-2" class="mr-2" @click.stop="toggle_student_description"><v-icon>fas {{lesson.student_description_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
				<div class="k-lesson-title-intro"><b>Student-Facing Lesson Overview</b></div>
			</div>
			<v-expand-transition><div v-show="lesson.student_description_showing" style="background-color:#fff;border-radius:6px;padding:6px;margin-top:4px;">
				<div class="fr-view" v-html="U.render_latex(lesson.student_description)"></div>
			</div></v-expand-transition>
		</div>

		<div v-for="(component, ci) in lesson.lesson_plan" :key="component.uuid" v-show="!component.is_empty()" class="k-lesson-component elevation-2" :class="component.lc_showing?'k-lesson-component-showing':''">
			<div class="k-lesson-component-title" @click="toggle_component(ci)">
				<div class="k-lesson-component-toggle-holder"><v-btn icon x-small color="indigo darken-2" @click.stop="toggle_component(ci)"><v-icon>fas {{component.lc_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn></div>
				<div class="k-lesson-component-title-text" :class="'k-lesson-component-title-text-'+lesson_master.lesson_plan[ci].lc_weight">{{(lesson_master.lesson_plan[ci].lc_title)?lesson_master.lesson_plan[ci].lc_title:'Part '+(ci+1)}}</div>
				<div v-if="lesson_master.lesson_plan[ci].lc_weight!=0" class="k-lesson-component-title-weight" :style="'flex-basis:' + lesson_master.lesson_plan[ci].lc_weight + '%'">{{lesson_master.lesson_plan[ci].lc_weight}}%</div>
			</div>
			<v-expand-transition><div v-show="component.lc_showing">
				<div v-if="lesson_master.lesson_plan[ci].lc_guidance" class="k-lesson-component-guidance" v-html="lesson_master.lesson_plan[ci].lc_guidance"></div>
				<div class="k-lesson-component-content">
					<div v-for="(component_part, cpi) in component.lc_parts" :key="cpi" class="k-lesson-component-part-content">
						<div v-if="component.lc_parts.length>1" class="k-lesson-component-part-header">
							<div v-if="!component_part.lcp_title" class="mr-2">Part {{cpi+1}}</div>
							<div v-if="component_part.lcp_title" v-html="component_part.lcp_title"></div>
						</div>
						<div class="fr-view" v-html="U.render_latex(component_part.lcp_teacher_content)"></div>
					</div>
				</div>
				<!-- show lc_default_content from master if we don't have any teacher content -->
				<div v-if="component.lc_parts.length>0&&component.lc_parts[0].lcp_teacher_content==''&&lesson_master.lesson_plan[ci].lc_default_content" class="k-lesson-component-default-content">
					<div class="float-left mr-2"><b>“Looks like…”</b></div>
					<div class="fr-view" v-html="lesson_master.lesson_plan[ci].lc_default_content"></div>
				</div>
			</div></v-expand-transition>
		</div>

		<div class="k-lesson-grouping elevation-2" v-if="lesson.resources.length>0">
			<div class="d-flex" @click="toggle_resources" style="cursor:pointer">
				<v-btn icon x-small color="indigo darken-2" class="mr-2" @click.stop="toggle_resources"><v-icon>fas {{lesson.resources_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
				<div class="k-lesson-title-intro"><b>Lesson Resources</b></div>
			</div>
			<v-expand-transition><div v-show="lesson.resources_showing"><div class="k-lesson-resource-collection mt-2">
				<div v-for="(resource) in lesson.resources" :key="resource.resource_id" class="k-resource-collection-activity-wrapper">
					<ResourceCollectionItem :item="resource" />
				</div>
			</div></div></v-expand-transition>
		</div>

		<div class="k-lesson-grouping elevation-2" v-if="lesson.standards.length>0">
			<div class="d-flex" @click="toggle_standards" style="cursor:pointer">
				<v-btn icon x-small color="indigo darken-2" class="mr-2" @click.stop="toggle_standards"><v-icon>fas {{lesson.standards_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
				<div class="k-lesson-title-intro"><b>Learning Standards</b></div>
			</div>
			<v-expand-transition><div v-show="lesson.standards_showing"><div class="d-flex flex-wrap my-1"><CASEItemBtn v-for="(s) in lesson.standards" :key="s.identifier" :item="s" :framework_identifier="case_framework_identifier(s)" :selected_items="lesson.standards" small :outer_class="'ma-1'" /></div></div></v-expand-transition>
		</div>

	</v-card-text>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	name: 'LessonView',
	components: {
		// have to this this way because of recursion in the components...
		ResourceCollectionItem: () => import('../resources/ResourceCollectionItem')
		, CASEItemBtn
	},
	props: {
		lesson: { required: true },
		print_lesson_dom_id: { type: String, required: false, default() { return U.new_uuid() } },
	},
	data() { return {
		standard_children_showing: {},
	}},
	computed: {
		...mapState(['user_info', 'lesson_masters', 'default_lesson_master']),
		...mapGetters([]),
		lesson_master() {
			// if we have a lesson_master_id, return the lesson master
			if (this.lesson.lesson_master_id) {
				let l = this.lesson_masters.find(x=>x.lesson_id==this.lesson.lesson_master_id)
				if (l) return l
			}
			// otherwise the lesson is its own master
			return this.lesson
		},
	},
	watch: {
	},
	created() {
		// get lesson_masters if we haven't already done so
		this.$store.dispatch('get_lesson_masters').then(x=>{
			// then get the lesson itself if it isn't yet fully loaded
			if (!this.lesson.lesson_fully_loaded) {
				this.$store.dispatch('load_lesson', this.lesson)
			}
		})
	},
	mounted() {
		setTimeout(x=>console.log(this.lesson), 2000)
	},
	methods: {
		lesson_component_not_empty(component) {
			
		},

		toggle_component(ci, val) {
			if (typeof(val) != 'boolean') val = !this.lesson.lesson_plan[ci].lc_showing
			this.$store.commit('set', [this.lesson.lesson_plan[ci], 'lc_showing', val])
		},

		toggle_standard_children(identifier) {
			if (!this.standard_children_showing[identifier]) {
				this.$set(this.standard_children_showing, identifier, true)
			} else {
				this.standard_children_showing[identifier] = false
			}
		},

		toggle_resources(val) {
			if (typeof(val) !== 'boolean') val = !this.lesson.resources_showing
			this.$store.commit('set', [this.lesson, 'resources_showing', val])
		},

		toggle_student_description(val) {
			if (typeof(val) !== 'boolean') val = !this.lesson.student_description_showing
			this.$store.commit('set', [this.lesson, 'student_description_showing', val])
		},

		toggle_standards(val) {
			if (typeof(val) !== 'boolean') val = !this.lesson.standards_showing
			this.$store.commit('set', [this.lesson, 'standards_showing', val])
		},

		case_framework_identifier(item) {
			// if the item has a framework_identifier, send it
			if (item.framework_identifier) return item.framework_identifier

			// HACK: trace up through parents looking for a case_framework_identifier value (e.g. in CourseView); if found, use it
			let parent = this.$parent
			while (parent) {
				if (parent.case_framework_identifier) {
					return parent.case_framework_identifier
				}
				parent = parent.$parent
			}

			// TODO: if we get to here, look in loaded frameworks, then use a service (or the item uri?) to look up the document
			return ''
		},
	}
}
</script>

<style lang="scss">
// enclosing item should apply an appropriate margin; we only add 4px padding here to make sure shadows show properly
.k-lesson-card-text {
	font-size:16px;
	line-height:20px;
	padding:4px 4px 12px 4px!important;

	.k-lesson-standard {
		border-top:1px solid #ccc;
		padding-top:5px;
		margin:8px 16px 8px 40px;
		font-size:14px;
		line-height:19px;
		position:relative;
		min-height:24px;
	}

	.k-lesson-standard-child {
		margin-left:24px;
		min-height: none;
	}

	.k-lesson-standard-statement-toggler {
		position:absolute;
		left:-32px;
		top:0px;
	}

	.k-lesson-standard-statement-wrapper {
		// display:flex;
		// align-items: flex-start;
	}

	.k-lesson-standard-statement {
		cursor:pointer;
		font-size:14px;
		line-height: 18px;
	}
	.k-lesson-standard-statement:hover {
		text-decoration:underline;
	}
}

.k-lesson-title {
	font-size:16px;
	line-height:20px;
	// margin-bottom:16px;
}

.k-lesson-course-unit-header {
	font-size:12px;
	line-height:15px;
	font-weight:bold;
	color:#999;
	margin-left:8px;
	text-align:center;
}

.k-lesson-date-header {
	white-space:nowrap;
	font-size:14px;
	line-height:15px;
	font-weight:900;
	background-color:#999;	// this will get overwritten
	border-radius:6px;
	padding:4px 6px;
	color:#fff;
	margin-left:8px;
	text-align:center;
}

.k-lesson-title-intro {
	font-weight:bold;
	font-size:14px;
	text-transform: uppercase;
	// font-variant: small-caps;
	color:$v-indigo-darken-2;
	margin-right:8px;
}

.k-lesson-component {
	border:1px solid $v-amber-darken-2;
	border-radius:7px;
	margin-top: 8px;
	color:#222;
	background-color:#fff;
}

.k-lesson-component-title {
	display:flex;
	cursor:pointer;
	font-size:16px;
	line-height:20px;
	.k-lesson-component-toggle-holder {
		border-radius:6px 0 0 6px;
		background-color:$v-amber-lighten-3;
		flex:0 1 auto;
		padding:4px 0 3px 4px;
	}
	.k-lesson-component-title-text {
		font-weight:bold;
		background-color:$v-amber-lighten-3;
		color:#222;
		padding:6px 6px;
		flex: 1 0 auto;
	}
	.k-lesson-component-title-text-0 {
		border-radius:0 6px 6px 0;
	}
	.k-lesson-component-title-weight {
		border-radius:0 6px 6px 0;
		background-color:$v-orange-darken-3;
		color:#fff;
		padding:6px 6px;
		flex:0 1 auto;
		text-align:right;
	}
}

.k-lesson-component-guidance, .fr-view.k-lesson-component-guidance {
	color:#555;
	font-size:12px;
	line-height:15px;
	padding: 0 8px;
}

.k-lesson-component-content {
	color:#222;
	font-size:14px;
	line-height:19px;
	margin-top:12px;
	padding: 0 8px 8px 8px;

	li {
		margin-bottom:6px;
	}
}

.k-lesson-component-part-header {
	display:flex;
	align-items: center;
	font-weight:900;
	margin:12px 0 8px 0;
	background-color:$v-amber-accent-1;
	border-radius:6px;
	padding:3px 5px 3px 8px;
}

.k-lesson-component-part-content {
	padding:0 4px;
}

// .k-lesson-component-part-content:last-of-type {
// 	border-bottom:0;
// 	margin-bottom:0;
// }

.k-lesson-component-default-content {
	color:#555;
	font-size:12px;
	line-height:15px;
	margin-top:12px;
	padding: 0 8px;
}

.k-lesson-component-showing {
	.k-lesson-component-title {
		.k-lesson-component-toggle-holder {
			border-radius:6px 0 0 0;
		}
		.k-lesson-component-title-text-0 {
			border-radius:0 6px 0 0;
		}
		.k-lesson-component-title-weight {
			border-radius:0 6px 0 0;
		}
	}
}

.k-lesson-component-part-activity {
	flex:0 0 auto;
	// max-width:200px;
	border:2px solid $v-pink-darken-2;
	border-radius:10px;
	margin:4px;
	background-color:$v-pink-lighten-5;
}

.k-lesson-component-part-activity-expanded {
	flex:1 0 100%;
	margin:4px 0;
	max-width:none;
	// width:calc(100% - 8px);
}

.k-lesson-component-resource-link, .k-lesson-component-resource-link-placeholder {
	background-color:#555;
	color:#fff;
	padding:2px 8px;
	border-radius:5px!important;
	margin:0 4px!important;
	display:inline-block;
	height:20px;
	font-size:16px;
	cursor:pointer;
}

.k-lesson-grouping {
	border-radius:6px;
	// border:1px solid #ccc;
	background-color:$v-amber-lighten-5;
	padding:8px;
	margin:12px 0 0 0;
}

.k-lesson-resource-collection {
	display:flex;
	flex-wrap: wrap;
	align-items: stretch;

	.k-resource-collection-activity-wrapper {
		min-width:25%;
		max-width:25%;
	}
}
</style>
