<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" class="k-page-title-btn k-elevated">fas fa-cog</v-icon>
		Courses and Repositories
	</h2>

	<p v-show="initialized&&!new_lp">
		<v-btn @click="create_lp" class="k-tight-btn" color="primary"><v-icon class="mr-2" small>fas fa-plus</v-icon> Create a New Course</v-btn>
		<v-btn class="ml-2 k-tight-btn" color="primary" @click="duplicate_lp"><v-icon small class="mr-2">fas fa-copy</v-icon>Duplicate an Existing Collection</v-btn>
		<v-btn class="ml-2 k-tight-btn" color="red" dark @click="delete_lp_clicked"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete an Existing Collection</v-btn>
	</p>

	<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null"></CollectionEdit>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from '../collections/CollectionEdit'

export default {
	components: { CollectionEdit },
	props: {
	},
	data() { return {
		initialized: false,
		new_lp: null,
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
				})
			}).catch(()=>{
				this.$alert('error in get_all_courses')
				this.return_to_admin_main()
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	methods: {
		create_lp() {
			// lps created from here are definitionally agency_sanctioned
			this.new_lp = new Learning_Progression({agency_sanctioned: true})
			return
		},

		duplicate_lp() {
			this.$prompt({
				title: 'Duplicate Collection',
				text: 'Enter the “course code(s)” for the collection (course or repository) you’d like to duplicate, along with the new course codes for the duplicates. Separate each original and duplicate course code with a :, and separate multiple course code pairs with a line break, like this:<br><br>1111: 1112<br>3333: 3334<br>5555: 5556<br><br>',
				promptType: 'textarea',
				acceptText: 'Duplicate',
			}).then(course_code_lines => {
				if (empty(course_code_lines)) return
				course_code_lines = $.trim(course_code_lines).split(/\n+/)

				// parse lines and check for errors
				let course_codes = []
				let bad_origin_course_codes = []
				let bad_destination_course_codes = []
				for (let line of course_code_lines) {
					let arr = $.trim(line).split(/[\s\:]+/)
					if (arr.length != 2) {
						this.$alert('Bad line in input:<br><br>' + line)
						return
					}

					// if the first code doesn't exist, error
					if (empty(this.$store.state.all_courses.find((o)=>o.course_code==arr[0]))) {
						bad_origin_course_codes.push(arr[0])
					}

					// if the second code does exist, error
					if (!empty(this.$store.state.all_courses.find((o)=>o.course_code==arr[1]))) {
						bad_destination_course_codes.push(arr[1])
					}

					course_codes.push(arr)
				}

				if (bad_origin_course_codes.length > 0) {
					this.$alert(sr('We don’t have collection(s) for course code(s) $1. Operation cancelled.', bad_origin_course_codes.join(', ')))
					return
				}
				if (bad_destination_course_codes.length > 0) {
					this.$alert(sr('We already have collection(s) for course code(s) $1. Operation cancelled.', bad_destination_course_codes.join(', ')))
					return
				}

				// if we get to here, process the duplicates
				this.$store.dispatch('duplicate_learning_progressions', course_codes).then(()=>{
					this.$alert('Collection(s) duplicated.')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		delete_lp_clicked() {
			this.$prompt({
				title: 'Delete Collection',
				text: 'Enter the “course code” for the collection you’d like to delete.',
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(course_code => {
				if (!empty(course_code)) {
					this.delete_lp(course_code)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		delete_lp(course_code) {
			let lp = this.$store.state.all_courses.find((o)=>o.course_code==course_code)
			if (empty(lp)) {
				this.$alert('We don’t have a collection for that course code in the system.')
				return
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete the collection for course code $1 (“$2”)?', lp.course_code, lp.title),
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_learning_progression', lp).then(()=>{
					this.$alert('Collection deleted.')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		}
	}
}
</script>

<style lang="scss">
</style>
