<template><div class="d-flex justify-center align-center">
	<div class="k-main-collection k-cv-main-collection">
		<div class="d-flex">
			<h2 class="k-main-collection-header k-page-title d-flex align-center">{{ header_text }}</h2>
			<v-spacer/>
			<v-btn class="k-nocaps-btn k-tight-btn" color="secondary" @click="show_all_lps"><v-icon class="mr-2">{{show_all_btn_icon}}</v-icon>{{show_all_btn_text}}</v-btn>
		</div>

		<div v-if="user_info.role == 'parent'">
			<div v-if="child_count == 0">You have not yet designated any students. Choose “Designated Students” from the “PARENT/GUARDIAN” menu above to view information for your students’ classes.</div>
			<div v-else>
				<span style="font-size:1.2em">Viewing courses for student <b class="ml-1">{{ child_data_showing.first_name }} {{ child_data_showing.last_name }}</b></span>
				<v-menu bottom left><template v-slot:activator="{ on }"><v-btn v-if="child_count > 1" small v-on="on" class="ml-2" color="secondary"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon>Switch To Student…</v-btn></template>
					<v-list>
						<v-list-item v-for="(child_data, child_email) in user_info.child_data" :key="child_email" @click="switch_child_data_showing(child_email)"><v-list-item-title>{{ child_data.first_name }} {{ child_data.last_name }} ({{ child_email }})</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>

		<div v-if="collections.length>0">
			<div v-if="welcome_section_lp_count==0">You have not favorited any {{welcome_section_label}}.<div class="mt-2" style="font-size:.85em">You can browse collections by clicking “{{show_all_btn_text}}” above;<br>you can then add collections to your Favorites from there.</div></div>
			<!-- <div v-else>You have {{welcome_section_lp_count}} favorite {{welcome_section_label}} -->
		</div>
		<div class="k-main-collection-body" :style="collection_body_style">
			<div v-if="collections.length==0">
				<div><i>You do you not have any Favorite Collections.</i><div class="mt-2" style="font-size:.85em">You can browse collections by clicking “{{show_all_btn_text}}” above;<br>you can then add collections to your Favorites from there.</div></div>
			</div>
			<FavoriteTile v-for="(collection) in collections" :key="collection.class_sourcedIds[0]"
				:course="collection"
				:welcome_section_collection_types="welcome_section_collection_types"
				:course_showing="''"
				@show_course="show_course"
			></FavoriteTile>

			<!-- <div class="mt-5 mb-3" style="flex:1 1 100%">
				<v-btn class="mt-3 ml-5 k-nocaps-btn k-tight-btn" x-large color="secondary" @click="show_all_lps"><v-icon class="mr-2">{{show_all_btn_icon}}</v-icon>{{show_all_btn_text}}</v-btn>
			</div> -->
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import FavoriteTile from './FavoriteTile'
import MiniNav from '../main/MiniNav'

export default {
	components: { FavoriteTile, MiniNav },
	data() { return {
		num_hidden_classes: 0,
		show_hidden_classes: false,
		classes_initialized: false,
		collection_body_style: '',
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'course_update_trigger', 'sis_classes', 'added_my_courses', 'removed_my_courses', 'site_config']),
		...mapGetters(['signed_in', 'child_count', 'child_data_showing', 'role']),
		welcome_section_showing: {
			get() { return this.$store.state.lst.welcome_section_showing },
			set(val) { this.$store.commit('lst_set', ['welcome_section_showing', val]) }
		},
		child_email_showing: {
			get() { return this.$store.state.lst.child_email_showing },
			set(val) { this.$store.commit('lst_set', ['child_email_showing', val]) }
		},
		header_text() {
			if (this.user_info.role === 'parent') return "My Child’s Courses"
			return 'Favorite Collections'
		},
		show_all_btn_icon() {
			if (this.welcome_section_showing == 'classes') return 'fas fa-chalkboard'
			if (this.welcome_section_showing == 'resourcerepos') return 'fas fa-diagram-project'
			if (this.welcome_section_showing == 'mycollections') return 'fas fa-cubes-stacked'
			if (this.welcome_section_showing == 'pd') return 'fas fa-user-graduate'
		},
		welcome_section_label() {
			if (this.welcome_section_showing == 'classes') return 'Course Content Collections'
			if (this.welcome_section_showing == 'resourcerepos') return `${this.site_config.resourcerepos_noun} Repositories`
			if (this.welcome_section_showing == 'mycollections') return 'My Content Collections'
			if (this.welcome_section_showing == 'pd') return 'PD Collections'
		},
		show_all_btn_text() {
			return this.welcome_section_label + ' Index'
		},
		welcome_section_collection_types() {
			if (this.welcome_section_showing == 'classes') return ['course']
			if (this.welcome_section_showing == 'resourcerepos') return ['repo', 'pd']
			if (this.welcome_section_showing == 'mycollections') return ['my']
			if (this.welcome_section_showing == 'pd') return 'PD Collections Index'
		},
		welcome_section_lp_count() {
			let n = 0
			for (let collection of this.collections) {
				let lp = this.all_courses.find(x=>x.course_code == collection.course_code)
				if (this.welcome_section_collection_types.includes(lp.collection_type)) ++n
			}
			return n
		},
		collections() {
			// console.log('-------- LOADING COLLECTIONS --------')
			let arr = []
			for (let course of this.sis_classes) {
				// filter out collections with code 'xxx'
				if (course.course_code == 'xxx') {
					// this.log_object(course)

				// also filter out collections in removed_my_courses
				} else if (this.removed_my_courses.find(x=>x == course.course_code)) {
					console.log('removing ' + course.course_code)

				} else {
					// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
					let lp = this.all_courses.find(x=>x.course_code == course.course_code)
					if (!empty(lp) && lp.active == 'no') {
						if (!lp.user_can_view_lp()) continue
					}

					// If viewing as parent, only show collections for the selected child
					if (this.user_info.role === 'parent' && this.child_data_showing.sis_student_classes) {
						const child_in_course = this.child_data_showing.sis_student_classes.find(x => x.course_code == course.course_code)
						if (!child_in_course) continue
					}

					arr.push(course)
				}
			}

			// add collections whose course_codes are in added_my_courses (and which we haven't yet processed above)
			for (let course_code of this.added_my_courses) {
				// skip if we already processed the class above -- the class might, e.g., have been added to the teacher's sis_classes after they explicitly added it
				if (arr.find(x=>x.course_code==course_code)) continue

				let c = this.all_courses.find(x=>x.course_code == course_code)
				if (!empty(c)) {
					arr.push(new User_Course({course_code: course_code, titles: [c.title]}))
				}
			}
			return arr

			// NOTE: sis_classes will appear first, in alpha order, followed by added classes
		},
	},
	created() {
		// for parents...
		if (this.role == 'parent') {
			// they should only see favorites in the 'classes' tab, and they have to have a child and have a child selected before viewing favorites...
			if (this.welcome_section_showing != 'classes' || empty(this.child_data_showing.sis_student_classes)) {
				this.show_all_lps()
				return
			}
		}

		if (window.location.pathname.match(/\/courses\/([^\/]+)(\/(.*))?/)) {
			this.show_all_lps()
		} else {
			// if no course is showing, make sure we don't have a subject/subcat displaying
			// window.history.replaceState(null, '', '/courses')

			if (this.$store.state.loaded_classes) {
				this.classes_initialized = true
			} else if (!this.classes_initialized) {
				this.get_classes()
			}
		}
	},
	watch: {
		welcome_section_showing() {
			// as noted above, parents can only see favorites for the 'classes' tab
			if (this.role == 'parent' && this.welcome_section_showing != 'classes') this.show_all_lps()
		},
	},
	mounted() {
	},
	methods: {
		get_classes() {
			this.$store.dispatch('get_classes').then(()=>{
				this.$nextTick(()=>{
					this.classes_initialized = true
				})
			})
		},

		show_all_lps() {
			this.$store.commit('lst_set', ['index_view_flavor', 'lpindex'])
		},

		show_course(course, event, unit_id) {
			if (empty(unit_id)) unit_id = 0

			// DEBUG: if you hold down the (mac) command and option key while clicking, just show the course data in the console
			if (event.metaKey && event.altKey) {
				this.log_object(course)
				return
			}
			// for legacy reasons the course will not be a proper Learning_Progression object; look up the proper lp
			let lp = this.all_courses.find(x=>x.course_code == course.course_code)
			let path = lp.vue_route(unit_id)
			if (path != this.$router.currentRoute.path) {
				this.$router.push({ path: path })
			}
		},

		log_object(o) {
			let s = ''
			for (let key in o) {
				s += sr('$1: $2\n', key, JSON.stringify(o[key]))
			}
			console.log(s)
		},

		switch_child_data_showing(child_email) {
			this.child_email_showing = child_email
		},
	}
}
</script>

<style lang="scss">
.k-cv-main-collection {
	// padding-right:20px!important;
	// padding-left:20px!important;
	.k-main-collection-body {
		justify-content:center!important;
	}
}

.k-cv-main-collection--course-showing {
	display: inline-block;
	margin-top:0!important;
	padding:0px!important;
	overflow: auto;
	width:100vw;
	max-width:100vw;
	margin:0;
	.k-main-collection-body {
		display:flex;
		flex-wrap: nowrap;
		// margin:0px;
		padding-right:0;
	}
}
</style>
