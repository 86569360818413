<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #ccc">
				<b><v-icon color="blue darken-3" class="mr-2">fas fa-flag-checkered</v-icon>{{lesson_report.report_title}}</b>
				<!-- <v-btn fab x-small class="ml-3 elevation-0" color="secondary" @click="edit_report_title"><v-icon small>fas fa-edit</v-icon></v-btn> -->
				<v-spacer></v-spacer>
				<v-btn color="secondary" dark class="k-tight-btn mr-2" @click=""><v-icon small class="mr-2">fas fa-link</v-icon>Report Link</v-btn>
				<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
			</v-card-title>
			<v-card-text v-if="!initialized" class="mt-2 text-center" style="font-size:16px">Loading lesson data...</v-card-text>
			<v-card-text v-if="initialized" class="mt-2" style="font-size:16px">
				<div class="mb-2 d-flex align-start">
					<v-icon small color="#333" class="mr-2" style="margin-top:3px">fas fa-chalkboard</v-icon>
					<div v-html="lp_html"></div>
					<v-spacer/>
					<div class="text-right">
						<div class="mb-1"><b>{{user_info.first_name}} {{user_info.last_name}}</b> <a class="ml-2" :href="`mailto:${user_info.email}`">{{user_info.email}}</a></div>
						<!-- <div class="text-center"><v-btn small color="primary" class="k-tight-btn" text @click=""><v-icon small class="mr-2">fas fa-users</v-icon>Collaborative Team Members</v-btn></div> -->
					</div>
				</div>
				<v-tabs background-color="#ddd" v-model="tab" class="k-lesson-report-tabs">
					<v-tab v-for="tab_item in tab_items" :key="tab_item.tab_key" active-class="k-lesson-report-active-tab" class="k-tight-btn k-nocaps-btn" v-html="tab_item.tab_text"></v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item v-for="tab_item in tab_items" :key="tab_item.tab_key" transition="false"><div class="k-lesson-report-body-outer">
						<div v-if="tab_item.type=='prework'">
							<div class="k-lesson-report-body-header py-2">
								<div><b>Prework</b></div>
								<v-spacer/>
								<!-- <v-btn class="float-right k-tight-btn ml-2" x-small text color="secondary" @click="toggle_editing_prework"><v-icon small class="mr-2">fas fa-edit</v-icon>{{editing_prework?'Done Editing':'Edit Prework'}}</v-btn> -->
							</div>
							<div class="k-lesson-report-body-inner pt-3">
								<div class="mb-3 d-flex align-center">
									<div><b>Collaborators:</b> {{collaborator_list}}</div>
									<v-spacer/>
									<div class="d-flex align-center ml-3">
										<b class="mr-2" style="font-size:14px;white-space:nowrap;">Show prework for:</b>
										<div><v-select style="width:250px" v-model="prework_user" :items="prework_users" label="" outlined dense hide-details></v-select></div>
									</div>
								</div>
								<div class="mb-3" style="font-size:14px;color:#666;font-weight:bold;"><i>Analyze the previous week’s common assessment data and Complete the Know/Show Chart for the upcoming week.</i></div>
								<div v-if="!editing_prework" class="fr-view" style="border-top:1px solid #ccc;" v-html="U.render_latex(lesson_report.prework)"></div>
								<div v-if="editing_prework">
									<froala-wrapper :config="editor_config()" v-model="prework" />
								</div>
								<!-- <div class="text-center mt-2"><v-btn class="k-tight-btn" small color="secondary" @click="toggle_editing_prework"><v-icon small class="mr-2">fas fa-edit</v-icon>{{editing_prework?'Done Editing':'Edit Prework'}}</v-btn></div> -->
							</div>
						</div>

						<div v-if="tab_item.type=='standards'">
							<div class="k-lesson-report-body-header py-2">
								<div><b>Henry Teaching & Learning Standards</b></div>
							</div>
							<div class="k-lesson-report-body-inner pt-3">
								<div v-for="standard in all_standards" :key="standard.identifier" class="d-flex" v-html="rendered_standard(standard)"></div>
							</div>
						</div>

						<div v-if="tab_item.type=='lesson'">
							<div class="k-lesson-report-body-header">
								<v-icon class="mr-2" color="#fff">{{tab_item.l.icon()}}</v-icon>
								<div class="k-lesson-title" v-html="tab_item.l.lesson_title"></div>
								<v-spacer />
							</div>
							<div class="k-lesson-report-body-inner">
								<LessonView :lesson="tab_item.l" :all_components_open="true" />
							</div>
						</div>

						<div v-if="tab_item.type=='feedback'">
							<div class="k-lesson-report-body-header py-2">
								<div><b>Feedback and Next Steps</b></div>
								<v-spacer/>
								<!-- <v-btn class="float-right k-tight-btn ml-2" x-small text color="secondary" @click="editing_feedback=true"><v-icon small class="mr-2">fas fa-edit</v-icon>{{editing_feedback?'Done Editing':'Edit'}}</v-btn> -->
							</div>
							<div class="k-lesson-report-body-inner pt-3">
								<div class="mb-3 pb-3" style="border-bottom:1px solid #ccc">
									<div class="d-flex">
										<!-- <v-checkbox disabled class="shrink mt-0 pt-0 d-inline-block" hide-details v-model="approved" @change="save"></v-checkbox> -->
										<v-icon color="green darken-2" class="mr-2">fas fa-square-check</v-icon>
										<b class="green--text text--darken-2 mr-3" style="font-weight:900">APPROVED</b>
										<span>by Avery Park <a href="mailto:avery.park@agency.edu">avery.park@agency.edu</a></span>
									</div>
									<!-- <div v-if="true"> -->
								</div>
								<div class="mb-2"><b>Next Steps:</b> What action steps do we need to complete as a team between now and our next collaborative meeting?</div>
								<div v-if="!editing_feedback" class="fr-view" v-html="U.render_latex(lesson_report.feedback)"></div>
								<div v-if="editing_feedback">
									<froala-wrapper :config="editor_config()" v-model="feedback" />
								</div>
								<!-- <div class="text-center mt-2"><v-btn class="k-tight-btn" small color="secondary" @click="toggle_editing_prework"><v-icon small class="mr-2">fas fa-edit</v-icon>{{editing_prework?'Done Editing':'Edit Next Steps'}}</v-btn></div> -->
								<div class="mt-3 pt-3" style="border-top:1px solid #ccc">
									<i><b>Threaded discussion goes here?</b></i>
								</div>
							</div>
						</div>
					</div></v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'
import ResourceEditor from '../resources/ResourceEditor'
import FroalaResourcesMixin from '../../js/FroalaResourcesMixin'
import CASEItemBtn from '../standards/CASEItemBtn'
import LessonView from '../lessons/LessonView'

export default {
	components: { ResourceCollectionItem, ResourceEditor, draggable, CASEItemBtn, LessonView, },
	mixins: [FroalaResourcesMixin],
	props: {
		lesson_report_id: { type: Number, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		initialized: false,
		lessons_to_load: 0,
		tab: null,
		prework_user: 1001,
		editing_prework: false,
		editing_feedback: false,
		lessons: [],
	}},
	computed: {
		...mapState(['my_lessons', 'my_resources']),	// 'user_info'
		...mapGetters(['role', 'system_role']),
		user_info() {
			// fake this for now
			return {first_name:'Elena', last_name:'Espinoza', email:'elena.espinoza@agency.edu'}
		},
		lesson_report() {
			let lr = new Lesson_Report({
				lesson_ids: [19, 21, 22, 23, 24],
				report_title: 'Weekly Plan for 1/8/2024 - 1/12/2024',
				prework: U.local_storage_get('district_portal_lesson_report_prework', '<p>Based on last week&#39;s common assessment and previous lessons:</p><ul><li># number of students demonstrated mastery on the prerequisite skill(s) and will likely be early finishers. I plan to engage those students by...</li><li># number of students have a developing understanding of the prerequisite skill(s) and will likely need to utilize the following scaffolds to access the content...</li><li># number of students are missing many of the key prerequisite skill(s). During the guided and independent practice portions of the lesson, I plan to...</li></ul>'),
				feedback: U.local_storage_get('district_portal_lesson_report_feedback', 'Formative assessment data will be analyzed to complete the MHS Know/Show Chart. The plan for re-engagement is to utilize IXL skills aligned to each standard and after school tutoring for students in need of help to demonstrate mastery. Data analysis will show whether the skills need to be taught in a whole group or small group.'),
				approved: true,
				course_code: '1525',
				lp_unit_id: 7026,
				// course_code: '6632',
				// lp_unit_id: 7200,
			})
			return lr
		},
		collaborators() {
			return [
				{user_id:1001, first_name:'Elena', last_name:'Espinoza', email:'elena.espinoza@agency.edu'},
				{user_id:1002, first_name:'Beth', last_name:'Mills', email:'beth.mills@agency.edu'},
				{user_id:1003, first_name:'Tnisha', last_name:'Hill', email:'tnisha.hill@agency.edu'},
				{user_id:1004, first_name:'Lori', last_name:'Beasley', email:'lori.beasley@agency.edu'},
				// {user_id:1005, first_name:'Uriel', last_name:'Richardson', email:'uriel.richardson@agency.edu'},
			]
		},
		collaborator_list() {
			let s = ''
			for (let o of this.collaborators) {
				if (s) s += ', '
				s += `${o.first_name} ${o.last_name}`
			}
			return s
		},
		lp() { return this.$store.state.all_courses.find(x=>x.course_code==this.lesson_report.course_code) },
		unit() {
			if (!this.lp || this.lesson_report.lp_unit_id == 0) return null
			return this.lp.units.find(x=>x.lp_unit_id == this.lesson_report.lp_unit_id)
		},
		lp_html() {
			let s = ''
			// TEMP: remove stars in lp/unit titles
			if (this.lp) s += `<b>${this.lp.title.replace(/\*/g, '')}</b>`
			if (this.unit) {
				s += '<span class="mx-2">|</span>'
				if (this.unit.display_number) s += `${this.unit.display_number.replace(/\*/g, '')}. `
				if (this.unit.title) s += this.unit.title.replace(/\*/g, '')
			}
			return s
		},
		prework: {
			get() { return this.lesson_report.prework },
			set(val) { this.lesson_report.prework = val }
		},
		feedback: {
			get() { return this.lesson_report.feedback },
			set(val) { this.lesson_report.feedback = val }
		},
		approved: {
			get() { return this.lesson_report.approved },
			set(val) { this.lesson_report.approved = val }
		},
		prework_users() {
			let arr = []
			for (let user of this.collaborators) {
				arr.push({value: user.user_id, text: `${user.first_name} ${user.last_name}`})
			}
			return arr
		},
		tab_items() {
			if (!this.initialized) return []

			let arr = [
				{ type: 'prework', tab_text: '<div class="px-0">Prework</div>', tab_key:U.new_uuid() },
				{ type: 'standards', tab_text: '<div class="px-0">Standards</div>', tab_key:U.new_uuid() },
			]
			let lesson_count = 1
			for (let lesson of this.lessons) {
				let tab_title = lesson.date_header()
				if (empty(tab_title)) tab_title = 'Lesson ' + lesson_count
				let o = {
					tab_key:U.new_uuid(),
					type: 'lesson',
					tab_text: `<div class="px-0">${tab_title}</div>`,
					l: lesson,	// use `l` for the lesson reference to ease coding
				}
				arr.push(o)
				++lesson_count
			}
			arr.push({ type: 'feedback', tab_text: '<div class="px-0">Feedback</div>', })
			return arr
		},
		all_standards() {
			let arr = []
			for (let tab_item of this.tab_items) {
				if (tab_item.type != 'lesson') continue
				for (let standard of tab_item.l.standards) {
					if (arr.find(x=>x.identifier == standard.identifier)) continue
					// console.log(standard)
					arr.push(standard)
				}
			}
			return arr
		},
	},
	watch: {
	},
	created() {
		vapp.lesson_report_component = this
		this.initialize()
	},
	mounted() {
	},
	methods: {
		initialize() {
			// first get the Lesson_Report data; for our purposes here we will already have it
			// then get the lessons for the report

			// get lesson_masters if we haven't already done so
			this.$store.dispatch('get_lesson_masters').then(x=>{
				for (let lesson_id of this.lesson_report.lesson_ids) {
					// check to see if we have the lesson in my_lessons
					let lesson = this.my_lessons.find(x=>x.lesson_id == lesson_id)
					// if not create a new Lesson object with the lesson_id
					if (!lesson) lesson = new Lesson({lesson_id: lesson_id})

					// then get the lesson data if it isn't yet fully loaded
					if (lesson.lesson_fully_loaded) {
						this.lessons.push(lesson)
					} else {
						++this.lessons_to_load
						this.$store.dispatch('load_lesson', lesson).then(loaded_lesson=>{
							this.lessons.push(loaded_lesson)
							--this.lessons_to_load
							if (this.lessons_to_load == 0) {
								this.$nextTick(x=>this.initialized = true)
							}
						})
					}
				}
				if (this.lessons_to_load == 0) {
					this.initialized = true
				}
			})
		},

		rendered_standard(standard) {
			let s = ''
			if (standard.humanCodingScheme) s += `<p><b class="mr-2">${standard.humanCodingScheme}</b></p>`
			s += U.marked_latex(standard.fullStatement)
			return s
		},

		editor_config(text, inline) {
			let config = U.get_froala_config({
				placeholderText: text,
				// initOnClick: true,
				toolbarInline: (inline === true),
				paragraphFormat: {
					H3: 'Section Header',
					N: 'Normal',
					BLOCKQUOTE: 'Block Quote',
				    PRE: 'Code',
				},
				heightMin: 250,
			})

			// add lesson part markers
			config.toolbarButtons.moreRich.buttons.unshift('lesson_marker_6')
			config.toolbarButtons.moreRich.buttons.unshift('lesson_marker_5')
			config.toolbarButtons.moreRich.buttons.unshift('lesson_marker_4')
			config.toolbarButtons.moreRich.buttons.unshift('lesson_marker_3')
			config.toolbarButtons.moreRich.buttons.unshift('lesson_marker_2')
			config.toolbarButtons.moreRich.buttons.unshift('lesson_marker_1')
			config.toolbarButtons.moreRich.buttonsVisible += 6

			// add the insert resource btn at the front of the moreRich buttons
			config.toolbarButtons.moreRich.buttons.unshift('insert_resource')
			config.toolbarButtons.moreRich.buttonsVisible += 1

			return config
		},

		edit_report_title() {

		},

		toggle_editing_prework() {
			if (!this.editing_prework) {
				this.editing_prework = true
			} else {
				this.save_edits()
				this.editing_prework = false 
			}
		},

		toggle_editing_feedback() {
			if (!this.editing_feedback) {
				this.editing_feedback = true
			} else {
				this.save_edits()
				this.editing_feedback = false 
			}
		},

		save_edits() {
			// temp: save data in local storage
			U.local_storage_set('district_portal_lesson_report_prework', this.lesson_report.prework)
			U.local_storage_set('district_portal_lesson_report_feedback', this.lesson_report.feedback)
		},
	}
}
</script>

<style lang="scss">
.k-lesson-report-tabs {
	border-radius:8px 8px 0 0;
	border-top:1px solid $v-blue-darken-3;
	border-left:1px solid $v-blue-darken-3;
	border-right:1px solid $v-blue-darken-3;
	margin-bottom:-2px;
}

.k-lesson-report-active-tab {
	border-radius:7px 7px 0 0;
	background-color:$v-blue-darken-3;
	color:#fff!important;
	// color:transparent!important;
}

.k-lesson-report-body-outer {
	border-left:1px solid $v-blue-darken-3;
	border-right:1px solid $v-blue-darken-3;
	border-bottom:1px solid $v-blue-darken-3;
	padding:0px;
	border-radius:0 0 8px 8px;
	min-height:300px;
}

.k-lesson-report-body-header {
	background-color:$v-blue-darken-3;
	color:#fff;
	padding:12px;
	display:flex;
	align-items:center;
}

.k-lesson-report-body-inner {
	padding:0 12px 12px 12px;
}
</style>
