<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-collections-unit-content-wrapper" :class="collection.units.length==1?'k-collections-unit-content-wrapper-single-unit':''">
	<div class="ma-2">
		<div class="d-flex">
			<h3 v-show="unit.title" class="ml-1" style="font-weight:normal"><v-icon class="mr-2" :class="folder_icon_css" style="font-size:20px;margin-top:-3px">fas fa-forward</v-icon><b v-if="unit.display_number" class="mr-2" v-html="unit.display_number"></b><span v-html="unit.title"></span><span v-if="unit.duration>0" class="ml-2" style="font-size:0.8em;color:#666;font-weight:bold">{{unit.duration}} WEEK{{unit.duration!=1?'S':''}}</span></h3>
			<v-spacer/>
			<!-- currently we only show the "View Content For" interface if enable_simulate_role_menu is 'yes' -->
			<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" small v-if="viewing_users_shadow_unit&&site_config.enable_simulate_role_menu=='yes'" class="k-edit-btn xk-tight-btn xk-nocaps-btn" color="#555" dark @click=""><v-icon class="mr-1" small>fas fa-user</v-icon>View Content For…</v-btn></template>
				<v-list dense min-width="250">
					<v-list-item @click="share_my_content"><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Share My Content for this collection with another user…</v-list-item-title></v-list-item>
					<v-list-item @click="subscribe_to_my_content"><v-list-item-icon><v-icon small>fas fa-cubes-stacked</v-icon></v-list-item-icon><v-list-item-title>Subscribe to another user’s My Content for this collection…</v-list-item-title></v-list-item>
					<!-- <v-divider/> -->
				</v-list>
			</v-menu>
		</div>

		<div v-if="unit.text" class="k-collection-unit-description-outer" v-visible="unit_description_style!='visibility:hidden'">
			<div v-if="!unit_descriptions_collapsed" class="k-collection-unit-description fr-view px-3 pt-2 pb-0 white" :style="(true||unit_show_full_description_btn)?'border-radius:10px 10px 0 0;border-bottom:1px solid #ccc;':'border-radius:10px;'">
				<div class="k-collection-unit-description-inner pb-2" :style="unit_description_style" v-html="U.render_latex(unit.text)"></div>
				<div style="clear:both;padding-bottom:1px;"></div><!-- this prevents annoying extra margin at the bottom of the description div -->
			</div>
			<div class="d-flex" style="cursor:pointer;border-radius:0 0 8px 8px;" :style="unit_descriptions_collapsed?'':'border-top:1px solid #ccc'">
				<v-btn v-if="!unit_descriptions_collapsed&&unit_show_full_description_btn" small text color="secondary" style="border-radius:0 0 0 8px" @click="unit.full_description_showing=!unit.full_description_showing">Show {{unit.full_description_showing?'Less':'More'}}<v-icon small class="ml-2">fas fa-caret-{{unit.full_description_showing?'up':'down'}}</v-icon></v-btn>
				<v-spacer/>
				<v-btn v-if="!unit_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="toggle_unit_descriptions_collapsed">Collapse Unit Descriptions<v-icon small class="ml-2">fas fa-xmark</v-icon></v-btn>
				<v-btn v-if="unit_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="toggle_unit_descriptions_collapsed">Show Unit Descriptions<v-icon small class="ml-2">fas fa-caret-down</v-icon></v-btn>
			</div>
		</div>
	</div>

	<div class="d-flex mt-4" v-if="unit.standards.length>0||show_my_content_tab"><v-spacer/><v-btn-toggle class="k-course-view-toggle-btn elevation-3" active-class="k-course-view-unit-toggle-btn-active-class" v-model="unit_mode" mandatory>
		<v-btn style="width:128px" small class="k-tight-btn k-nocaps-btn" :value="'resources'"><v-icon small class="mr-1" :color="(unit_mode=='resources')?'primary':'#888'">fas fa-box-archive</v-icon>Resources ({{base_unit_resource_count}})</v-btn>
		<v-btn v-if="unit.standards.length>0" style="width:128px" small class="k-tight-btn k-nocaps-btn" :value="'standards'"><v-icon small class="mr-1" :color="(unit_mode=='standards')?'primary':'#888'">fas fa-bullseye</v-icon>Standards ({{standards_count}})</v-btn>
		<v-btn v-if="show_my_content_tab" style="width:128px" small class="k-tight-btn k-nocaps-btn" :value="'my_content'"><v-icon small class="mr-1" :color="(unit_mode=='my_content')?'primary':'#888'">fas fa-cubes-stacked</v-icon>My Content ({{shadow_unit_resource_count}})</v-btn>
	</v-btn-toggle><v-spacer/></div>

	<div v-if="unit_mode=='resources'||unit_mode=='my_content'||unit.standards.length==0">
		<div style="display:flex" class="justify-center mx-2">
			<div style="flex:1 1 100%">
				<div class="d-flex align-center mt-3 mr-1 mb-2" v-if="unit_has_resources">
					<div class="k-mycontent-view-header" v-html="resources_header_text"></div>
					<div v-if="show_sort_by_control" class="d-flex align-center" style="margin-top:-8px">
						<div style="font-size:16px" class="mr-2">Sort by:</div>
						<div>
							<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="default_collection_sort_by" mandatory>
								<v-btn x-small width="90px" light :value="'title'" @click.stop="previous_default_collection_sort_by='title'">Title</v-btn>
								<v-btn x-small width="90px" light :value="'created_at'" class="k-tight-btn" @click.stop="set_created_at_sort_order">Date Created</v-btn>
							</v-btn-toggle>
						</div>
					</div>
					<v-spacer/>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="at_least_one_folder_closed" v-on="on" x-small fab outlined color="#555" class="elevation-0 mr-2" @click="open_all_folders"><v-icon small>fas fa-angles-down</v-icon></v-btn></template>Open all folders</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="at_least_one_folder_open" v-on="on" x-small fab outlined color="#555" class="elevation-0 mr-2" @click="close_all_folders"><v-icon small>fas fa-angles-up</v-icon></v-btn></template>Close all folders</v-tooltip>
					<v-btn-toggle v-if="collection.collection_type !== 'pd'" dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="collection_view_mode" mandatory>
						<v-btn x-small light :value="'tiles'" @click.stop="">Tile View</v-btn>
						<v-btn x-small light :value="'list'" @click.stop="">List View</v-btn>
					</v-btn-toggle>
				</div>
				<div v-if="!unit_has_resources" class="mt-5 text-center">
					<div v-if="unit_mode=='my_content'"><i>You have not created or copied any resources for this unit.</i></div>
					<div v-else><i>This {{collection.units.length>1?'unit':'collection'}} does not include any resources.</i></div>
				</div>
				<div class="k-collection-unit-wrapper-outer">
					<div class="k-collection-unit-wrapper">
						<div v-if="search_results_units&&!search_results_units.includes(unit)" class="text-center mt-4 pa-2 yellow lighten-3" style="border-radius:8px;"><i>No resources in this repository unit match your search terms.</i></div>
						<div class="k-collection-unit-resources mb-6">
							<CollectionResourceFolder ref="top_collection_resource_folder"
								:collection="collection" 
								:unit="content_unit" 
								:search_results_items="search_results_items" 
								:folder_id="'top'" 
								:enable_collection_editing="enable_collection_editing"
								@clear_collection_last_search_results="$emit('clear_collection_last_search_results')" 
								@save_unit_edits="save_unit_edits"
								@copy_to_my_content_finish="copy_to_my_content_finish"
								@resource_search_closed="cancel_unit_editing"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
							/>
						</div>
					</div>
				</div>
				<div v-if="false&&!is_collection_admin" class="mb-4 text-center"><v-btn outlined class="k-tight-btn k-nocaps-btn" color="primary" @click=""><v-icon small class="mr-2">fas fa-bolt</v-icon>Recommend a new Lesson, Student Activity or other Resource<v-icon small class="ml-2">fas fa-bolt</v-icon></v-btn></div>
				<div class="text-center">
					<v-btn small v-if="user_can_add_content" class="k-edit-btn xk-tight-btn xk-nocaps-btn mx-1 mb-3" color="#555" dark @click="add_content"><v-icon class="mr-1" small>fas fa-plus</v-icon>Add Content</v-btn>
					<v-btn small v-if="user_can_edit_unit" class="k-edit-btn xk-tight-btn xk-nocaps-btn mx-1 mb-3" color="#555" dark @click="edit_unit"><v-icon class="mr-2" small>fas fa-edit</v-icon>Edit Unit</v-btn>
					<v-btn small v-if="can_view_pd_report" class="k-edit-btn k-tight-btn xk-nocaps-btn mx-1 mb-3" color="#555" dark @click="show_todo_report=true"><v-icon class="mr-2" small>fas fa-list-check</v-icon>Show Resource Completion Report</v-btn>
					<v-btn small v-if="is_collection_admin&&collection.units.length==1" class="k-edit-btn k-tight-btn xk-nocaps-btn mx-1 mb-3" color="#555" dark @click="$emit('add_unit')"><v-icon small class="mr-2">fas fa-plus</v-icon>Add a new Unit to this Collection</v-btn>
				</div>

			</div>
		</div>
	</div>
	<div class="pl-1" v-if="unit_mode=='standards'&&unit.standards.length>0"><CollectionUnitStandards :lp="collection" :unit="unit" /></div>
	<CollectionUnitEdit v-if="show_unit_editor" :collection="collection" :original_unit="unit" @editor_cancel="cancel_unit_editing" />
	<TodoReportCollectionUnit v-if="show_todo_report" :collection="collection" :unit="unit" @dialog_cancel="show_todo_report=false" />
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionUnitStandards from './CollectionUnitStandards'
import CollectionResourceFolder from './CollectionResourceFolder'
import CollectionUnitEdit from './CollectionUnitEdit'
import TodoReportCollectionUnit from '../reports/TodoReportCollectionUnit.vue'

export default {
	components: { CollectionUnitStandards, CollectionResourceFolder, CollectionUnitEdit, TodoReportCollectionUnit },
	props: {
		collection: { type: Object, required: true },
		unit: { type: Object, required: true },
		is_collection_admin: { type: Boolean, required: true },
		unit_descriptions_collapsed: { type: Boolean, required: false, default() { return false }},
		unit_show_full_description_btn: { type: Boolean, required: true },
		unit_description_style: { type: String, required: true },
		search_results_items: { type: Array, required: false, default() { return [] }},
		search_results_units: { required: false, default() { return null }},
	},
	data() { return {
		show_todo_report: false,
		show_unit_editor: false,
		shadow_unit: null,
	}},
	watch: {
	},
	computed: {
		...mapState(['user_info', 'lp_edit_locked', 'my_lessons', 'my_resources', 'my_ca_mappings', 'site_config']),
		...mapGetters(['studentish_role', 'my_default_collection']),
		unit_mode: {
			get() { 
				// 'my' and 'pd' collections don't have 'my_content' unit_mode, so don't allow that mode in those types of collections
				if (['my','pd'].includes(this.collection.collection_type) && this.$store.state.lst.unit_mode == 'my_content') return 'resources'
				return this.$store.state.lst.unit_mode 
			},
			set(val) { this.$store.commit('lst_set', ['unit_mode', val]) }
		},
		content_unit() {
			// if this isn't an agency_sanctioned unit, or this isn't a teacher-level user, or unit_mode isn't 'my_content', show the "base" unit content
			if (!this.is_agency_sanctioned_collection) return this.unit
			if (this.studentish_role) return this.unit
			if (this.unit_mode != 'my_content') return this.unit
			
			// if we get to here this is an agency_sanctioned lp being viewed by a teacher and unit_mode is 'my_content', so show the teacher's "shadow unit", which we (currently) create locally, on the fly, below
			return this.shadow_unit
		},
		is_default_collection() { return (this.collection.course_code == 'default') },
		is_agency_sanctioned_collection() { return this.collection.agency_sanctioned },
		show_my_content_tab() { return this.is_agency_sanctioned_collection && this.shadow_unit != null },	// see note below
		viewing_shadow_unit() { return (this.unit_mode == 'my_content' && this.show_my_content_tab) },
		viewing_users_shadow_unit() { return (this.viewing_shadow_unit && this.shadow_unit.shadow_unit_owner_id == this.user_info.user_id) },
		show_sort_by_control() {
			if (this.is_default_collection) return true
			if (this.unit_mode == 'my_content') return true
			return false
		},
		user_can_add_content() {
			if (this.is_collection_admin) return true
			if (this.viewing_users_shadow_unit) return true
			return false
		},
		user_can_edit_unit() {
			return (this.is_collection_admin && !this.is_default_collection && this.unit_mode != 'my_content')
		},
		base_unit_resource_count() {
			// BUG: I believe that for TCC items, we're counting all the TCC items in every unit; we should only be counting the items that have folder assignments for this unit...
			// if we have search results, only count items that match search
			if (this.search_results_items.length > 0) {
				let n = 0
				for (let sri of this.search_results_items) {
					let item = (sri.type == 'lesson') ? this.unit.lessons.find(x=>x.lesson_id==sri.item_id) : this.unit.resources.find(x=>x.resource_id==sri.item_id)
					if (!item) continue
					if (!item.is_visible_by_user()) continue
					++n
				}
				return n
			} else {
				// count all items visible by the user
				let n = 0
				for (let resource of this.unit.resources) if (resource.is_visible_by_user()) ++n
				for (let lesson of this.unit.lessons) if (lesson.is_visible_by_user()) ++n
				return n
			}
		},
		shadow_unit_resource_count() {
			if (!this.shadow_unit) return 0
			// if we have search results, only count items that match search
			if (this.search_results_items.length > 0) {
				let n = 0
				for (let item of this.search_results_items) {
					if (this.shadow_unit.lessons.find(x=>x.lesson_id&&x.lesson_id==item.item_id) || this.shadow_unit.resources.find(x=>x.resource_id&&x.resource_id==item.item_id)) ++n
				}
				return n
			}
			// note: the user can definitionally see all items in their shadow unit	
			return this.shadow_unit.lessons.length + this.shadow_unit.resources.length
		},
		standards_count() {
			return this.unit.standards.length
		},
		enable_collection_editing() {
			// for most collections, the user has to click the edit button to edit the unit
			// but for the default collection, and shadow units, we don't allow for the edit button, so show editing functionality (e.g. remove) from here
			return this.is_default_collection || this.viewing_users_shadow_unit
		},
		collection_view_mode: {
			get() {
				if (this.collection.collection_type === 'pd') return 'list'
				return this.$store.state.lst.collection_view_mode
			},
			set(val) { this.$store.commit('lst_set', ['collection_view_mode', val]) }
		},
		default_collection_sort_by: {
			get() { return this.$store.state.lst.default_collection_sort_by },
			set(val) { this.$store.commit('lst_set', ['default_collection_sort_by', val]) }
		},
		unit_has_resources() {
			if (!this.content_unit) return false
			return this.content_unit.resources.length > 0 || this.content_unit.lessons.length > 0 || this.content_unit.resource_tree.folders > 0
		},
		can_view_pd_report() {
			// only applicable for PD collections
			if (this.collection.collection_type != 'pd') return false
			return vapp.has_admin_right('pd_rep.any')
		},
		at_least_one_folder_closed() {
			let cof = this.$store.state.lst.collections_opened_folders
			let folder_closed = (folder_id) => {
				if (folder_id != 'top' && !cof[folder_id]) return true

				for (let f of this.content_unit.resource_tree.folders) {
					if (f.parent_folder_id == folder_id) {
						if (folder_closed(f.folder_id)) return true
					}
				}
				return false
			}
			return folder_closed('top')
		},
		at_least_one_folder_open() {
			let cof = this.$store.state.lst.collections_opened_folders
			let folder_open = (folder_id) => {
				if (folder_id != 'top' && cof[folder_id] == 'open') return true

				for (let f of this.content_unit.resource_tree.folders) {
					if (f.parent_folder_id == folder_id) {
						if (folder_open(f.folder_id)) return true
					}
				}
				return false
			}
			return folder_open('top')
		},
		folder_icon_css() {
			return U.subject_tile_css(this.collection) + '-text'
		},
		resources_header_text() {
			if (this.is_default_collection || this.unit_mode == 'my_content') {
				// for the default collection, the units are named after the types;
				// and for "my content", the folders are named after the types
				return ''
			} else if (this.collection.agency_sanctioned) {
				return 'Resources, Lesson Templates, and Student Activities'
			} else {
				return 'Resources, Lessons, and Student Activities'
			}
		},
	},
	watch: {
		my_lessons: { deep:true, handler() { this.create_shadow_unit() }},
		my_resources: { deep:true, handler() { this.create_shadow_unit() }},
		'unit.lp_unit_id': { deep:false, handler() { this.create_shadow_unit() }},
	},
	created() {
		this.create_shadow_unit()
	},
	mounted() {
		this.previous_default_collection_sort_by = this.default_collection_sort_by
	},
	methods: {
		// this is where we, on the fly, create the "shadow" unit for the user, which includes items that they've copied from this unit, or items that they've created in this unit
		create_shadow_unit() {
			// parents/students currently can't do anything with resource copies, so no need for a shadow unit for them
			if (this.studentish_role) return

			// and no need for shadow units in the default collection
			if (this.is_default_collection) return

			// console.log('create_shadow_unit: ' + this.unit.lp_unit_id)

			// for non-studentish users, we always create the shadow unit for collections: we only show the "My Content" tab for agency-sanctioned collections, but for non-agency-sanctioned collections, we use the shadow unit to manage copies of the resources for students

			// create the shadow unit with 'my' lessons and resources tagged to this lp_unit_id
			let lessons = []
			for (let lesson of this.unit.lessons) {
				// note that we look both in my_xxx and in the default collection
				let copy = this.my_lessons.find(x=>x.lesson_template_id == lesson.lesson_id)
				if (!copy) copy = this.my_default_collection.units[0].lessons.find(x=>x.lesson_template_id == lesson.lesson_id)
				if (copy) lessons.push(copy)
			}
			let sparkl_activities = []
			let link_resources = []
			for (let resource of this.unit.resources) {
				// note that we look both in my_xxx and in the default collection
				let copy = this.my_resources.find(x=>x.resource_template_id == resource.resource_id)
				if (!copy) {
					if (resource.type == 'sparkl') copy = this.my_default_collection.units[1].resources.find(x=>x.resource_template_id == resource.resource_id)
					else copy = this.my_default_collection.units[2].resources.find(x=>x.resource_template_id == resource.resource_id)
				}
				if (copy) {
					if (resource.type == 'sparkl') sparkl_activities.push(copy)
					else link_resources.push(copy)
				}
			}

			// add items created by the user and expressly tagged to the unit
			for (let ca_mapping of this.my_ca_mappings) {
				if (ca_mapping.collection_id == this.collection.lp_id && ca_mapping.unit_id == this.unit.lp_unit_id) {
					if (ca_mapping.asset_type == 'lesson') {
						let item = this.my_lessons.find(x=>x.lesson_id == ca_mapping.asset_id)
						if (item && !lessons.find(x=>x.lesson_id == item.lesson_id)) lessons.push(item)
					} else {
						let item = this.my_resources.find(x=>x.resource_id == ca_mapping.asset_id)
						if (item) {
							if (item.type == 'sparkl' && !sparkl_activities.find(x=>x.resource_id == item.resource_id)) sparkl_activities.push(item)
							if (item.type != 'sparkl' && !link_resources.find(x=>x.resource_id == item.resource_id)) link_resources.push(item)
						}
					}
				}
			}

			// sort in alpha order; CollectionResourceFolder will change to date if necessary
			lessons.sort((a,b)=>U.natural_sort(a.lesson_title, b.lesson_title))
			sparkl_activities.sort((a,b)=>U.natural_sort(a.description, b.description))
			link_resources.sort((a,b)=>U.natural_sort(a.description, b.description))

			// create the shadow_unit, then add folders for lessons and resources
			let unit = new LP_Unit({
				shadows_lp_id: this.collection.lp_id,
				shadows_lp_unit_id: this.unit.lp_unit_id,
				shadow_unit_owner_id: this.user_info.user_id,
				lessons: lessons,
				resources: sparkl_activities.concat(link_resources),
			})
			// by giving the units consistent folder_ids, we'll remember what's open in lst
			if (lessons.length > 0) unit.create_resource_folder('top', 'My Lessons', lessons, 'blue', `shadow-folder-${this.unit.lp_unit_id}-${this.user_info.user_id}-lessons`)
			if (sparkl_activities.length > 0) unit.create_resource_folder('top', `My ${this.site_config.sparkl_app_name} Student Activities`, sparkl_activities, 'purple', `shadow-folder-${this.unit.lp_unit_id}-${this.user_info.user_id}-sparkl_activities`)
			if (link_resources.length > 0) unit.create_resource_folder('top', 'My Other Resources', link_resources, 'orange', `shadow-folder-${this.unit.lp_unit_id}-${this.user_info.user_id}-link_resources`)

			this.shadow_unit = unit
		},

		toggle_unit_descriptions_collapsed() {
			this.$store.commit('lst_set', ['unit_descriptions_collapsed', !this.unit_descriptions_collapsed])
		},

		set_created_at_sort_order(val) {
			if (this.previous_default_collection_sort_by == 'created_at') {
				if (this.$store.state.lst.default_collection_sort_by_created_at_order == 'desc') this.$store.commit('lst_set', ['default_collection_sort_by_created_at_order', 'asc'])
				else this.$store.commit('lst_set', ['default_collection_sort_by_created_at_order', 'desc'])
			}
			this.previous_default_collection_sort_by = 'created_at'
			// console.log(`this.previous_default_collection_sort_by: ${this.previous_default_collection_sort_by}`)
		},

		narrow_window() {
			return $(window).width() < 1000
		},

		open_all_folders() {
			let cof = extobj(this.$store.state.lst.collections_opened_folders)
			for (let f of this.content_unit.resource_tree.folders) cof[f.folder_id] = 'open'
			this.$store.commit('lst_set', ['collections_opened_folders', cof]) 
		},

		close_all_folders() {
			let cof = extobj(this.$store.state.lst.collections_opened_folders)
			for (let f of this.content_unit.resource_tree.folders) delete cof[f.folder_id]
			this.$store.commit('lst_set', ['collections_opened_folders', cof]) 
		},

		edit_unit() {
			// we have to request to edit the unit, to make sure no one else already has the lp opened for editing -- but not for the default collection
			if (this.is_default_collection) {
				this.show_unit_editor = true
				return
			}

			this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
				console.log('edit request: ' + result.status)
				this.show_unit_editor = true

			}).catch((e)=>{
				// if this doesn't work, don't enter edit mode
				console.log('error requesting editor checkout for collection unit', e)
			})
		},

		cancel_unit_editing() {
			// when editing is canceled, clear the edit lock. Note that we do this both when the unit editor is closed and when the ResourceSearch dialog is closed
			if (!this.is_default_collection && !this.viewing_shadow_unit) this.$store.dispatch('edit_access_control_checkin', {lp_id: this.collection.lp_id})
			this.show_unit_editor = false
		},

		add_content() {
			// if we're viewing the default collection, or a user's shadow unit, just start the process
			if (this.is_default_collection || this.viewing_users_shadow_unit) {
				this.$refs.top_collection_resource_folder.search_start()
				return
			}
			
			// adding content is the same as editing the unit, so we have to request to edit the unit, to make sure no one else already has the lp opened for editing
			this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
				console.log('edit request (add_content): ' + result.status)
				this.$refs.top_collection_resource_folder.search_start()

			}).catch((e)=>{
				// if this doesn't work, don't enter edit mode
				console.log('error requesting editor checkout for collection unit add_content', e)
			})
		},

		// this fn is called when the CollectionResourceFolder emits save_unit_edits
		save_unit_edits(flag) {
			console.log('CollectionUnit: save_unit_edits: ', flag)
			if (this.viewing_users_shadow_unit) {
				this.$store.dispatch('synch_shadow_unit_assets', this.shadow_unit).then(()=>{
					this.create_shadow_unit()
					// when we're done, we have to call add_content again if we were adding from search; this re-creates the placeholder item
					if (flag == 'add_items_from_search') setTimeout(x=>this.add_content())
				})

			// if we're editing a "real" collection...
			} else if (!this.is_default_collection) {
				// CollectionResourceFolder will have made the necessary updates to the resources or lessons array of the unit, so we just have to dispatch save_learning_progression
				this.$store.dispatch('save_learning_progression', this.collection).then(()=>{})

			// else we're editing the default collection...
			} else {
				// so dispatch save_default_collection_resources
				this.$store.dispatch('save_default_collection_resources').then(()=>{
					// when we're done, we have to call add_content again if we were adding from search; this re-creates the placeholder item
					if (flag == 'add_items_from_search') this.add_content()
				})
			}
		},

		// this is emitted from ResourceCollectionItem when the user copies an item for their use
		copy_to_my_content_finish(copied_item) {
			// add the item to your shadow unit, and mark the item as the last-accessed item
			let msg
			if (!empty(copied_item.lesson_id)) {
				msg = `A copy of the lesson has been imported for your use. You can start modifying the lesson now.`

				// push to the shadow unit's lessons array
				this.$store.commit('set', [this.shadow_unit.lessons, 'PUSH', copied_item])
				this.$store.commit('set', ['last_viewed_resource_id', copied_item.lesson_id])

			} else if (copied_item.type == 'sparkl') {
				msg = `A copy of the activity has been imported for your use. You can use the activity with your students, and/or modify the activity if you wish, now.`

				// push to the shadow unit's resources array
				this.$store.commit('set', [this.shadow_unit.resources, 'PUSH', copied_item])
				this.$store.commit('set', ['last_viewed_resource_id', copied_item.resource_id])

			} else {
				// for non-sparkl resources, we always just add an "alias" of the item ???
				msg = `The resource has been imported to your “My Content” area.`
				// push to the shadow unit's resources array
				this.$store.commit('set', [this.shadow_unit.resources, 'PUSH', copied_item])
				this.$store.commit('set', ['last_viewed_resource_id', copied_item.resource_id])
			}

			// call the synch_shadow_unit_assets service to save the shadow unit mappings, then trigger to update the shadow unit
			this.$store.dispatch('synch_shadow_unit_assets', this.shadow_unit).then(()=>{
				this.create_shadow_unit()
			})

			// show the message
			this.$alert({
				text: msg,
				dialogMaxWidth: 500,
			}).then(y => {
			})
		},

		share_my_content() {
			
		},
		
		subscribe_to_my_content() {

		},
	}
}
</script>

<style lang="scss">
.k-collections-unit-content-wrapper {
	flex:1 1 auto;
	border-radius:0 12px 12px 0;
	background-color:#fff;
}

.k-collections-unit-content-wrapper-single-unit {
	border-radius:12px;
}

.k-collection-unit-description-outer {
	max-width:820px;
	border-radius:10px;
	margin:8px auto 12px auto;
	border:1px solid #ccc;
	background-color:#f8f8f8;

	.fr-view table td, .fr-view table th { 
		border:0;
		padding:4px;
	}
}

.k-collection-unit-description {
	font-size:14px;
	line-height:18px;
	padding:12px 8px 8px 8px;
}

.k-collection-unit-wrapper-outer {
	max-width:1000px;
	min-height:240px;

	.k-collection-unit-wrapper {
		font-size:16px;
		padding-bottom:8px;
		width:100%;
		// margin:8px;
		background-color:#fff;
		// border-radius:10px;
		overflow:auto;

		.k-collection-unit-section-header {
			font-weight:bold;
			background-color:$v-blue-darken-2;
			border-radius:5px;
			padding:2px 8px;
			color:#fff;
			font-size:20px;
		}
	}
}


.k-collection-unit-resources {
	.k-resource-link {
		background-color:$v-amber-accent-2;		// #fbe4d4
		border-radius:8px;
		padding:6px 2px 6px 2px;
		position:static;
		min-height:0;
		white-space: normal;
		height:100%;
		text-align:center;

		// flex:1 0 300px;

		.k-resource-link-inner {
			display:flex;
			align-items: center;
			min-height:30px;
			position:relative;
		}

		a {
			color:#000!important;	// #b48a6b
			display:flex;
			align-items: flex-start;
			// padding-top:6px;
		}
		a span:hover {
			text-decoration:underline;
		}
		.k-resource-description-text {
			flex:1 1 auto;
		}
		.v-icon.k-resource-type-icon { color:#000!important; margin-right:4px; }
		.k-resource-link-options-menu-btn {
			// margin-top:-4px;
			color:#000!important;
		}

		.k-resource-link-description {
			flex:1 1 100%;
			margin-left:5px;
			margin-top:0;
			word-break: break-word;
			line-height:16px;
			text-align:left;
		}
	}

	.k-resource-link-small {
		padding: 6px 4px 2px 3px;
		border-radius:6px;
		.k-resource-link-inner {
			min-height:10px;
		}
	}

	.k-resource-link--assessment {
		background-color:$v-pink-accent-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--teacher-facing {
		background-color:$v-blue-darken-3;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--adv {
		background-color:$v-purple-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--stem {
		background-color:$v-lime-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--leader-resource {
		background-color:$v-brown-darken-3;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--ese {
		background-color:$v-teal-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--not-district-sanctioned {
		background-color:$v-red-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--unit-planning-guide {
		background-color:$v-green-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--course-guidance {
		background-color:$v-cyan-darken-3;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link-check-box {
		color:$v-pink-accent-1!important;
	}

	// .k-resource-link--search_match {
	// 	background-color:$v-yellow-lighten-3!important;
	// 	border:2px solid $v-yellow-darken-1!important;
	// 	padding:2px 6px 2px 4px;
	// }
}

</style>
