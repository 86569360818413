<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<div class="float-right"><v-btn @click="load_organization_list">Load / Reload LTI Installation List</v-btn></div>
		<v-icon @click="return_to_admin_main" class="k-page-title-btn k-elevated">fas fa-plug</v-icon>
		{{site_config.sparkl_app_name}} LTI Installations
	</h2>

	<div v-if="organizations" style="clear:both">
		<div class="py-4 d-flex">
			<v-text-field
				v-model="search"
				prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
				label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
			></v-text-field>
			<v-btn class="ml-8" color="primary" @click="create_org"><v-icon small class="mr-2">fas fa-plus</v-icon> Add New LTI Installation</v-btn>
		</div>

		<v-data-table light
			:headers="headers"
			:items="organizations"
			xsort-by="['su','user']"
			xsort-desc="[true,false]"
			xmust-sort="true"
			:custom-filter="table_search_filter"
			:search="search"
			hide-default-footer
			:footer-props="footer_options"
			:items-per-page="-1"
			class="k-admin-users-table"
		>
			<template v-slot:item="{ item }"><tr>
				<td>
					<v-btn icon small class="mr-2" @click="edit_org(item, 'organization_name', 'Organization Name')"><v-icon small>fas fa-edit</v-icon></v-btn>
					<span v-html="item.organization_name"></span>
				</td>
				<td><v-btn icon small class="mr-2" @click="edit_org(item, 'key_key', 'Organization Key')"><v-icon small>fas fa-edit</v-icon></v-btn>{{item.key_key}}</td>
				<td><v-btn icon small class="mr-2" @click="edit_org(item, 'secret', 'Secret')"><v-icon small>fas fa-arrows-rotate</v-icon></v-btn><span style="font-size:10px">⏺⏺⏺⏺⏺⏺⏺⏺–⏺⏺⏺⏺–⏺⏺⏺⏺–⏺⏺⏺⏺–⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺⏺</span><v-btn class="ml-2" icon small color="primary" @click="copy_secret(item)"><v-icon small>fas fa-clipboard</v-icon></v-btn></td>
				<td class="text-center">{{item.created_at.substr(0,10)}}</td>
				<td class="text-center">{{item.login_at ? item.login_at.substr(0,10) : '–'}}</td>
				<td class="text-center">{{item.login_count}}</td>
				<td class="text-center"><v-btn icon small class="ml-2" color="red" @click="delete_org(item)"><v-icon small>fas fa-trash-alt</v-icon></v-btn></td>
			</tr></template>
		</v-data-table>
	</div>
	<div v-else class="mt-12 mb-3"><i>This area allows you to manage LTI installations for {{site_config.sparkl_app_name}}. To get started, click the “LOAD / RELOAD LTI INSTALLATION LIST” button above.</i></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import AdminUserEdit from './AdminUserEdit'

export default {
	components: { },
	props: {
	},
	data() { return {
		organizations: null,
		headers: [
			{ text: 'Organization Name', align: 'left', sortable: true, value:'organization_name' },
			{ text: 'Key', align: 'left', sortable: true, value:'key_key' },
			{ text: 'Secret', align: 'center', sortable: false, value:'secret' },
			{ text: 'Created', align: 'center', sortable: true, value:'created_at' },
			{ text: 'Last LTI Login', align: 'center', sortable: true, value:'login_at' },
			{ text: 'LTI Login Ct', align: 'center', sortable: true, value:'login_count' },
			{ text: '', sortable: false },	// for delete btn
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		user_being_edited: null,
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'site_config']),
		// rows() {
		// 	let arr = []

		// 	for (let org of this.organizations) {
		// 		arr.push(org)
		// 	}

		// 	return arr
		// },
	},
	created() {
		// DEBUG
		vapp.admin_lti_component = this
	},
	mounted() {
	},
	methods: {
		load_organization_list() {
			let payload = {
				user_id: this.user_info.user_id,
			}
			U.loading_start()
			U.ajax('admin_manage_lti_organizations', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in admin ajax call: ' + result.status); vapp.ping(); return;
				}

				this.organizations = result.organizations
			});
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check org name and key
			if (item.organization_name.search(re) > -1) return true
			if (item.key_key.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		edit_org(org, key, key_title) {
			if (key == 'secret') {
				this.$confirm({
					title: 'Generate New LTI Secret',
					text: 'Are you sure you want to generate a new secret for this LTI installation? <b class="red--text text--darken-2">If you do this, LTI links for the installation will cease to work until the organization updates their installation with the new secret.</b>',
					acceptText: 'Generate New Secret',
					dialogMaxWidth: 600,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					let updated_org = {organization_id: org.organization_id}
					updated_org.secret = U.new_uuid()
					this.submit_org_crud(updated_org, 'Secret updated', `Are you ABSOLUTELY SURE you want to generate a new secret for ${org.organization_name}? THIS IS NOT UNDOABLE!`)
				}).catch(n=>{console.log(n)}).finally(f=>{})

			} else {
				let text = ''
				if (key == 'key_key') text += '<div class="mb-2">Are you sure you want to update the LTI key for this LTI installation? <b class="red--text text--darken-2">If you do this, LTI links for the installation will cease to work until the organization updates their installation with the new key.</b></div>'
				text += `Enter new value for ${key_title}:`
				this.$prompt({
					title: 'Update LTI Installation',
					text: text,
					dialogMaxWidth: 600,
					initialValue: org[key],
					acceptText: 'Update',
				}).then(val => {
					val = $.trim(val)
					if (empty(val)) return

					// make sure val doesn't match any other organization
					let other_org = this.organizations.find(x=>x[key] == val && x.organization_id != org.organization_id)
					if (other_org) {
						this.$alert(`Another LTI installation (${other_org.organization_name}) already has that ${key}.`)
						return
					}

					if (val != org[key]) {
						let updated_org = {organization_id: org.organization_id}
						updated_org[key] = val

						let reconfirm_msg = (key == 'key_key') ? `Are you ABSOLUTELY SURE you want to update the LTI key for ${org.organization_name}? THIS IS NOT UNDOABLE!` : ''
						this.submit_org_crud(updated_org, `${key_title} updated`, reconfirm_msg)
					}
				})
			}
		},

		create_org() {
			this.$prompt({
				title: 'Create New LTI Installation',
				text: 'Enter organization name for LTI Installation (e.g. “Bartow County”):',
				initialValue: '',
				disableForEmptyValue: true,
				acceptText: 'Next',
				acceptIconAfter: 'fas fa-arrow-right',
			}).then(organization_name => {
				organization_name = $.trim(organization_name)
				if (empty(organization_name)) return

				// make sure val doesn't match any other organization
				let other_org = this.organizations.find(x=>x.organization_name == organization_name)
				if (other_org) {
					this.$alert(`An LTI installation with that name (${organization_name}) already exists.`)
					return
				}

				this.$prompt({
					title: 'Create New LTI Installation',
					text: 'Enter organization “key” for LTI Installation (e.g. “bartow.k12.ga.us”):',
					initialValue: '',
					disableForEmptyValue: true,
					acceptText: 'Create',
					acceptIconAfter: 'fas fa-arrow-right',
				}).then(key_key => {
					key_key = $.trim(key_key)
					if (empty(key_key)) return

					// make sure val doesn't match any other organization
					let other_org = this.organizations.find(x=>x.key_key == key_key)
					if (other_org) {
						this.$alert(`Another LTI installation (${other_org.organization_name}) is already using that key (${key_key}).`)
						return
					}

					this.submit_org_crud({organization_name: organization_name, key_key: key_key, secret: U.new_uuid()}, `LTI installation for ${organization_name} created`)

				}).catch(n=>{console.log(n)}).finally(f=>{})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_org(org) {
			this.$confirm({
				title: 'Are you sure?',
				text: 'Are you sure you want to delete this LTI installation? <b class="red--text text--darken-2">If you proceed, any LTI links created for this installation will cease to work.</b>',
				acceptText: 'Delete LTI Installation',
				acceptColor: 'red',
				dialogMaxWidth: 600,
			}).then(y => {
				this.submit_org_crud({organization_id: org.organization_id, delete: 'delete'}, `LTI installation for ${org.organization_name} deleted`, `Are you ABSOLUTELY SURE you want to delete the LTI installation for ${org.organization_name}? THIS IS NOT UNDOABLE!`)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		submit_org_crud(updated_org, inform_msg, reconfirm_msg) {
			if (reconfirm_msg) {
				this.$confirm({
					title: 'Re-Confirm',
					text: reconfirm_msg,
					acceptText: 'Proceed',
					acceptColor: 'red',
					dialogMaxWidth: 600,
				}).then(y => {
					this.submit_org_crud(updated_org, inform_msg)
				}).catch(n=>{console.log(n)}).finally(f=>{})
				return
			}

			let payload = {
				user_id: this.user_info.user_id,
				updated_org: updated_org
			}
			// admin_manage_lti_organizations serves both to retrieve list of organizations and handle CRUD operations
			U.loading_start()
			U.ajax('admin_manage_lti_organizations', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in admin ajax call: ' + result.status); vapp.ping(); return;
				}

				this.$inform(inform_msg)

				this.organizations = result.organizations
			});
		},

		copy_secret(org) {
			U.copy_to_clipboard(`KEY: ${org.key_key} \nSECRET: ${org.secret}\n`)
			this.$inform('Key and secret copied to clipboard')
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		},
	}
}
</script>

<style lang="scss">
</style>
