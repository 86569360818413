<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Duplicate {{site_config.sparkl_app_name}} Activity</b></v-card-title>
			<v-card-text v-if="activity_data" class="mt-3" style="font-size:16px">
				<div>Original activity title: <b>{{activity_data.title}}</b></div>
				<div class="mt-4"><v-text-field outlined dense hide-details label="Duplicate activity title" v-model="title_start"></v-text-field></div>
				<div class="mt-3">This activity includes {{activity_data.exercise_records.length}} “exercises”. Choose the exercises you would like to use in the new activity you’re creating, then choose an option below:</div>
				<div class="mt-2 text-center">
					<v-btn x-small outlined color="primary" class="mx-1" @click="select_all">Select All</v-btn>
					<v-btn x-small outlined color="primary" class="mx-1" @click="select_none">Select None</v-btn>
				</div>
				<div v-for="(exercise, index) in activity_data.exercise_records" class="mt-1">
					<v-checkbox class="mt-0 pt-0" :label="exercise_label(exercise, index)" v-model="included_exercises[exercise.exercise_id]" hide-details></v-checkbox>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="$emit('dialog_cancel')">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-menu top><template v-slot:activator="{on}"><v-btn v-show="n_included_exercises>0" v-on="on" class="ml-3" color="primary"><v-icon small class="mr-2">fas fa-plus-circle</v-icon>Create New Activity...</v-btn></template>
					<v-list min-width="250">
						<v-list-item v-show="n_included_exercises==1" @click="create_new('single')"><v-list-item-title>Create a new activity with the selected exercise</v-list-item-title></v-list-item>
						<v-list-item v-show="n_included_exercises>1" @click="create_new('single')"><v-list-item-title>Create <b>a single new activity</b> with the {{n_included_exercises}} selected exercises</v-list-item-title></v-list-item>
						<v-list-item v-show="n_included_exercises>1" @click="create_new('multiple')"><v-list-item-title>Create <b>{{n_included_exercises}} new activities</b>, one for each selected exercise</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		tool_activity_id: { type: Number, required: true },
	},
	data() { return {
		dialog_open: true,
		activity_data: null,
		included_exercises: {},
		title_start: '',
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters([]),
		n_included_exercises() {
			let n = 0
			for (let exercise_id in this.included_exercises) if (this.included_exercises[exercise_id]) ++n
			return n
		},
	},
	watch: {
	},
	created() {
		vapp.duplicate_activity_component = this
		// Call a service to get a list of the exercises from the activity
		U.loading_start()
		U.ajax('get_activity_exercise_list', {user_id: this.user_info.user_id, tool_activity_id: this.tool_activity_id}, result=>{
			U.loading_stop()
			if (result.status != 'ok') {
				this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
			}

			// set activity_data to show the interface
			result.activity_data.exercise_records = result.exercises
			this.activity_data = result.activity_data

			this.select_all()
			this.title_start = this.activity_data.title

			console.log('get_activity_exercise_list', result)
		})
	},
	mounted() {
	},
	methods: {
		exercise_label(exercise, index) {
			let s = `${index+1}. `
			if (exercise.exercise_title) s += exercise.exercise_title
			else s += `${U.capitalize_word(exercise.exercise_type)} Exercise`
			s += ` (${exercise.stars_available} ${U.ps('star', exercise.stars_available)})`
			return s
		},
		select_all() {
			let o = {}
			for (let exercise of this.activity_data.exercise_records) {
				o[exercise.exercise_id] = true
			}
			this.included_exercises = o
		},
		select_none() {
			let o = {}
			for (let exercise of this.activity_data.exercise_records) {
				o[exercise.exercise_id] = false
			}
			this.included_exercises = o
		},

		create_new(which) {
			let new_activity_shell = (duplicate_of)=>{
				let o = extobj(this.activity_data)
				
				// mark the new activity as a duplicate of the original activity
				o.duplicate_of = duplicate_of ?? ''
				// set editor to the current user
				o.editors = `,${this.user_info.email},`
				// create new exercises array to receive duplicated exercises
				o.exercises = []
				// we'll add up stars_available below
				o.stars_available = 0
				// clear title; we'll set it below
				o.title = ''

				// delete the exercise_records we added when get_activity_exercise_list was called
				delete o.exercise_records

				// delete other properties that we'll want to be re-established in the new activity
				delete o.activity_id
				delete o.created_at
				delete o.created_for_lti_link_id
				delete o.last_save_ts
				delete o.login_at
				delete o.login_count
				delete o.updated_at
				
				return o
			}

			// start the payload
			let payload = { 
				user_id: this.user_info.user_id, 
				sparkl_activities: [],
			}

			// for a single activity, start its new shell
			if (which == 'single') {
				// in this case, set duplicate_of to the original
				payload.sparkl_activities.push(new_activity_shell(this.activity_data.activity_id))
				// if title_start is empty, use the original title
				if (empty(this.title_start)) payload.sparkl_activities[0].title = this.activity_data.title
				else payload.sparkl_activities[0].title = this.title_start
			}

			// go through each included exercise
			let ex_n = 0
			for (let exercise_id in this.included_exercises) {
				++ex_n
				if (this.included_exercises[exercise_id]) {
					let exercise_record = this.activity_data.exercise_records.find(x=>x.exercise_id==exercise_id)
					let activity
					// if we're creating a single activity, we're adding to the activity shell we created above
					if (which == 'single') {
						activity = payload.sparkl_activities[0]

					// otherwise we're making an activity for each exercise, so create a new activity shell here
					} else {
						// for single-exercise activities, don't make them duplicates
						activity = new_activity_shell()
						payload.sparkl_activities.push(activity)

						// set activity title to include info about the exercise
						// if the exercise has a title...
						if (!empty(exercise_record.exercise_title)) {
							// if user didn't supply an title_start, use the exercise_title as-is
							if (empty(this.title_start)) activity.title = exercise_record.exercise_title
							// else append the exercise_title to the end of the title_start
							else activity.title = `${this.title_start}: ${exercise_record.exercise_title}`
						} else {
							// we don't have an exercise title. If user didn't supply a title_start, use the original activity title; otherwise start with title_start
							if (empty(this.title_start)) activity.title = this.activity_data.exercise_title
							else activity.title = this.title_start
							// then add ': 1', ': 2', etc.
							activity.title += ': ' + ex_n
						}
					}

					// now add the exercise to the activity.exercises array
					activity.exercises.push({
						exercise_id: exercise_id,
						// add original_activity_id field; that lets Sparkl know that if the exercise gets edited in the duplicate, we have to first make a copy of the exercise
						original_activity_id: this.activity_data.activity_id,
					})
					// and update stars_available
					activity.stars_available += exercise_record.stars_available
				}
			}

			console.log(extobj(payload.sparkl_activities))

			// stringify the sparkl_activities array, since they might be big
			payload.sparkl_activities = JSON.stringify(payload.sparkl_activities)

			// Call a service to create the Sparkl activities and cureum resources
			U.loading_start()
			U.ajax('create_duplicate_activities', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
				}
				console.log('create_sparkl_activities', result)
				// on success, emit event to parent to do something with our new activity(s), the data for which will be in result.cureum_resources
				this.$emit('duplicate_activities_created', result.cureum_resources)
			})

		},
	}
}
</script>

<style lang="scss">
</style>