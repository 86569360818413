<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div :class="top_css_class"><div class="k-collections-view-wrapper-inner">

		<h2 v-if="!show_collection_only" class="k-page-title d-flex align-center">
			<v-icon v-show="collection_icon" :class="collection_icon_css_class" class="mr-3" style="font-size:30px">fas {{collection_icon}}</v-icon>
			<span v-if="initialized==0">LOADING…</span>
			<span v-else v-html="page_title"></span>
			<b v-if="initialized&&collection.active=='no'" class="ml-8 red--text">[Inactive]</b>
			<v-spacer/>

			<v-btn v-if="show_school_admin_btn" color="primary" class="ml-2 k-tight-btn k-nocaps-btn" @click="school_admin_interface_showing=!school_admin_interface_showing"><v-icon small class="mr-1">fas fa-screwdriver-wrench</v-icon> School Admin Tools</v-btn>
			<v-btn v-if="show_pd_resources_btn" color="primary" class="ml-2 k-tight-btn k-nocaps-btn" @click="pd_resources_showing=!pd_resources_showing"><v-icon small class="mr-1">fas fa-user-graduate</v-icon> PD Resources</v-btn>
			<v-btn v-if="show_course_standards_btn" color="primary" class="ml-2 k-tight-btn k-nocaps-btn" @click="show_course_standards"><v-icon small class="mr-1">fas fa-bullseye</v-icon> Course Standards</v-btn>
			<v-btn v-if="is_default_collection" color="secondary" class="k-tight-btn k-nocaps-btn" @click="create_new_mycollection"><v-icon small class="mr-2">fas fa-cubes-stacked</v-icon>Create a New Content Collection</v-btn>
			<v-menu v-if="show_collection_menu" bottom right><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-2" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template><!-- v-if="course_removable||(course_addable&&$vuetify.breakpoint.xsOnly)||user_is_superuser" -->
				<v-list>
					<v-list-item @click="export_common_cartridge"><v-list-item-icon><v-icon small>fas fa-file-zipper</v-icon></v-list-item-icon><v-list-item-title>Export content from this collection…</v-list-item-title></v-list-item>
					<v-list-item v-if="course_removable" @click="remove_from_my_courses"><v-list-item-icon><v-icon small>fas fa-minus-circle</v-icon></v-list-item-icon><v-list-item-title>Remove from My Favorite Collections</v-list-item-title></v-list-item>
					<v-list-item v-if="course_addable" @click="add_to_my_courses"><v-list-item-icon><v-icon small>fas fa-plus-circle</v-icon></v-list-item-icon><v-list-item-title>Add to My Favorite Collections</v-list-item-title></v-list-item>
					<v-list-item v-if="collection_is_shareable" @click="open_share_dialog"><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>{{course_or_repo?'Share “My Content” for this collection with other users':'Share collection with other users…'}}</v-list-item-title></v-list-item>
					<!-- TODO: to be done by Mathew -->
					<!-- <v-list-item v-if="signed_in&&course_or_repo" @click=""><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Manage subscriptions to other users’ “My Content” for this collection</v-list-item-title></v-list-item> -->
					<v-list-item v-if="user_can_archive_collection" @click="archive_collection"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove this collection…</v-list-item-title></v-list-item>
					<v-list-item v-if="user_subscribed_to_collection" @click="remove_collection_subscription"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>“Unsubscribe” from this collection…</v-list-item-title></v-list-item>
					<v-list-item v-if="user_is_superuser" @click="remove_edit_lock"><v-list-item-icon><v-icon small>fas fa-unlock</v-icon></v-list-item-icon><v-list-item-title>Remove “edit lock” for this course</v-list-item-title></v-list-item>
					<v-list-item v-if="can_view_report" @click="todo_report_showing=true"><v-list-item-icon><v-icon small>fas fa-list-check</v-icon></v-list-item-icon><v-list-item-title>Show Resource Completion Report</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</h2>

		<div v-if="initialized" v-show="!show_collection_editor">
			<div v-if="studentish_role&&teacher_display" class="k-course-view-teacher_display" v-html="teacher_display"></div>

			<div v-if="!show_collection_only&&collection.description" class="k-collections-collection-description mx-auto" :class="collection_descriptions_collapsed?'':'elevation-1'">
				<div v-if="!collection_descriptions_collapsed" class="fr-view white" :style="(true||collection_show_full_description_btn)?'border-radius:10px 10px 0 0;':'border-radius:10px;margin-bottom:8px;'">
					<div class="k-collections-collection-description-inner py-2 px-3" :style="collection_description_style" v-html="U.render_latex(collection.description)"></div>
					<div style="clear:both;padding-bottom:1px;"></div><!-- this prevents annoying extra margin at the bottom of the description div -->
				</div>
				<div class="d-flex" style="cursor:pointer;border-radius:0 0 8px 8px;" :style="collection_descriptions_collapsed?'':'border-top:1px solid #ccc'">
					<v-btn v-if="!collection_descriptions_collapsed&&collection_show_full_description_btn" small text color="secondary" style="border-radius:0 0 0 8px" @click="collection.full_description_showing=!collection.full_description_showing">Show {{collection.full_description_showing?'Less':'More'}}<v-icon small class="ml-2">fas fa-caret-{{collection.full_description_showing?'up':'down'}}</v-icon></v-btn>
					<v-spacer/>
					<v-btn v-if="!collection_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="collection_descriptions_collapsed=!collection_descriptions_collapsed">Collapse Collection Descriptions<v-icon small class="ml-2">fas fa-xmark</v-icon></v-btn>
					<v-btn v-if="collection_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="collection_descriptions_collapsed=!collection_descriptions_collapsed">Show Collection Descriptions<v-icon small class="ml-2">fas fa-caret-down</v-icon></v-btn>
				</div>
			</div>

			<div class="my-4 d-flex">
				<v-spacer/>
				<div style="flex:0 0 auto; width:700px; max-width:calc(100vw - 40px)">
					<v-text-field light rounded outlined solo hide-details clearable dense
						placeholder="Search content item titles…"
						v-model="search_terms"
						prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
						@click:clear="execute_search_clear"
						@keyup="search_field_keyup"
						autocomplete="new-password"
					></v-text-field>
					<div v-if="search_term_res.length>0">
						<div v-if="search_results_units||search_results_items.length>0" class="k-resource-search-results">{{search_results_items.length}} {{search_results_items.length==1?'item':'items'}} in {{search_results_units.length}} {{search_results_units.length==1?'unit':'units'}} {{search_results_items.length==1?'matches':'match'}} your search terms.</div>
						<div v-else class="k-resource-search-results k-resource-search-results-no-results">No units or items match your search terms.</div>
					</div>
				</div>
				<v-spacer/>
				<!-- <v-btn small color="primary" class="k-tight-btn k-nocaps-btn ml-3" @click="find_additional">Find Additional Resources <v-icon class="ml-2" small>fas fa-search</v-icon></v-btn> -->
			</div>

			<div v-if="collection_has_units">
				<CollectionUnitsVertical v-if="collection.lp_layout=='tree'" ref="collection_units"
					:collection="collection" :unit_showing="unit_showing" :search_results_units="search_results_units" :search_results_items="search_results_items" :unit_descriptions_collapsed="unit_descriptions_collapsed" :unit_show_full_description_btn="unit_show_full_description_btn" :unit_description_style="unit_description_style" :is_collection_admin="is_collection_admin"
					@add_unit="add_unit" @toggle_unit="toggle_unit" 
					@clear_collection_last_search_results="clear_collection_last_search_results"
					@lesson_shift_update="lesson_shift_update"
				/>
				<CollectionUnitsHorizontal v-if="collection.lp_layout=='map'" ref="collection_units"
					:collection="collection" :unit_showing="unit_showing" :search_results_units="search_results_units" :search_results_items="search_results_items" :unit_descriptions_collapsed="unit_descriptions_collapsed" :unit_show_full_description_btn="unit_show_full_description_btn" :unit_description_style="unit_description_style" :is_collection_admin="is_collection_admin"
					@add_unit="add_unit" @hide_unit="hide_unit" @show_unit="show_unit" 
					@clear_collection_last_search_results="clear_collection_last_search_results"
					@lesson_shift_update="lesson_shift_update"
				/>
			</div>
			<div v-if="!collection_has_units" class="text-center mt-5 mb-3">
				<i>This collection does not currently include any content.</i>
				<!-- if enable_simulate_role_menu is on, show an additional message... -->
				<div v-if="site_config.enable_simulate_role_menu=='yes'" class="mt-2"><b><i>Please see courses marked with an asterisk (*) for sample content collections.</i></b></div>
			</div>
			
			<div v-show="is_collection_admin&&!is_default_collection" class="mt-4 mb-1 text-center">
				<v-btn class="k-edit-btn" color="primary" @click="edit_collection"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Collection</v-btn>
			</div>

		</div>
		<CollectionEdit v-if="show_collection_editor" :learning_progression="collection" @editor_cancel="cancel_collection_editing"></CollectionEdit>
		<CollectionManageSharing v-if="show_collection_share_dialog" :learning_progression="collection" :course_code="course_code" @cancel_share="close_share_dialog"></CollectionManageSharing>
		<PDResourceCollection v-if="pd_resources_showing" :lp="collection" @hide="pd_resources_showing=false" />
		<TodoReportCollection v-if="todo_report_showing" :collection="collection" @dialog_cancel="todo_report_showing=false" />
		<SchoolAdminInterface v-if="school_admin_interface_showing" :collection="collection" @dialog_cancel="school_admin_interface_showing=false" />
	</div></div>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionUnitsVertical from './CollectionUnitsVertical'
import CollectionUnitsHorizontal from './CollectionUnitsHorizontal'
import CollectionEdit from './CollectionEdit'
import CollectionManageSharing from './CollectionManageSharing.vue'
import PDResourceCollection from '../resources/PDResourceCollection'
import TodoReportCollection from '../reports/TodoReportCollection.vue'
import SchoolAdminInterface from '../reports/SchoolAdminInterface.vue'

export default {
	components: { CollectionUnitsVertical, CollectionUnitsHorizontal, CollectionEdit, CollectionManageSharing, PDResourceCollection, TodoReportCollection, SchoolAdminInterface },
	props: {
		course_code: { type: String, required: true },
		unit_id: { type: String, required: true },
		collections_list: { type: Array, required: false, default() { return [] }},
		show_collection_only: { type: Boolean, required: false, default() { return false }},
		custom_class: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		initialized: 0,
		collection_min_description_height: 280,
		unit_showing_val: null,
		unit_min_description_height: 280,
		unit_description_style: '',
		show_collection_editor: false,
		show_collection_share_dialog: false,
		show_unit_editor: false,
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results_items: [],
		search_results_units: null,
		unit_descriptions_collapsed: false,
		pd_resources_showing: false,
		school_admin_interface_showing: false,
		todo_report_showing: false,
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'lp_edit_locked', 'collection_search_data', 'my_lessons', 'my_resources', 'my_ca_mappings', 'site_config']),
		...mapGetters(['signed_in', 'role', 'studentish_role', 'user_is_principal_or_ap']),
		show_collection_menu() {
			if (this.studentish_role) return false
			return true
		},
		show_school_admin_btn() {
			// config option determines if this is ever available
			if (this.site_config.show_school_leader_tools == 'no') return false
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
			if (this.is_default_collection) return false
			if (this.user_is_principal_or_ap) return true
			if (this.role == 'admin') return true
		},
		show_course_standards_btn() {
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
 			return !empty(this.course_case_identifier)
		},
		collection_id() {
			// for HenryConnects, the course_code is used as the collection_id
			return this.course_code
		},
		user_is_superuser() {
			return vapp.has_admin_right('su')
		},
		is_collection_admin() {
			if(this.collection_is_empty) return false
			if (this.initialized <= 0) return false

			if (this.is_default_collection) return true

			return this.collection.user_is_lp_admin()
		},
		user_subscribed_to_collection() {
			if (this.collection_is_empty || !this.collection.user_is_subscribed_to_lp) return false
			return this.collection.user_is_subscribed_to_lp()
		},
		course_or_repo() { return ['course','repo'].includes(this.collection.collection_type) },
		collection_is_shareable() {
			if (!this.signed_in) return false
			if (this.is_default_collection) return false
			// for 'my' collections, admins of the collection can share it
			if (this.collection.collection_type == 'my') {
				return this.collection.user_is_lp_admin()
			}
			// for courses and repos, you can share your "my content"
			if (this.course_or_repo) {
				return true
			}
			return false
		},
		collection() {
			if (this.initialized <= 0) return {}

			// we always pull from all_courses here, not the user's individual courses
			let l = this.all_courses.find(o=>o.course_code == this.course_code)
			if (empty(l)) return {}
			return l
		},
		collection_icon() {
			if (this.collection.collection_type == 'course') return 'fas fa-chalkboard'
			else if (this.collection.collection_type == 'repo') return 'fas fa-diagram-project'
			else if (this.collection.collection_type == 'my') return 'fas fa-cubes-stacked'
			else if (this.collection.collection_type == 'pd') return 'fas fa-user-graduate'
			else return ''
		},
		collection_is_empty() {
			return Object.keys(this.collection).length === 0
		},
		is_default_collection() { 
			if (this.initialized <= 0) return false
			return (this.collection.course_code == 'default') 
		},
		page_title() {
			return sr('<b>$1</b>', this.collection.title)
		},
		collection_icon_css_class() {
			return U.subject_tile_css(this.collection) + '-text'
		},
		top_css_class() {
			let s = ''
			if (!this.show_collection_only) {
				s = 'k-collections-view-wrapper k-page-wrapper '
				s += U.subject_tile_css(this.collection) + '-border'
			}
			if (this.unit_showing) s += ' k-collections-view-wrapper--unit-showing'

			// add custom_class (blank by default)
			s += ' ' + this.custom_class

			if (this.collection.shifted_for_lesson) s += ' k-collections-view-wrapper-shifted-for-lesson'

			return s
		},
		unit_showing() {
			if (empty(this.collection.units)) return

			// if we only have one unit, always show it
			if (this.collection.units.length == 1) {
				this.unit_showing_val = this.collection.units[0]
				return this.unit_showing_val
			}

			// unit_id either comes from the url via vuerouter, in which case the router will update it when necessary,
			// or it's sent in by the including component, in which case it'll be sent in as 0 and we'll manipulate unit_showing_val directly below

			// if unit_id is something other than 0, get the unit to show;
			// otherwise use the last unit showing (which could be null)
			if (this.unit_id != 0) {
				let u = this.collection.units.find(x=>x.lp_unit_id == this.unit_id)
				if (empty(u)) return null
				this.unit_showing_val = u
			}
			return this.unit_showing_val
		},
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		case_framework_identifier() {
			if (this.collection.subject_case_identifier == 'none') return ''
			if (this.collection.subject_case_identifier) return this.collection.subject_case_identifier
			return this.$store.state.subjects[this.collection.subject]?.framework_identifier	// may return null
		},
		sis_course() { 
			// note that this returns the first-listed sis_classes record for this course_code. If this is a teacher that teaches both block and quarter classes, they may have two sis_classes records, but that's OK for our purposes here.
			return this.$store.state.sis_classes.find(x=>x.course_code==this.collection.course_code) 
		},
		is_sis_course() {
			return !empty(this.sis_course)
		},
		in_added_my_courses() {
			if (this.$store.state.added_my_courses.find(x=>x==this.collection.course_code)) return true
			return false
		},
		in_removed_my_courses() {
			if (this.$store.state.removed_my_courses.find(x=>x==this.collection.course_code)) return true
			return false
		},
		course_case_identifier() {
			return this.collection.course_case_identifier
		},
		course_addable() {
			return (this.in_removed_my_courses || (!this.is_sis_course && !this.in_added_my_courses))
		},
		course_removable() {
			return ((this.in_added_my_courses || this.is_sis_course) && !this.in_removed_my_courses)
		},
		collection_description_style() {
			if (!empty(this.collection.description)) {
				// initialize full_description_height if necessary
				if (this.collection.full_description_height == -1) {
					setTimeout(x=>{
						this.collection.full_description_height = $('.k-collections-collection-description-inner').height()
						// console.log('set collection_description_style ', this.collection.full_description_showing, this.collection.full_description_height, $('.k-collections-collection-description-inner').length)
					}, 0)

					// if we haven't yet calculated the height, set to visibility:hidden so that the timeout above will run and set the height; the computed will then re-run since full_description_height has been updated
					return 'visibility:hidden'

				} else {
					// we have full_description_height, so determine whether or not the description should be showing
					if (!this.collection.full_description_showing && this.collection.full_description_height > this.collection_min_description_height) {
						return `max-height:${this.collection_min_description_height}px; overflow:hidden;`
					} else {
						return ''
					}
				}
			}
		},
		collection_show_full_description_btn() {
			// see code in initialize
			return (this.collection.full_description_height > this.collection_min_description_height)
		},
		unit_show_full_description_btn() {
			// see unit_showing watcher below
			if (!this.unit_showing) return false
			return (this.unit_showing.full_description_height > this.unit_min_description_height)
		},
		user_can_archive_collection() {
			// Assumptions: User can not delete default collection...
			if (this.is_default_collection) return false
			// ...User can only delete 'my' collection type...
			if (this.collection.collection_type !== 'my') return false
			// ...Only the collection *owner* can delete (not -- at least currently -- the collection admin or a superuser)...
			// if (this.user_is_superuser) return true
			// if (this.is_collection_admin) return true
			if (this.collection.owner_id == this.user_info.user_id) return true
			// ...otherwise...
			return false
		},
		collection_has_units() {
			return this.collection.units?.length > 0
		},
		collection_descriptions_collapsed: {
			get() { return this.$store.state.lst.collection_descriptions_collapsed },
			set(val) { this.$store.commit('lst_set', ['collection_descriptions_collapsed', val]) }
		},
		can_view_report() {
			// the "resource completion report" is only available for pd collections
			if (this.collection.collection_type != 'pd') return false
			return vapp.has_admin_right('pd_rep.any')
		},
		show_pd_resources_btn() {
			// if we're not showing the PD tab, don't show this btn
			if (this.site_config.main_tabs_to_include != 'all' && !this.site_config.main_tabs_to_include.includes('pd')) return false
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
			if (!this.collection.collection_type=='course') return false
			if (!this.signed_in || this.studentish_role) return false
			if (!this.collection.pd_resources || this.collection.pd_resources.length == 0) return false
			return true
		},
		teacher_display() {
			if (!this.studentish_role) return ''
			if (!this.is_sis_course) return ''

			// ported from HenryConnects; may need to be updated to generalize...
			let s = ''
			if (this.sis_course.teachers && this.sis_course.teachers.length > 0) {
				// note teachers[0][0] format, which is needed because of the complex course structure in IC
				let teacher = this.sis_course.teachers[0]
				// console.log(teacher)
				s = teacher.givenName + ' ' + (teacher.middleName ? (teacher.middleName + ' ') : '') + teacher.familyName

				s += sr(' <a style="font-size:14px; font-weight:bold; margin-left:6px;" href="mailto:$1">$1</a>', teacher.email.toLowerCase())
			}

			if (s) return `<b>Teacher:</b> ${s}`
			return ''
		},
	},
	watch: {
		unit_showing: { deep:true, immediate:true, handler() {
			// we have to use this watcher rigamarole instead of the simpler computed we use above for the collection description, because the unit changes depending on what the user chooses
			if (!this.unit_showing || !this.unit_showing.text) return
			if (this.unit_showing.full_description_height == -1) {
				this.unit_description_style = 'visibility:hidden'
			}

			this.unit_descriptions_collapsed = false
			this.$nextTick(x=>{
				if (this.unit_showing.full_description_height == -1) {
					this.unit_showing.full_description_height = $('.k-collection-unit-description-inner').height()
				}

				// console.log('unit_showing updated; set unit_description_style ', this.unit_showing.full_description_showing, this.unit_showing.full_description_height, $('.k-collection-unit-description-inner').length)
				if (!this.unit_showing.full_description_showing && this.unit_showing.full_description_height > this.unit_min_description_height) {
					this.unit_description_style = `max-height:${this.unit_min_description_height}px; overflow:hidden;`
				} else {
					this.unit_description_style = ''
				}
				this.unit_descriptions_collapsed = this.$store.state.lst.unit_descriptions_collapsed
			})
		}},
		'$store.state.lst.unit_descriptions_collapsed'() { this.unit_descriptions_collapsed = this.$store.state.lst.unit_descriptions_collapsed },
		search_terms() {
			if (empty(this.search_terms)) {
				this.execute_search_clear()
			}
		},
		'$route.fullPath': {
			handler() {
				// restore search data when we open/return to a collection
				this.restore_search_data()

				// add collection to last_collections_viewed, removing any at the end of the list -- but not if it's already in last_collections_viewed
				if (this.$route.fullPath.includes(`collection/${this.course_code}`)) {
					// if (!this.collections_list.find(x=>x.course_code == this.course_code)) {
					if (!this.last_collections_viewed.find(x=>x == this.course_code)) {
						// console.log('add to top bar: ' + this.course_code)
						let arr = this.last_collections_viewed.concat([])
						let index = arr.indexOf(this.course_code)
						if (index > -1) arr.splice(index, 1)
						arr.unshift(this.course_code)
						arr.splice(this.$store.state.n_collections_to_show_in_banner, 1000)
						this.last_collections_viewed = arr
					}
				}

				// whenever the url is updated, cancel any lesson shift that has happened
				this.lesson_shift_update({cancel_last: true})
			}, deep:true, immediate:true
		},
	},
	created() {
		vapp.collection_component = this
	},
	mounted() {
		// unsigned-in users and studentish users can't visit pd or my collections; check here (for the case where we already know something about this collection), then get_learning_progression service will check again; then check again if get_learning_progression loads
		if ((!this.signed_in || this.studentish_role) && (['pd','my'].includes(this.collection.collection_type))) {
			vapp.go_to_home('home')
		} else {
			// console.log('mounting collection ' + this.collection_id)
			this.initialize()
		}
	},
	methods: {
		initialize() {
			// if we don't already have the full collection data for this course...
			if (empty(this.collection.lp_id)) {
				// if title is empty and the user is an admin, we must be creating it now, so go right to the editor
				if (this.collection.title == '' && this.is_collection_admin) {
					++this.initialized
					this.edit_collection()
					return
				}

				// try to load it now
				this.$store.dispatch('get_learning_progression', this.course_code).then((found)=>{
					if (found == 'not_authenticated' || found == 'no_admin_rights') {
						vapp.go_to_home('home')
						return
					}

					if (!found) {
						this.$alert('This collection id was not found.')
						return
					}

					// have to check for role again in case the user is simulating another user type
					if (!this.studentish_role && (['pd','my'].includes(this.collection.collection_type))) {
						vapp.go_to_home('home')
						return
					}

					this.$nextTick(()=>{
						this.initialize_finish()
					})

				}).catch((e)=>{
					console.log(e)
					this.$alert('error in get_collection?')
				})

			} else {
				this.initialize_finish()
			}
			this.restore_search_data()
		},

		initialize_finish() {
			// this.collection might not have been computed in this component, so get lp directly from the store
			let lp = this.all_courses.find(o=>o.course_code == this.course_code)

			// if this LP originally used the "map" view that had course_wide_resources and/or assessment_* resources separate, add a unit for those, with folders
			let cw_unit_title = ''
			let cw_unit = new LP_Unit({
				lp_unit_id: 9999999,
			})
			if (lp.course_wide_resources.length > 0) {
				cw_unit_title += 'Course-Wide'
				cw_unit.create_resource_folder('top', 'Course-Wide Resources', lp.course_wide_resources, 'cyan')
				cw_unit.resources = cw_unit.resources.concat(lp.course_wide_resources)
				this.$store.commit('set', [lp, 'course_wide_resources', []])
			}
			if (lp.assessment_resources_general.length > 0 || lp.assessment_resources_annotated_examples.length > 0 || lp.assessment_resources_sample_items.length > 0) {
				if (cw_unit_title) cw_unit_title += ' and Assessment'
				else cw_unit_title += 'Assessment'

				if (lp.assessment_resources_general.length) cw_unit.create_resource_folder('top', 'Assessment Guides', lp.assessment_resources_general, 'pink') 
				if (lp.assessment_resources_annotated_examples.length) cw_unit.create_resource_folder('top', 'Annotated Example Assessment Items', lp.assessment_resources_annotated_examples, 'pink') 
				if (lp.assessment_resources_sample_items.length) cw_unit.create_resource_folder('top', 'Sample Assessment Items', lp.assessment_resources_sample_items, 'pink') 

				cw_unit.resources = cw_unit.resources.concat(lp.assessment_resources_general)
				cw_unit.resources = cw_unit.resources.concat(lp.assessment_resources_annotated_examples)
				cw_unit.resources = cw_unit.resources.concat(lp.assessment_resources_sample_items)

				this.$store.commit('set', [lp, 'assessment_resources_general', []])
				this.$store.commit('set', [lp, 'assessment_resources_annotated_examples', []])
				this.$store.commit('set', [lp, 'assessment_resources_sample_items', []])
			}

			if (cw_unit_title) {
				cw_unit.title = `${cw_unit_title} Resources`
				this.$store.commit('set', [lp.units, 'UNSHIFT', cw_unit])
			}

			++this.initialized
		},

		matches_search(s) {
			if (!U.string_includes_stop_word(this.stop_words, s)) {
				if (U.strings_match_search_term_res(this.search_term_res, [s])) {
					return true
				}
			}
			return false
		},

		execute_search_start() {
			// establish the debounce fn if necessary
			if (empty(this.fn_debounced)) {
				this.fn_debounced = U.debounce(() => {
					this.execute_search_clear()
					if (empty(this.search_terms)) return

					this.search_results_units = []

					let arr = U.create_search_re(this.search_terms)
					this.search_term_res = arr[0]
					this.stop_words = arr[1]

					for (let u of this.collection.units) {
						let unit_included = false
						// if one of the unit titles matches, always show the unit
						if (this.matches_search(u.title)) { this.search_results_units.push(u); unit_included = true; }

						for (let item of u.resources) {
							// skip items the user doesn't have access to
							if (!item.is_visible_by_user()) continue
							
							if (this.matches_search(item.description)) {
								if (!unit_included) { this.search_results_units.push(u); unit_included = true; }
								this.search_results_items.push({type:'resource', item_id:item.resource_id})
							}
							// also check for a copy of the unit item made by the user
							let copy = this.my_resources.find(x=>x.resource_template_id == item.resource_id)
							if (copy && this.matches_search(copy.description)) {
								if (!unit_included) { this.search_results_units.push(u); unit_included = true; }
								this.search_results_items.push({type:'resource', item_id:item.resource_id})
							}
						}

						for (let item of u.lessons) {
							// skip items the user doesn't have access to
							if (!item.is_visible_by_user()) continue
							
							if (this.matches_search(item.lesson_title)) {
								if (!unit_included) { this.search_results_units.push(u); unit_included = true; }
								this.search_results_items.push({type:'lesson', item_id:item.lesson_id})
							}
							// also check for a copy of the unit item made by the user
							let copy = this.my_lessons.find(x=>x.lesson_template_id == item.lesson_id)
							if (copy && this.matches_search(copy.lesson_title)) {
								if (!unit_included) { this.search_results_units.push(u); unit_included = true; }
								this.search_results_items.push({type:'lesson', item_id:copy.lesson_id})
							}
						}

						// check items created by the user and expressly tagged to the unit: go through all mappings...
						for (let ca_mapping of this.my_ca_mappings) {
							// if the mapping goes with this collection/unit
							if (ca_mapping.collection_id == this.collection.lp_id && ca_mapping.unit_id == u.lp_unit_id) {
								if (ca_mapping.asset_type == 'lesson') {
									// find the item in my_xxx
									let item = this.my_lessons.find(x=>x.lesson_id == ca_mapping.asset_id)
									// if we found it, its title matches the search terms, and we haven't already added it, add it to search_results_items
									if (item && this.matches_search(item.lesson_title) && !this.search_results_items.find(x=>x.lesson_id == item.lesson_id)) {
										if (!unit_included) { this.search_results_units.push(u); unit_included = true; }
										this.search_results_items.push({type:'lesson', item_id:item.lesson_id})
									}
								} else {
									let item = this.my_resources.find(x=>x.resource_id == ca_mapping.asset_id)
									if (item && this.matches_search(item.description) && !this.search_results_items.find(x=>x.resource_id == item.resource_id)) {
										if (!unit_included) { this.search_results_units.push(u); unit_included = true; }
										this.search_results_items.push({type:'resource', item_id:item.resource_id})
									}
								}
							}
						}
					}

					this.save_search_data()
				}, 500)
			}

			// call the debounce fn
			this.fn_debounced()
		},

		execute_search_clear() {
			this.search_results_units = null
			this.search_results_items = []
			this.search_term_res = []
			this.stop_words = []
			this.save_search_data()
		},

		clear_collection_last_search_results() {
			this.search_terms = ''
			this.$store.commit('set', ['collection_last_search_results', []])
		},

		search_field_keyup(evt) {
			this.execute_search_start()
		},

		save_search_data() {
			// save search data in store so that if we switch to a different unit we'll restore
			let o = {
				search_terms: this.search_terms,
				search_results_units: this.search_results_units,
				search_results_items: this.search_results_items,
				search_term_res: this.search_term_res,
				stop_words: this.stop_words,
			}
			// also save last search results, which we use to show an icon next to last-searched-for items
			if (this.search_results_items.length > 0) {
				let val = this.search_results_items.length > 0 ? this.search_results_items : null
				this.$store.commit('set', ['collection_last_search_results', val])
			}
			
			this.$store.commit('set', [this.collection_search_data, this.collection_id, o])
		},

		restore_search_data() {
			if (this.collection_search_data[this.collection_id]) {
				this.search_terms = this.collection_search_data[this.collection_id].search_terms
				this.search_results_units = this.collection_search_data[this.collection_id].search_results_units
				this.search_results_items = this.collection_search_data[this.collection_id].search_results_items
				this.search_term_res = this.collection_search_data[this.collection_id].search_term_res
				this.stop_words = this.collection_search_data[this.collection_id].stop_words
			}
		},

		hide_unit() {
			if (!empty(this.unit_showing)) {
				// if we're only showing the collection, we're not using the router, so just set unit_showing_val to null
				if (this.show_collection_only) {
					this.unit_showing_val = null

				} else {
					// otherwise use the router; when we hide the unit, we have to set unit_showing_val to null, but after a tick to let unit_id get to 0
					this.$nextTick(()=>this.unit_showing_val = null)
					let base = window.location.pathname.replace(/^.(\w+).*/, '$1')	// repo or course
					this.$router.push({ path: `/${base}/${this.collection_id}/0` })
				}
			}
		},

		show_unit(unit, flag) {
			if (empty(this.unit_showing) || unit != this.unit_showing) {
				// if we're only showing the collection, we're not using the router, so just set unit_showing_val to the unit
				if (this.show_collection_only) {
					this.unit_showing_val = unit
				} else {
					let base = window.location.pathname.replace(/^.(\w+).*/, '$1')	// repo or course
					this.$router.push({ path: `/${base}/${this.collection_id}/${unit.lp_unit_id}` })
				}
			}
			// if the user clicked on the "reveal search results" icon, show folders in the unit, so that the searched items will be visible
			if (flag == 'search') {
				setTimeout(x=>this.$refs.collection_units.$refs.collection_unit.open_all_folders())
			}
		},

		toggle_unit(unit) {
			if (!empty(this.unit_showing) && unit == this.unit_showing) this.hide_unit()
			else this.show_unit(unit)
		},

		add_unit() {
			this.edit_collection()
			this.$inform({text:'Add a new unit using the lavender “Units” section at the bottom of the <nobr>Content Collection Editor.</nobr>', color:'purple darken-3', snackbarY:'top', snackbarTimeout:-1})
		},

		edit_collection() {
			// if the lp is already saved (i.e. it isn't brand new), we have to request to edit it, to make sure no one else already has it opened for editing
			if (!empty(this.collection.lp_id) && this.collection.lp_id != 0) {
				this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
					console.log('edit request: ' + result.status)
					// flag that we've requested to edit the LP; once the edit lock is aquired show lp_editor
					// we have to delay this a bit in case the unit has to first be hidden
					this.show_collection_editor = true
				}).catch((e)=>{
					// if this doesn't work, don't enter edit mode
					console.log('error requesting editor checkout for collection', e)
				})
			} else {
				// in this case it is a brand new lp, so no edit lock to create
				this.show_collection_editor = true
			}
		},

		cancel_collection_editing() {
			// when editing is canceled (including after a save), clear the edit lock
			// note that edit_access_control_checkin is called separately when editing a unit (CourseUnit) 
			this.$store.dispatch('edit_access_control_checkin', {lp_id: this.collection.lp_id})
			this.show_collection_editor = false
		},

		remove_edit_lock() {
			this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, action: 'checkin'}).then((result)=>{
				if (result.status == 'ok') {
					this.$inform('Edit lock removed')
				} else {
					this.$alert('A problem occurred when attempting to remove the edit lock: ' + result.status)
				}
			}).catch((e)=>{
				console.log(e)
				this.$alert('A problem occurred when attempting to remove the edit lock.')
			})
		},

		show_course_standards() {
			if (!this.course_case_identifier) return

			let data = {framework_identifier:this.case_framework_identifier}
			// if we have a course_case_identifier...
			if (this.course_case_identifier) {
				// have that item open and selected, and (initially) limit to showing just that item and its descendents
				data.item_identifier = this.course_case_identifier
				data.selected_items = [this.course_case_identifier]
				data.limit_to_selected_items = 'children'
				data.no_framework_reset = true
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})

		},

		add_to_my_courses() {
			this.$store.dispatch('update_my_courses', {course_code:this.course_code, action:'add'}).then(()=>{
				this.$alert(sr('“$1” has been added to your Favorite Collections. If you wish to remove the course in the future, click the “three-dot” button in the upper-right corner of the interface.', this.collection.title))
			})
		},

		remove_from_my_courses() {
			this.$store.dispatch('update_my_courses', {course_code:this.course_code, action:'remove'})
		},

		archive_collection() {
			this.$confirm({
				text: 'Are you sure you want to remove this collection?',
				acceptText: 'Remove Collection',
			}).then(y => {
				this.$store.dispatch('archive_learning_progression', { course_code: this.course_code }).then((result) => {
					if (result.status == 'ok') {
						this.$router.push('/mycollections');
					} else {
						this.$alert('A problem occurred when attempting to remove the collection: ' + result.status)
					}
				}).catch((e) => {
					console.log(e)
					this.$alert('A problem occurred when attempting to remove the collection.')
				})
			}).catch(n=>{}).finally(f=>{});
		},

		remove_collection_subscription() {
			this.$confirm({
				text: 'Are you sure you want to unsubscribe from this collection?',
				acceptText: 'Unsubscribe',
			}).then(y => {
				this.$store.dispatch('unsubscribe_from_shared_collection', this.collection.course_code).then((result) => {
					this.$inform('You have been unsubscribed.')
					this.$router.push('/mycollections');
				})
			}).catch(n=>{}).finally(f=>{});
		},

		open_share_dialog() {
			this.show_collection_share_dialog = true
		},

		close_share_dialog() {
			this.show_collection_share_dialog = false
		},

		export_common_cartridge() {
			this.$alert('<b>Coming Soon:</b> This will allow you to choose resources from this repository to be exported as a Thin Common Cartridge, which can then be imported into any learning object repository (LOR) or learning management system (LMS) that supports the 1EdTech Common Cartridge standard.')
		},

		show_my_content() {
			this.$alert('This will show a list of course content the user has adapted for their own use.')
		},

		create_new_mycollection() {
			if (vapp.my_collections_index_component) {
				vapp.my_collections_index_component.create_new_collection()
			} else {
				// if we're here, it means that the user opened their window right to the default collection, then clicked to create a new collection; set state.create_my_collection to true, then open the MyCollectionsIndex path
				this.$store.commit('set', ['create_my_collection', true])
				this.$router.push({ path: '/mycollections' })
			}
		},

		// when viewing a collection, we allow for lessons to be "shifted" over to the right so that the user can view other resources in a unit. this fn shifts the collection over to the left when this happens
		lesson_shift_update(params) {
			// if params.cancel_last is true, we're clearing things out from a previous shift (e.g. when the user shifts to another unit)
			if (params.cancel_last == true) {
				if (this.collection.shifted_for_lesson && this.collection.shifted_for_lesson.close_lesson) {
					this.collection.shifted_for_lesson.close_lesson()
				}
				this.$store.commit('set', [this.collection, 'shifted_for_lesson', false])
			} else {
				// else store params.shifted_for_lesson in this.collection; this can be false if no lesson is shifted, or a reference to the lesson's ResourceCollectionItem if it *is* shifted
				this.$store.commit('set', [this.collection, 'shifted_for_lesson', params.shifted_for_lesson])
			}
		},
	}
}


</script>

<style lang="scss">
.k-collections-view-wrapper {
	margin-top:20px!important;
	border-top:5px solid transparent;
	border-bottom:5px solid transparent;

	.k-page-title {
		margin-bottom:0;
	}

	.k-safari_btn {
		height:36px;
		display:inline-block;
	    cursor: pointer;
	    background-color: #fff;
	    border-radius: 10px;
	    padding: 3px 16px;
	    vertical-align: middle;
		img {
			height: 30px;
		}
	}
}

.k-collections-view-wrapper-shifted-for-lesson {
	width: 50vw;
    margin-left: 0!important;
    padding-left: 8px!important;
    padding-right: 8px!important;
}

.k-collections-view-wrapper-inner {
	max-width: 1400px;
	margin:0 auto;

	.k-mycontent-view-header {
		// display:none;
		border:0;
		margin:0;
		padding:0 0 0 4px;
		font-size:18px;
		font-weight:bold;
		color:#666;
	}
}

.k-collections-collection-description {
	border:1px solid #ccc;
	background-color:#f2f2f2;
	color:#000;
	// max-width:800px;
	margin:20px auto 0 auto;
	// padding:4px 16px;
	border-radius:10px;
	font-size:16px;
	max-width:800px;

	.fr-view table td, .fr-view table th { 
		border:0;
		padding:4px;
	}
}

.k-resource-search-results {
	background-color:$v-yellow-lighten-5;
	padding:4px 8px;
	font-size:14px;
	margin:0 20px;
	border-radius:0 0 6px 6px;
}

.k-resource-search-results-no-results {
	background-color:$v-red-lighten-5;
}

.k-course-view-teacher_display {
	font-size:16px;
	line-height:19px;
	text-align:center;
	margin-top:8px;
}

</style>
