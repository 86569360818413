<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-wrapper">
	<div class="k-main-wrapper-bgd"></div>
	<v-app-bar app dense :hide-on-scroll="small_screen" class="k-app-toolbar" :class="current_sparkl_embed_component?'k-app-toolbar-sparkl-showing':'k-app-toolbar-sparkl-not-showing'">
		<img @click="logo_clicked" class="k-toolbar__logo-img" :src="agency_logo_img_src">
		<span @click="logo_clicked" class="k-tassle-logo-text" v-html="site_config.banner_logo_text" translate="no"></span>

		<v-spacer/>

		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-if="!current_sparkl_embed_component&&alt_role_label" color="pink accent-4" class="mr-3 k-tight-btn k-nocaps-btn" dark @click="return_to_default_role"><v-icon small class="mr-2">fas fa-glasses</v-icon>{{alt_role_label}} <v-icon small class="ml-2">fas fa-arrow-rotate-left</v-icon></v-btn></template>Click to return to default role</v-tooltip>

		<!-- Sparkl btns -->
		<div v-show="current_sparkl_embed_component && viewing_original_of_in_my_collections" style="font-size:12px" class="mr-2 green--text text--lighten-1"><v-icon color="green lighten-1" small class="mr-1">fas fa-cubes-stacked</v-icon> <i>You are viewing the original version of an activity you copied for your use</i></div>
		<v-btn v-show="current_sparkl_embed_component && !viewing_original_of_in_my_collections&&show_copy_for_my_use_btn" class="k-tight-btn" color="green darken-2" dark @click.stop="copy_to_my_content($event)"><v-icon small class="mr-2">fas fa-cubes-stacked</v-icon>{{copy_to_my_content_btn_text}}</v-btn>
		<v-btn v-show="current_sparkl_embed_component" class="ml-4 k-tight-btn" color="amber" @click.stop="sparkl_close_btn_clicked"><v-icon small class="mr-2">fas fa-times-circle</v-icon>Close Activity</v-btn>
		<!-- end of sparkl btns -->

		<div v-show="!current_sparkl_embed_component" v-if="!small_screen" class="k-toolbar__username" translate="no">{{user_info.first_name}} {{user_info.last_name}}</div>

		<UserMenu v-show="!current_sparkl_embed_component" v-if="signed_in" />
		<v-btn v-show="!current_sparkl_embed_component" v-if="!signed_in&&site_config.show_banner_create_account_btn=='yes'" color="#666" class="mr-3 k-tight-btn" dark @click="create_account"><v-icon small class="mr-2">fas fa-user-plus</v-icon>Create Account</v-btn>
		<v-btn v-show="!current_sparkl_embed_component" v-if="!signed_in&&site_config.show_banner_signin_btn=='yes'" :color="signin_btn_color" @click="sign_in">  Sign In<v-icon small class="mx-2">fas fa-sign-in</v-icon></v-btn>
	</v-app-bar>

	<keep-alive><router-view :key="router_key"></router-view></keep-alive>

	<div class="k-copyright" @click="show_change_log">{{ site_config.copyright_text }} &nbsp; (v{{$store.state.app_version}})</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import UserMenu from './UserMenu'

export default {
	components: { UserMenu },
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'site_config', 'current_sparkl_embed_component']),
		...mapGetters(['small_screen', 'signed_in']),
		role() { return this.user_info.role },
		signin_btn_color() { return this.site_config.banner_signin_btn_color },
		simulated_role: {
			get() { return this.$store.state.lst.simulated_role },
			set(val) { this.$store.commit('lst_set', ['simulated_role', val]) }
		},
		simulated_user: {
			get() { return this.$store.state.lst.simulated_user },
			set(val) { this.$store.commit('lst_set', ['simulated_user', val]) }
		},
		alt_role_label() {
			if (this.simulated_role) {
				if (this.role == 'student') return 'STUDENT View'
				if (this.role == 'parent') return 'GUARDIAN View'
				if (this.role == 'staff') return 'STAFF View'
			} else if (this.simulated_user) {
				return 'MIMICKING ' + this.simulated_user
			}
		},
		main_mode() {
			let path = this.$route.fullPath
			return path.replace(/^\/(\w+).*/, '$1')
		},
		agency_logo_img_src() {
			if (!this.site_config.agency_logo) return ''
			return this.site_config.agency_logo
		},
		router_key() {
			let path = this.$route.fullPath
			// by using these keys, we preserve the right amount of "history" in our components...
			if (vapp.$route.name == 'collection_view') {
				return path.replace(/(\/collection\/.+?)(\/|$).*/, '$1')
			// if (vapp.$route.name == 'course_view') {
			// 	return path.replace(/(\/course\/.+?)(\/|$).*/, '$1')
			// } else if (vapp.$route.name == 'repo_course_view' || vapp.$route.name == 'repo_course_view2') {
			// 	return path.replace(/(\/repo\/.+?)(\/|$).*/, '$1')
			// } else if (vapp.$route.name == 'mycollection_course_view') {
			// 	return path.replace(/(\/mycollection\/.+?)(\/|$).*/, '$1')
			} else {
				// we only need to render the 'WelcomeView' component once; all these routes use it
				if (['welcome', 'signin', 'welcome_alt', 'home', 'courses', 'repos', 'mycollections', 'admin', 'standards'].includes(vapp.$route.name)) {
					return 'welcome'
				} else {
					return path
				}
			}
		},

		// from SparklEmbed
		viewing_original_of_in_my_collections() { return this.current_sparkl_embed_component?.viewing_original_of_in_my_collections },
		show_copy_for_my_use_btn() { return this.current_sparkl_embed_component?.show_copy_for_my_use_btn },
		copy_to_my_content_btn_text() { return this.current_sparkl_embed_component?.copy_to_my_content_btn_text },
	},
	created() {
		window.main_view = this
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		// from SparklEmbed
		copy_to_my_content($event) { this.current_sparkl_embed_component.copy_to_my_content($event) },
		use_with_students() { this.current_sparkl_embed_component.use_with_students() },
		sparkl_close_btn_clicked() { this.current_sparkl_embed_component.close_btn_clicked() },

		logo_clicked() {
			// if a sparkl activity is showing, close it
			if (vapp.$refs.sparkl_embed.sparkl_showing) {
				vapp.$refs.sparkl_embed.hide_activity()
			} else {
				// else go to the default route
				this.go_to_route('')
			}
		},

		return_to_default_role() {
			// if we're in "sandbox" mode, call simulate_sandbox_role
			if (this.site_config.enable_simulate_role_menu == 'yes') {
				vapp.simulate_sandbox_role('')

			} else {
				// otherwise just reset simulated role/user and reload
				this.simulated_role = ''
				this.simulated_user = ''
				window.location.reload()
			}
		},

		go_to_route(new_route, event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			new_route = '/' + new_route
			if (this.$route.path == new_route) return

			this.$router.push({ path: new_route })
		},

		sign_out() {
			// for demo purposes we sign out by clearing password from local_storage and reloading
			U.local_storage_set('district_portal_login_password', '')
			window.location.reload()

			// this.$store.dispatch('sign_out')
		},

		show_change_log() {
			this.go_to_route('changelog')
		},

		cycle_bgd_image() {
			vapp.show_bgd_image(-1)
		},

		sign_in() {
			vapp.redirect_to_login()
		},

		create_account() {
			vapp.redirect_to_login('create_account')
		},
	}
}
</script>

<style lang="scss">
// .k-case-tree-outer-wrapper--maximized .k-case-tree-top-inner .k-case-tree-title {
// 	padding-left:132px;
// }

.k-main-wrapper {
	.k-main-wrapper-bgd {
		position:fixed;
		z-index:-1;
		left:0;
		top:0;
		width:100vw;
		height:100vh;
		background:rgba(0, 0, 0, 0.65)!important;
	}

	margin-bottom:40px;
	// margin-right:15px;

	.k-elevated {
		-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
	}

	.k-app-toolbar.k-app-toolbar-sparkl-showing { 
		// background-color:#222!important;
		// .k-toolbar__logo-img { background-color:#222!important; }
	}

	.k-app-toolbar {
		// height is 60; set in v-app-bar component; matches sparklsalt
		z-index:11;
		background-color:$v-doe-light-orange!important;
		// background-color:rgba(244, 221, 203, .9)!important;

		.k-tassle-logo-text {
			color:$v-doe-hot-pink;
			background: -webkit-linear-gradient($v-doe-orange, $v-doe-hot-pink);
		    -webkit-background-clip: text;
		    -webkit-text-fill-color: transparent;

			font-weight:bold;
			font-size:32px;
			cursor:pointer;
			padding-top:0px;
			position:absolute;
			left:120px;
			top:-3px;
			font-family: var(--agency-name-font-family);
		}

		.k-toolbar__logo-img {
			height:64px;
			cursor:pointer;
			position:absolute;
			top:4px;
			left:0;
			background-color:$v-doe-light-orange;
			padding:4px 16px 8px 12px;
			border-radius:0 0 10px 0;

			-webkit-box-shadow: 0px 6px 5px -6px black;
			box-shadow: 0px 6px 5px -6px black;
		}

		.k-toolbar__username {
			margin-right:10px;
			font-family:$display-font;
			color:#222;
			font-weight:900;
			font-size:1.4em;
			line-height:1.0em;
			text-align:center;
		}
	}

	.k-main-division-wrapper {
		// margin-left:140px;
		margin-top:50px;
	}

	.k-page-wrapper {
		background-color:$page-background;
		padding:15px 80px;
		// max-width:1200px;
		margin:30px auto 0 auto;
		// border-radius: 8px;
	}

	.k-page-title {
		font-size:27px;
		margin-bottom:20px;
	}
	.k-page-title-btn {
		width:70px;
		height:70px;
		background-color:$secondary-color;
		color:$primary-color;
		border-radius:50%;
		margin-right:10px;
		font-size:40px;
		float:left;
	}

	.k-main-collection {
		// border-radius:8px;
		padding:15px 50px;
		// max-width:1000px;
		// margin:0 auto;
		margin:0;
		width:100vw;
		background-color:$page-background;
		// border-top:5px solid #fff;
	}

	.k-main-collection--item-showing {
		border-radius:0 16px 16px 0;
		width:auto;
		height:160px;
	}

	// .k-main-collection-header {
	// 	font-size:30px;
	// 	font-weight:900;
	// 	margin:5px 15px;
	// 	text-align:left;
	// }

	.k-main-collection-body {
		display:flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items:center;
		justify-content: center;
		margin:10px 10px;
		clear:both;
	}

	.k-main-collection-item {
		margin:12px 16px;
		// border:3px solid $primary-color;
		padding:8px 10px;
		font-size:16px;
		line-height:22px;
		text-align:left;
		vertical-align:middle;
		// background-color:$secondary-color;

		width:165px;
		height:165px;
		border-radius:25px;

		display:flex;
		justify-content: center;
		flex-direction: column;

		position:relative;
		cursor:pointer;

		-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
	    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);

		.fas {
			font-size:12px;
			color:#fff;
			margin-right:4px;
		}
	}

	.k-lp-tile {
		width:120px!important;
		height:120px!important;
		border-radius:24px!important;
		font-size:14px!important;
		line-height:18px!important;
		color:#fff;
		// hyphens: auto;	// this doesn't seem to really help; e.g. it doesn't break "microeconomics"
		.k-class-tile-course-code {
			position:absolute;
			left:0px;
			bottom:10px;
			font-size:9px;
			width:100%;
			text-align:center;
		}
	}

	.k-lp-tile-long-title {
		font-size:12px!important;
		line-height:16px!important;
	}

	.k-lp-tile-extra-long-title {
		font-size:10px!important;
		line-height:13px!important;
	}

	.k-lp-tile-inactive {
		border:3px dashed #ccc;
	}

}

.k-copyright {
	position:absolute;
	bottom:0;
	right:0;
	padding:10px;
	text-align:right;
	white-space:nowrap;
	font-size:.85em;
	color:#999;
	font-weight:bold;

	// cursor pointer for easter egg
	cursor:pointer;
}


.k-fade-enter-active {
	transition: opacity .25s;
}
.k-fade-enter, .k-fade-leave-active {
	opacity: 0;
}

</style>
