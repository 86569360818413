<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="d-flex justify-center align-center">
	<MiniNav v-show="admin_tool_showing" current_section="admin" />
	<div class="k-main-collection" :class="admin_tool_showing?'k-main-collection--item-showing k-main-collection--admin-showing':''">
		<div class="k-main-collection-header">
			<h2 class="k-page-title d-flex">
				<v-icon large color="primary" class="mr-4" style="margin-top:-2px">fas fa-gear</v-icon>
				<b>Site Admin Tools</b>
				<v-spacer/>
			</h2>
		</div>
		<div class="k-main-collection-body">
			<div @click="go_to_admin_route('users')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-1 white--text" :class="admin_tool_showing=='users'?'k-admin-item-showing':''"><div><b>Admin User Privileges</b></div><div><v-icon large color="#fff">fas fa-user-cog</v-icon></div></div>
			<div @click="go_to_admin_route('home_page')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-2 white--text" :class="admin_tool_showing=='home_page'?'k-admin-item-showing':''"><div><b>Home Page Content</b></div><div><v-icon large color="#fff">fas fa-home</v-icon></div></div>
			<div @click="go_to_admin_route('lp')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-4 white--text" :class="admin_tool_showing=='lp'?'k-admin-item-showing':''"><div><b>Courses and Repositories</b></div><div><v-icon large color="#fff">fas fa-chalkboard</v-icon></div></div>
			<div @click="go_to_admin_route('lti')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-5 white--text" :class="admin_tool_showing=='lti'?'k-admin-item-showing':''"><div><b>{{site_config.sparkl_app_name}} LTI Installations</b></div><div><v-icon large color="#fff">fas fa-plug</v-icon></div></div>
			<div @click="go_to_admin_route('usage')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-6 white--text" :class="admin_tool_showing=='usage'?'k-admin-item-showing':''"><div><b>Site Usage Stats</b></div><div><v-icon large color="#fff">fas fa-chart-line</v-icon></div></div>
			<div @click="go_to_admin_route('editlog')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-7 white--text" :class="admin_tool_showing=='editlog'?'k-admin-item-showing':''"><div><b>Course Editing Logs</b></div><div><v-icon large color="#fff">fas fa-person-digging</v-icon></div></div>
			<div @click="go_to_admin_route('resource_usage')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-8 white--text" :class="admin_tool_showing=='resource_usage'?'k-admin-item-showing':''"><div><b>Resource Usage Report</b></div><div><v-icon large color="#fff">fas fa-table</v-icon></div></div>
			<!-- <div @click="go_to_admin_route('lessonmasters')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-6" :class="admin_tool_showing=='lessonmasters'?'k-admin-item-showing':''"><div><b>Lesson Masters</b></div><div><v-icon large color="#000">fas fa-file-code</v-icon></div></div> -->
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MiniNav from '../main/MiniNav'

export default {
	components: { MiniNav },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		admin_tool_showing() {
			if (this.$route.fullPath.search(/admin\/(\w+)\b/) > -1) {
				return RegExp.$1
			} else {
				return null
			}
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		go_to_admin_route(which) {
			this.$router.push({ path: '/admin/' + which })
		},
	}
}
</script>

<style lang="scss">
.k-main-collection--admin-showing {
	display: inline-block;
	padding:0px 10px!important;

	.k-main-collection-header {
		display:none!important;
	}

	.k-main-collection-item {
		width:110px;
		height:110px;
		border-radius:16px;
		opacity:0.6;
		margin:10px;
		background-color:#f8f8f8;
		font-size:14px!important;
		line-height:18px;

		.v-icon {
			font-size:20px!important;
		}
	}

	.k-admin-item-showing {
		opacity:1.0!important;
	}
}
</style>
