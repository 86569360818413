<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Resource Usage Report
	</h2>

	<div>
    <v-container fluid style="clear:both">
		<div class="d-flex align-end mb-6">
			<div>
				<div>Load data for<span v-if="!data_is_loaded">*</span>:</div>
				<div style="width:280px" class="mt-1"><v-select v-model="report_interval" :items="interval_list" solo hide-details label="" @input="interval_changed"></v-select></div>
			</div>
			<div v-if="report_interval==='custom'" style="width:300px" class="ml-2"><v-menu v-model="interval_chooser_menu_showing" :close-on-content-click="false" transition="scale-transition" offset-y min-width="300px">
				<template v-slot:activator="{on}"><v-text-field background-color="#f8f8f8" solo hide-details v-model="interval_dates_display" label="Start Date – End Date" prepend-inner-icon="fas fa-calendar-week" readonly v-on="on"></v-text-field></template>
				<v-date-picker v-model="interval_dates" no-title scrollable range>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="interval_chooser_menu_showing=false">Done</v-btn>
				</v-date-picker>
			</v-menu></div>
			<div class="ml-8">
				<div>Show resource usage by:</div>
				<div style="width:280px" class="mt-1"><v-select v-model="breakdown_by" :items="breakdown_by_options" label="" solo hide-details @input="update_headers" class="k-ru-breakout-menu"></v-select></div>
			</div>
			<v-btn class="ml-8 mb-1" v-if="resource_usage_rows.length==0" color="primary" @click="load_admin_resource_usage">Load Report Data <v-icon small class="ml-2">fas fa-arrow-circle-right</v-icon></v-btn>
		</div>
		<!-- SF: Removing this note as this restriction is being removed -->
		<!-- <div v-if="!data_is_loaded" style="margin:-12px 0 18px 8px;font-size:14px"><i><b>*Note:</b> reports are currently limited to 7-day windows. This restriction will be lifted soon.</i></div> -->

		<div class="mb-1">Filter results by:</div>
		<v-row>
			<v-col sm="2.5">
				<v-select v-model="grade" :items="filter_grades" @input="filter_changed('grade')" label="Grade" solo outlined dense class="k-ru-filter-grade-menu"></v-select>
			</v-col>
			<v-col sm="2.5">
				<v-select v-model="subject" :items="filter_subjects" @input="filter_changed('subject')" label="Subject" solo outlined dense class="k-ru-filter-subject-menu"></v-select>
			</v-col>
			<v-col sm="2.5">
				<v-autocomplete v-model="lp" :items="filter_lps" @input="filter_changed('lp')" label="Learning Progression" solo outlined dense class="k-ru-filter-lp-menu"></v-autocomplete>
			</v-col>
			<!--<v-col sm="2.5">
				<v-autocomplete v-model="school" :items="filter_schools" @input="filter_changed('school')" label="School" solo outlined dense class="k-ru-filter-school-menu"></v-autocomplete>
			</v-col>-->
			<v-col sm="2.5">
				<v-autocomplete v-model="staff" :items="filter_staff_by_domain" @input="filter_changed('staff')" label="Staff" solo outlined dense class="k-ru-filter-staff-menu"></v-autocomplete>
			</v-col>
		</v-row>
    </v-container>
	</div>

	<div v-if="resource_usage_rows.length>0" style="clear:both">
		<div class="py-4 d-flex">
			<v-spacer/>
			<v-text-field
				v-model="raw_search"
				prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
				label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 600px"
				@keyup="search_keyup"
				@click:prepend-inner="search=raw_search"
				@click:clear="search=''"
			></v-text-field>
			<v-spacer/>
		</div>

		<div class="mb-4 d-flex align-center">
			<div class="pa-2 text-center light-blue lighten-4 elevation-2" style="border-radius:8px; flex:1 0 auto;"><b>Totals:</b>
				<span class="pl-4">Signed-in user resource access count: <b>{{this.total_staff_access_cnt.toLocaleString()}}</b></span>
				<span class="pl-8" v-if="show_stu_cnt">Unsigned-in resource access count: <b>{{this.total_student_access_cnt.toLocaleString()}}</b></span>
			</div>
			<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-4" small fab color="primary" @click="download_report"><v-icon>fas fa-download</v-icon></v-btn></template><div class="text-center">Download report data<br>to CSV file</div></v-tooltip>
		</div>

		<v-data-table light
			:headers="headers"
			:items="filtered_report_rows"
			:sort-by="['staff_access_cnt']"
			:sort-desc="[true]"
			:must-sort="true"
			:footer-props="footer_options"
			class="k-admin-resource-usage-table"
		>
			<template v-slot:item="{ item }"><tr>
				<td class="text-left" v-html="item.breakout_0"></td>
				<td v-if="num_breakout_columns>1" class="text-left" v-html="item.breakout_1"></td>
				<td v-if="num_breakout_columns>2" class="text-left" v-html="item.breakout_2"></td>
				<td v-if="num_breakout_columns>3" class="text-left" v-html="item.breakout_3"></td>
				<td class="text-center">{{item.staff_access_cnt}}</td>
				<td v-if="show_stu_cnt" class="text-center">{{item.student_access_cnt}}</td>
				<td class="text-center">{{item.gc_add_cnt}}</td>
			</tr></template>
		</v-data-table>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

// principal for testing: yvette.christian
// assistant principal: jamilla.martin

export default {
	components: { },
	props: {
		course_code: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		resource_usage_rows: [], // from ajax service
		report_rows: [],
		data_is_loaded: false,
		report_interval: 'custom',
		interval_list: [
			{value:'custom', text: 'Choose Report Dates'},
			{value:'day', text: 'Today'},
			{value:'week', text: 'This Week (since Saturday)'},
			// {value:'', text: 'Note: reports are currently limited to 7-day windows. This restriction will be lifted soon.'},
			// {value:'fortnight', text: 'Last 14 days'},
			// {value:'term', text: 'This Term'},
			// {value:'year', text: 'This Academic Year'},
		],
		interval_dates: ['',''],
		interval_dates_display: '',
		interval_chooser_showing: false,
		interval_chooser_menu_showing: false,

		show_stu_cnt: true,
		num_breakout_columns: 0,
		headers: [],
		breakdown_by_options: [
			{ text: 'Course/Resource Collection', value: 'lp_title' },
			{ text: 'Resource', value: 'resource_title' },
			{ text: 'District (Email Domain)', value: 'user_domain' },
		],
      	breakdown_by: '',

		lp_list: [], // from ajax service
		domain_list: [],
		grade: '- All Grades -',
		subject: '- All Subjects -',
		lp: '- All LPs/Repositories -',
		//school: '- All Schools/Departments -',
		staff: '- All Users -',

		footer_options: {
			itemsPerPageOptions: [50,50,100,-1],
		},

		total_staff_access_cnt: 0,
		total_student_access_cnt: 0,

		// since search filtering can take a while, use raw_search as the auto-updated value; then transfer to search when the user hits the enter key or clicks the search icon
		raw_search: '',
		search: '',

		limit_to_courses: [],
		//limit_to_schools: [],
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		filter_grades() {
			let arr = ['- All Grades -']
			for (let grade of this.$store.state.grades) {
				let text
				if (grade == 'PK') text = 'Pre-K'
				else if (grade == 'K') text = 'Kindergarten'
				else if (!isNaN(grade*1)) text = 'Grade ' + grade
				else text = grade

				arr.push({ value: grade, text: text})
			}
			return arr
		},
		filter_subjects() {
			let arr = ['- All Subjects -']
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: subject, text: subject})
			}
			return arr
		},
		// update lp list as grades or subject filters changed
		filter_lps() {
			let arr = ['- All LPs/Repositories -']
			let lp_arr = this.lp_list

			if (this.subject !== '- All Subjects -') lp_arr = lp_arr.filter(lp => lp.subject == this.subject)
			if (this.grade !== '- All Grades -') {
				let g = (this.grade === 'K') ? 0 : this.grade*1
				lp_arr = lp_arr.filter(lp => g >= lp.grade_low && g <= lp.grade_high)
			}

			lp_arr.sort((a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0))
			for (let lp of lp_arr) {
				// if this user can't edit the lp...
				if (false && !vapp.is_lp_admin(lp)) {
					// then unless the user is a principal or ap...
					// SF: Commenting this out as this getter does not exist so this was logging errors to console
					// if (!this.user_is_principal_or_ap) {
					// 	// don't let them see this lp's results
					// 	continue
					// }
				}
				arr.push({ value: lp.course_code, text: lp.title})
			}

			return arr
		},

		filter_staff_by_domain() {
			let arr = ['- All Users -']
			for (let item of this.domain_list) {
				if (!item || item == 'cglt.com') continue
				arr.push({ value: item, text: item })
			}
			// add 'Not Signed In' as first user in list
			arr.splice(1, 0,
				{ value: 'cglt.com',  text: 'Not Signed In'},
				{ value: '- All Signed-In Users -', text: 'All Signed-In Users'});
			return arr
		},
		filtered_report_rows() {
			// find rows that match search values; go through the array even if we're not searching to get totals
			let re = new RegExp(this.search, 'i')
			let arr = []
			this.total_staff_access_cnt = 0
			this.total_student_access_cnt = 0
			for (let i = 0; i < this.report_rows.length; ++i) {
				let row = this.report_rows[i]
				if (!this.search) {
					arr.push(row)
				} else {
					// search breakout_0, and the rest also if we have them/need to search them
					if (row.breakout_0 && row.breakout_0.search(re) > -1) arr.push(row)
					else if (row.breakout_1 && row.breakout_1.search(re) > -1) arr.push(row)
					else if (row.breakout_2 && row.breakout_2.search(re) > -1) arr.push(row)
					else if (row.breakout_3 && row.breakout_3.search(re) > -1) arr.push(row)
					else continue
				}
				// if we get to here, add to totals
				this.total_staff_access_cnt += row.staff_access_cnt
				this.total_student_access_cnt += row.student_access_cnt
			}

			return arr
		},
		user_is_filtering_by_grade() {
			return this.grade !== '- All Grades -'
		},
		user_is_filtering_by_subject() {
			return this.subject !== '- All Subjects -'
		},
		user_is_filtering_by_course() {
			return this.lp !== '- All LPs/Repositories -'
		},
		user_is_filtering_by_domain() {
			return (this.staff !== '- All Users -' && this.staff !== '- All Signed-In Users -')
		},
		user_is_filtering_by_signed_in() {
			return this.staff === '- All Signed-In Users -'
		},
		user_is_filtering() {
			return this.user_is_filtering_by_grade || this.user_is_filtering_by_subject || this.user_is_filtering_by_course || this.user_is_filtering_by_domain || this.user_is_filtering_by_signed_in
		}
	},
	watch: {
		// if the dates change, do same as if interval selection is changed, set up for "Load Report Data" btn to show
		interval_dates() {	// deep?
			let s = ''
			if (this.interval_dates[0]) s += this.interval_dates[0].replace(/-/g, '/') + ' – '
			if (this.interval_dates[1]) s += this.interval_dates[1].replace(/-/g, '/')
			this.interval_dates_display = s

			this.resource_usage_rows = []
			this.report_rows = []
			this.data_is_loaded = false
		},
		// We also need to set up for Load Rerort Data if we change the 'breakdown_by' dropdown
		breakdown_by() {
			this.resource_usage_rows = []
			this.report_rows = []
			this.data_is_loaded = false
		},
	},
	created() {
		vapp.admin_resource_usage_component = this
		this.load_lp_list()
		//this.load_school_list()

		// if we get a course_code as a prop, use it as the initial lp
		if (this.course_code) {
			this.lp = this.course_code
		}

		// if we received a course_code, set breakdown_by to resource_title
		if (this.course_code) { this.breakdown_by = 'resource_title'
		// else if the user is a principle or ap, start with breakdown_by set to lp_title
		} else { this.breakdown_by = 'lp_title' }
		// have to call update_headers in case we changed breakdown_by here
		this.update_headers()
	},
	mounted() {
	},
	methods: {
		domain_from_email(email) {
			return email?.match(/(?<=@)[^.]+(\.\w+)+/g)[0] ?? 'unknown'
		},
		// this service will need update if type of resource_usage.lp_id is changed to store lp_id rather than course_code
		load_lp_list() {
			let payload = { user_id: this.user_info.user_id }

			U.ajax('get_all_courses', payload, result=>{
				if (result.status != 'ok') {
					console.log('Error in get_all_courses ajax call'); vapp.ping(); return;
				}

				for (let i = 0; i < result.all_courses.length; ++i) {
					let c = result.all_courses[i]
					c.grade_low  = empty(c.grade_low) ? -1 : ((c.grade_low === 'K') ? 0 : c.grade_low*1)
					c.grade_high = empty(c.grade_high) ? -1 : ((c.grade_high === 'K') ? 0 : c.grade_high*1)
					// // set PD grades to -1 for now
					// if (c.subject === 'PD') {c.grade_low = -1; c.grade_high = -1}
				}

				this.lp_list = result.all_courses
			});
		},

		// gets the whole school table: source_id, school_code, school_name
		/*load_school_list() {
			let payload = { user_id: this.user_info.user_id }

			U.ajax('get_all_schools', payload, result=>{
				if (result.status != 'ok') {
					console.log('Error in get_all_schools ajax call'); vapp.ping(); return;
				}
				this.school_list = result.all_schools
			});
		},*/

		interval_changed() {
			// when interval is changed, reset the data (but not the form parameters);
			// then the "Load Report Data" btn will appear; let the user click that to reload
			this.resource_usage_rows = []
			this.report_rows = []
			this.data_is_loaded = false
		},

		// get the usage rows and user data from ajax service
		load_admin_resource_usage() {
			let payload = {
				user_id: this.user_info.user_id,
				report_interval: this.report_interval,
				breakdown_by: this.breakdown_by,
			}

			// console.log(this.interval_dates)
			if (this.report_interval === 'custom') {
				if (empty(this.interval_dates[0])) {
					this.$alert('You must select a start date for report.')
					return
				}
				if (empty(this.interval_dates[1])) {
					this.$alert('You must select an end date for report.')
					return
				}
				if (this.interval_dates[1] < this.interval_dates[0]) {
					this.$alert('The report end date is earlier than the start date.')
					return
				}
				payload.start_date = this.interval_dates[0]
				payload.end_date = this.interval_dates[1]

				// let date_diff_ms = date.parse(payload.end_date, 'YYYY-MM-DD') - date.parse(payload.start_date, 'YYYY-MM-DD')
				// if (date_diff_ms > 7.1*24*60*60*1000) {
				// 	this.$alert('Please choose a shorter date span, as reports are currently limited to 7-day windows. This restriction will be lifted soon.')
				// 	return
				// }
			}

			// limit results if the user isn't allowed to see everything
			// if (filter_lps	filter_schools)

			U.loading_start()
			U.ajax('admin_get_resource_usage_data_v2', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin_get_resource_usage_data ajax call'); vapp.ping(); return;
				}
				console.log('resource_usage_rows: ' + result.report_rows.length) //; console.log(result.report_rows)

				this.resource_usage_rows = result.report_rows
				this.domain_list = result.domain_list

				// if we're auto-loading initially or on report date selection change, set a type, if needed, so compile will work
				if (empty(this.breakdown_by)) {
					this.headers = [
						//{ text: 'School', align: 'left', sortable: true, value:'breakout_0' },
						{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
						{ text: 'Unsigned In Count', align: 'center', sortable: true, value:'student_access_cnt' },
						{ text: 'GC Add Count', align: 'center', sortable: true, value:'gc_add_cnt' },
					]
					this.breakdown_by = 'staff_access_cnt'
					this.show_stu_cnt = true
				}

				this.compile_report()
				// console.log('report_rows', this.report_rows)
			});
		},

		search_keyup(evt) {
			// if/when user hits enter key, set search to raw_search
			if (evt.key === "Enter"){
				this.search = this.raw_search
			}
		},

		// filter selection triggers view change if data has been loaded
		filter_changed(type) {
			if (this.data_is_loaded) {
				this.compile_report()
			}
		},

		aggregate_filtered_row_totals(row) {
			// If 1-x subfilters are being applied, we need to re-count the row total based on those filters
			const domains_array = () => {
				if (!this.user_is_filtering_by_domain && !this.user_is_filtering_by_signed_in) return []
				if (this.breakdown_by !==  'user_domain') {
					return row.user_domains?.filter(match => {
						if (this.user_is_filtering_by_domain) return match.user_domain === this.staff
						if (this.user_is_filtering_by_signed_in) return match.signed_in_count !== 0
					} )
				}
				return []
			}
			const course_array = () => {
				if (!this.user_is_filtering_by_course) return []
				if (this.breakdown_by !== 'lp_title') {
					return row.lp_titles?.filter(match => match.course_code === this.lp) ?? []
				}
				return []
			}
			const subjects_array = () => {
				if (!this.user_is_filtering_by_subject) return []
				if (this.breakdown_by !== 'lp_title') {
					return row.lp_titles?.filter(match => match.subject === this.subject) ?? []
				}
				return []
			}

			let is_in_grade_range = (grade_low, grade_high) => {
				let gl = (grade_low == 'K') ? 0 : grade_low
				let gh = (grade_high == 'K') ? 0 : grade_high
				let filter_grade = (this.grade === 'K') ? 0 : this.grade * 1
				return (filter_grade >= gl && filter_grade <= gh)
			}

			const grades_array = () => {
				if (!this.user_is_filtering_by_grade) return []
				if (this.breakdown_by !== 'lp_title') {
					return row.lp_titles?.filter(match => is_in_grade_range(match.grade_low, match.grade_high))
				}
				return []
			}

			// combine arrays
			let combined_filtered_items = [...domains_array(), ...course_array(), ...subjects_array(), ...grades_array()];

			// If no matches, just return the original row counts.
			// An example of how this might happen is the user selects 'Domain' for the top level filter, then selects a specific Domain from the second level filter
			if (!(combined_filtered_items.length > 0)) return { signed_in_count: row.signed_in_count, signed_out_count: row.signed_out_count }

			// remove duplicate objects -- since object comparison is by reference, we have to stringify and then convert back
			let unique_filtered_items = Array.from(new Set(combined_filtered_items.map(JSON.stringify))).map(JSON.parse);

			// Next, remove any items that do not meet all applied active filter conditions
			// To do so, we have to run multiple conditional tests.
			// For example, before evaluating if the selected "subject" filter is a match, we have to first test:
				//  a: Has the user applied a subject filter? If not, we ignore it by assigning the match to TRUE.
				//  b: Has the user selected EITHER Course or Resource as their top level filter? If so, we will assume TRUE prior to our final test. This keeps this from incorrectly evaluating as FALSE if multiple filters are selected.
				//	c: Is the item's subject a match for the selected filter subject? If so, TRUE
			let full_matches = unique_filtered_items.filter(item => {
				let match_grade_filter = (!this.user_is_filtering_by_grade || ['lp_title', 'resource_title'].includes(this.breakdown_by)) ||  is_in_grade_range(item.grade_high, item.grade_low)
				let match_subject_filter = (!this.user_is_filtering_by_subject  || ['lp_title', 'resource_title'].includes(this.breakdown_by)) || (item.subject === this.subject)
				let match_course_filter = (!this.user_is_filtering_by_course  || ['lp_title', 'resource_title'].includes(this.breakdown_by)) || (item.course_code === this.lp)
				let match_domain_filter = (!this.user_is_filtering_by_domain || ['user_domain', 'resource_title'].includes(this.breakdown_by)) || (item.user_domain === this.staff)
				return match_grade_filter && match_subject_filter && match_course_filter && match_domain_filter
			})

			// Finally, we need to reset our count totals, and then total up all of the values for each match
			let response = { signed_in_count: 0, signed_out_count: 0 }
			full_matches.forEach(item => {
				response.signed_in_count += item.signed_in_count;
				response.signed_out_count += item.signed_out_count;
			});

			return response
		},

		// hash on first breakout now, those following are informational
		compile_report() {
			this.report_rows = []
			let report_hash = {}

			for (let row of this.resource_usage_rows) {
				// Make sure the user_domains node is an array and not an object.
				// This doesn't matter if we are breaking down by user_domain, which returns a single value
				if (this.breakdown_by !== 'user_domain') row.user_domains = Object.values(row.user_domains)
				// Same thing for lp_titles if not breaking down by lp_title
				if (this.breakdown_by !== 'lp_title') row.lp_titles = Object.values(row.lp_titles)

				// apply user selected filters to rows before hashing/aggregation
				if (this.exclude_row(row)) continue

				// in some scenarios the course code for staff & admin views is not logged
				// exclude these counts from views that show LP
				// TODO: Wouldn't it be better to do this in the service?
				if (row.system_role !== 'student' && row.lp_id == 0 && ['user_domain', 'lp_title'].includes(this.breakdown_by)) continue

				let row_keys
				row_keys = [row[this.breakdown_by]]

				for (let row_key of row_keys) {
					row.breakout_0 = row_key

					////// aggregate resource usage by hash key
					if (!(row_key in report_hash)) {
						// for new hash entries, add common fields and initialize counts
						report_hash[row_key] = {}

						// transfer breakout_0 to report_hash
						report_hash[row_key].breakout_0 = row.breakout_0

						// other fields common to report row
						report_hash[row_key].course_code = row.course_code
						report_hash[row_key].user_domains = row.user_domains

						// Here we will aggregate usage counts. If we are not applying any secondary filters, we can just use the top level accumulated value provided by the service
						let usage_counts = {
							signed_in_count: row.signed_in_count,
							signed_out_count: row.signed_out_count
						}

						// If we are applying one of the second level filters(grade, subject, lp, domain) we need to search through the nested arrays for lp or domain in the row and aggregate totals
						if (this.user_is_filtering) usage_counts = this.aggregate_filtered_row_totals(row)

						report_hash[row_key].staff_access_cnt = usage_counts.signed_in_count
						report_hash[row_key].student_access_cnt = usage_counts.signed_out_count

						if (this.breakdown_by == 'resouce_title') {
							report_hash[row_key].resource_id = row.resource_id
						}
						// initialize lps, subjects, schools, and grades fields if we need them
						if (['user_domain', 'resource_title'].includes(this.breakdown_by)) {
							report_hash[row_key].lp_titles = []
						}
						report_hash[row_key].subjects  = []
						report_hash[row_key].grades = []
					}
					///////////////////// add data to informational rows as report configuration dictates
					// The user_domain and resource_title filters include lp information in an array
					if (['user_domain', 'resource_title'].includes(this.breakdown_by)) {
						row['lp_titles'].forEach(lp => {
							// Check to make sure we aren't filtering the row out based on any of the input filters
							const course_passes = !this.user_is_filtering_by_course || lp.course_code === this.lp
							const subject_passes = !this.user_is_filtering_by_subject || lp.subject === this.subject
							let gl = (lp.grade_low == 'K') ? 0 : lp.grade_low * 1
							let gh = (lp.grade_high == 'K') ? 0 : lp.grade_high * 1
							let filter_grade = (this.grade === 'K') ? 0 : this.grade * 1
							const grade_passes = !this.user_is_filtering_by_grade || (filter_grade >= gl && filter_grade <= gh)

							// If not, add the row content to the hash if it isn't already there
							if (course_passes && subject_passes && grade_passes) {
								if (report_hash[row_key].lp_titles.indexOf(lp.lp_title) === -1) report_hash[row_key].lp_titles.push(lp.lp_title)
								if (report_hash[row_key].subjects.indexOf(lp.subject) === -1) report_hash[row_key].subjects.push(lp.subject)

								if (gl == gh && report_hash[row_key].grades.indexOf(gl) === -1) {
									report_hash[row_key].grades.push(gl)
								} else {
									for (let i = gl; i <= gh; ++i) {
										if (report_hash[row_key].grades.indexOf(i) === -1) report_hash[row_key].grades.push(i)
									}
								}
							}
						})
					}

					if (this.breakdown_by === 'lp_title') {
						if (row['subject'] && report_hash[row_key]['subjects'].indexOf(row['subject']) === -1) {
							report_hash[row_key]['subjects'].push(row['subject'])
						}

						let gl = (row['grade_low'] == 'K') ? 0 : row['grade_low']*1
						let gh = (row['grade_high'] == 'K') ? 0 : row['grade_high']*1
						if (gl == gh && report_hash[row_key].grades.indexOf(gl) === -1) {
							report_hash[row_key].grades.push(gl)
						} else {
							for (let i = gl; i <= gh; ++i) {
								if (report_hash[row_key].grades.indexOf(i) === -1) report_hash[row_key].grades.push(i)
							}
						}
					}
				}	// end of for loop on row_keys
			}
			this.report_rows = Object.values(report_hash)

			for (let i = 0; i < this.report_rows.length; ++i) {
				let row = this.report_rows[i]

				if (row['breakout_0'] == 'unsignedin@cglt.com') {
					row['breakout_0'] = 'Not Signed In'
				}

				// sort grades array before formatting grade ranges
				if (['lp_title', 'resource_title'].includes(this.breakdown_by)) {
					row.grades.sort((a,b) => a - b)
				}

				// create formatted values to show in columns
				if (this.num_breakout_columns > 1) row.breakout_1 = this.format_column_value(row, 1)
				if (this.num_breakout_columns > 2) row.breakout_2 = this.format_column_value(row, 2)
				if (this.num_breakout_columns > 3) row.breakout_3 = this.format_column_value(row, 3)
			}

			// console.log('report_rows: ' + this.report_rows.length); console.log(this.report_rows)
			this.data_is_loaded = true
		},

		// we do some different things to format
		format_column_value(row, col_index) {
			let col_name = this.headers[col_index].value

			if (col_name === 'grade') {
				//if (row.breakout_0 != 'HMH activities') return
				return this.grade_range_str(row['grades'])
			} else if (col_name == 'resource_title') {
				return row[col_name + 's'].join(', ')
			} else if (col_name == 'subject' || col_name == 'lp_title') {
				return '<nobr>' + row[col_name + 's'].join(',</nobr><br><nobr>') + '</nobr>'
			} else {
				return 'ERROR'
			}
		},

		// assemble string for grade(s) display
		// there can be disjoint grade ranges
		grade_range_str(grades) {
			if (grades && grades[0] == -1) return ''
			if (grades.length === 1) return (grades[0] === 0) ? 'K' : grades[0]+''

			// snippet to convert array of grades to array of range strings
			// incoming array of grades must be sorted
			// https://stackoverflow.com/questions/2270910/how-to-reduce-consecutive-integers-in-an-array-to-hyphenated-range-expressions
			var ranges = [], rstart, rend
			for (var i = 0; i < grades.length; i++) {
				rstart = grades[i]
				rend = rstart

				while (grades[i + 1] - grades[i] === 1) {
					rend = grades[i + 1] // increment the index if the numbers sequential
					i++
				}
				rstart = (rstart === 0) ? 'K' : rstart
				ranges.push(rstart === rend ? rstart+'' : rstart + '-' + rend);
			}

			return ranges.join(', ')
		},

		lp_link(course_code, lp_title) {
			return sr('<a href="javascript:vapp.admin_resource_usage_component.open_lp($1)">$2</a>', course_code, lp_title)
		},
		open_lp(course_code) {
			if (course_code*1 < 90000) {
				this.$router.push({ path: sr('/course/lp/$1/0/0', course_code) })
			} else {
				this.$router.push({ path: sr('/repo/$1/0/0', course_code) })
			}
		},
		resource_link(resource_id, resource_title) {
			return sr('<a href="javascript:vapp.admin_resource_usage_component.open_resource(\'$1\')">$2</a>', resource_id, resource_title)
		},
		open_resource() {

		},

		// report user's filter selection(s) on usage rows prior to hashing & counting
		// assuming only one selection per filter
		exclude_row(row) {
			if (['user_domain', 'resource_title'].includes(this.breakdown_by)) {
				if (this.user_is_filtering_by_grade &&
					row.lp_titles.findIndex(g => {
						let gl = (g.grade_low == 'K') ? 0 : g.grade_low
						let gh = (g.grade_high == 'K') ? 0 : g.grade_high
						let filter_grade = (this.grade === 'K') ? 0 : this.grade * 1
						return (filter_grade >= gl && filter_grade <= gh)
					}) === -1) return true
				if (this.user_is_filtering_by_subject && row.lp_titles.findIndex(s => this.subject == s.subject) === -1) return true
				if (this.user_is_filtering_by_course && row.lp_titles.findIndex(c => this.lp == c.course_code) === -1) return true
			} else {
				if (this.user_is_filtering_by_grade) {
					let gl = (row.grade_low == 'K') ? 0 : row.grade_low
					let gh = (row.grade_high == 'K') ? 0 : row.grade_high
					let filter_grade = (this.grade === 'K') ? 0 : this.grade*1
					if (filter_grade < gl || filter_grade > gh) return true
				}
				if (this.user_is_filtering_by_subject && this.subject !== row.subject) return true
				if (this.user_is_filtering_by_course && this.lp !== row.course_code) return true
			}

			// We receive an array of user_domains from service when filtering on Resource or Course, but a single value if filtering on Domain,
			// so there is some additional logic here to handle that
			if (this.user_is_filtering_by_domain) {
				const domain_is_excluded = this.breakdown_by !== 'user_domain'
					? row.user_domains.every(domain => domain.user_domain !== this.staff)
					: row.user_domain !== this.staff
				if (domain_is_excluded) return true
			}
			if (this.user_is_filtering_by_signed_in) {
				const domain_is_cglt = this.breakdown_by !== 'user_domain'
					? row.user_domains.every(domain => domain.user_domain === 'cglt.com')
					: row.user_domain === 'cglt.com'
				if (domain_is_cglt) return true
			}
			return false
		},

		// update report columns as breakout column is selected
		update_headers(value) {
			if (this.breakdown_by == 'lp_title') {
				this.headers = [
					{ text: 'Course/Resource Collection', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'Subject', align: 'left', sortable: false, value:'subject' },
					{ text: 'Grade(s)', align: 'left', sortable: false, value:'grade' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'Not-Signed-In Count', align: 'center', sortable: true, value:'student_access_cnt' },
				]
				this.show_stu_cnt = true
				this.num_breakout_columns = 3

			} else if (this.breakdown_by == 'resource_title') {
				this.headers = [
					{ text: 'Resource', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'Subject', align: 'left', sortable: false, value:'subject' },
					{ text: 'Grade(s)', align: 'left', sortable: false, value:'grade' },
					{ text: 'Course/Resource Collection', align: 'left', sortable: false, value:'lp_title' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'Not-Signed-In Count', align: 'center', sortable: true, value:'student_access_cnt' },
				]
				this.show_stu_cnt = true
				this.num_breakout_columns = 4
			} else if (this.breakdown_by == 'user_domain') {
				this.headers = [
					{ text: 'User Domain', align: 'left', sortable: true, value:'breakout_0' },
					{ text: 'Subject', align: 'left', sortable: false, value:'subject' },
					{ text: 'Grade(s)', align: 'left', sortable: false, value:'grade' },
					{ text: 'Course/Resource Collection', align: 'left', sortable: false, value:'lp_title' },
					{ text: 'Staff Access Count', align: 'center', sortable: true, value:'staff_access_cnt' },
					{ text: 'Not-Signed-In Count', align: 'center', sortable: true, value:'student_access_cnt' },
					// { text: 'Course/Resource Collection', align: 'left', sortable: false, value:'lp_title' },
				]
				this.show_stu_cnt = true
				this.num_breakout_columns = 4
			}
			// that should cover all possibilities
		},

		download_report() {
			let filename = sr('Resource Usage Report - $1.csv', date.format(new Date(), 'YYYY-MM-DD hh:mm'))

			let table = []

			// create header row
			let header_row = []
			for (let col of this.headers) {
				header_row.push(col.text)
			}

			// create table rows
			for (let item of this.filtered_report_rows) {
				let row = []
				row.push(item.breakout_0)
				if (this.num_breakout_columns > 1) row.push(item.breakout_1)
				if (this.num_breakout_columns > 2) row.push(item.breakout_2)
				if (this.num_breakout_columns > 3) row.push(item.breakout_3)
				row.push(item.staff_access_cnt)
				if (this.show_stu_cnt) row.push(item.student_access_cnt)
				//row.push(item.gc_add_cnt)

				table.push(row)
			}

			// sort by column 1 (second column) descending, which is always a number
			table.sort((a,b) => b[1] - a[1])

			// add the header to the top of the file
			table.unshift(header_row)

			U.download_file(CSV.stringify(table), filename)
		},

		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		},
	},
}
</script>

<style lang="scss">
	.k-admin-resource-usage-table {
		th {
			white-space:nowrap;
		}
	}
</style>
