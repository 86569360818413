<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title><b>Edit User Admin Rights</b></v-card-title>
	<v-card-text>
		<div style="font-size:1.2em"><b>{{admin_user.last_name}}, {{admin_user.first_name}} ({{admin_user.email}})</b></div>

		<v-checkbox :label="'“Superuser” admin rights'" v-model="su" @change="su_changed" hide-details></v-checkbox>
		<v-checkbox :label="'Create/Edit PD Resource Collections'" :disabled="su" v-model="pd" @change="pd_changed" hide-details></v-checkbox>
		<v-checkbox :label="'Create/Edit Parent/Guardian Announcements and Resource Collections'" :disabled="su" v-model="pg" @change="pg_changed" hide-details></v-checkbox>
		<v-checkbox :label="'Mimic Any User'" :disabled="su" v-model="mimic_all" @change="mimic_all_changed" hide-details></v-checkbox>

		<div class="mt-4">
			<b>Courses that can be EDITED:</b>
			<div class="mt-1">
				<v-chip :disabled="su" v-for="lp in lps" :key="lp.code" small class="mr-1" close @click:close="remove_right(lp.code)">{{lp.text}}</v-chip>
				<v-btn v-show="!su&&!show_add_lp_interface" small color="primary" @click="show_add_lp_interface=true"><v-icon class="mr-1" small>fas fa-plus</v-icon> Add</v-btn>
				<i class="grey--text text--darken-1" v-if="su">Note: Superusers can edit all courses.</i>
			</div>
			<div class="mt-2" style="display:flex" v-show="show_add_lp_interface">
				<div style="width:400px"><v-autocomplete outlined hide-details clearable v-model="lp_add_item" :items="lp_add_menu_items" label="Choose Course EDIT Rights" solo dense></v-autocomplete></div>
				<v-btn class="ml-2" color="primary" @click="add_course_right('lp')" :disabled="!lp_add_item"><v-icon class="mr-1" small>fas fa-plus</v-icon> Add</v-btn>
			</div>
		</div>

		<div class="mt-4">
			<b>Courses that can be VIEWED (even when tagged as inactive):</b>
			<div class="mt-1">
				<v-chip :disabled="su" v-for="view_lp in view_lps" :key="view_lp.code" small class="mr-1" close @click:close="remove_right(view_lp.code)">{{view_lp.text}}</v-chip>
				<v-btn v-show="!su&&!show_add_view_lp_interface" small color="primary" @click="show_add_view_lp_interface=true"><v-icon class="mr-1" small>fas fa-plus</v-icon> Add</v-btn>
				<i class="grey--text text--darken-1" v-if="su">Note: Superusers can view all courses.</i>
			</div>
			<div class="mt-2" style="display:flex" v-show="show_add_view_lp_interface">
				<div style="width:400px"><v-autocomplete outlined hide-details clearable v-model="view_lp_add_item" :items="lp_add_menu_items" label="Choose Course VIEW Rights" solo dense></v-autocomplete></div>
				<v-btn class="ml-2" color="primary" @click="add_course_right('view_lp')" :disabled="!view_lp_add_item"><v-icon class="mr-1" small>fas fa-plus</v-icon> Add</v-btn>
			</div>
		</div>

	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="red" dark @click="remove_all_rights"><v-icon small class="mr-2">fas fa-trash</v-icon> Remove All Admin Rights for User</v-btn>
		<v-spacer></v-spacer>
		<v-btn color="secondary" @click="$emit('editor_cancel')" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		admin_user: { type: Object, required: true },
	},
	data() { return {
		dialog_open: true,
		rights: [],
		su: false,
		pd: false,
		pg: false,
		mimic_all: false,
		lps: [],
		view_lps: [],
		lp_add_item: null,
		view_lp_add_item: null,
		show_add_lp_interface: false,
		show_add_view_lp_interface: false,
	}},
	computed: {
		...mapState(['user_info', 'all_courses']),
		lp_add_menu_items() {
			let arr = [
				{value:'level.all', text: 'ALL COURSES'},
				{value:'level.Elementary', text: 'ALL Elementary Courses'},
				{value:'level.Middle School', text: 'ALL Middle School Courses'},
				{value:'level.High School', text: 'ALL High School Courses'},
			]
			for (let subject in this.$store.state.subjects) {
				arr.push({ value: 'subject.' + subject, text: 'ALL ' + subject})
			}
			for (let course of this.all_courses) {
				arr.push({ value: 'course.' + course.course_code, text: course.title })
			}

			// note: we will add 'lp.' or 'view_lp.' to the start of the value in add_course_rights
			return arr
		},
	},
	created() {
		this.rights = this.admin_user.rights.concat([])
		this.rights_changed()
	},
	mounted() {
		// DEBUG
		vapp.admin_user_edit = this
	},
	methods: {
		rights_changed() {
			// NOTE THAT CODE BELOW IS LARGELY MIRRORED IN AdminUsers.vue
			// if user has su rights, they can do everything else too
			if (this.rights.findIndex(s=>s=='su') > -1) {
				this.su = true
				this.pd = true
				this.pg = true
				this.mimic_all = true
				this.lps = [
					{ code: 'lp.level.all', text: 'ALL COURSES' },
				]
				this.view_lps = [
					{ code: 'view_lp.level.all', text: 'ALL COURSES' },
				]

			} else {
				// else build up list from rights
				this.su = false
				this.pd = false
				this.pg = false
				this.mimic_all = false
				this.lps = []
				this.view_lps = []
				for (var r of this.rights) {
					if (r == 'qs') this.qs = true

					// Professional Development
					else if (r == 'pd') this.pd = true

					// Parent/Guardian
					else if (r == 'pg') this.pg = true

					// Mimic Any User
					else if (r == 'mimic_all') this.mimic_all = true

					// Edit courses
					if (r.search(/^lp\.(.*?)\.(.*)/) > -1) {
						let l1 = RegExp.$1
						let l2 = RegExp.$2
						let text
						if (l2 == 'all') {
							text = 'ALL COURSES'
						} else if (l1 == 'level') {
							text = 'ALL ' + l2
						} else if (l1 == 'subject') {
							text = 'ALL ' + l2
						} else {
							// lp.course.1355
							let course = this.all_courses.find(x=>x.course_code == l2)
							if (!empty(course)) {
								text = course.title
							} else {
								text = 'Course ID ' + l2
							}
						}
						this.lps.push({ code: r, text: text })
					}

					// View courses
					if (r.search(/^view_lp\.(.*?)\.(.*)/) > -1) {
						let l1 = RegExp.$1
						let l2 = RegExp.$2
						let text
						if (l2 == 'all') {
							text = 'ALL COURSES'
						} else if (l1 == 'level') {
							text = 'ALL ' + l2
						} else if (l1 == 'subject') {
							text = 'ALL ' + l2
						} else {
							// lp.course.1355
							let course = this.all_courses.find(x=>x.course_code == l2)
							if (!empty(course)) {
								text = course.title
							} else {
								text = 'Course ID ' + l2
							}
						}
						this.view_lps.push({ code: r, text: text })
					}
				}
			}
		},

		su_changed() {
			if (this.su) this.add_right('su')
			else this.remove_right('su')
		},
		pd_changed() {
			if (this.pd) this.add_right('pd')
			else this.remove_right('pd')
		},
		pg_changed() {
			if (this.pg) this.add_right('pg')
			else this.remove_right('pg')
		},
		mimic_all_changed() {
			if (this.mimic_all) this.add_right('mimic_all')
			else this.remove_right('mimic_all')
		},


		add_course_right(type) {
			// this[type+'_add_item'] means lp_add_item or view_lp_add_item
			// it will be one of the values of lp_add_menu_items, e.g. 'level.Elementary'
			// we add 'type.' to the front of this string for the right we're adding
			let lai = type + '.' + this[type+'_add_item']
			if (empty(lai)) {
				this.$alert('Please specify a grade level, subject, or course in the menu at left.')
				return
			}
			this.add_right(lai)
			this[type+'_add_item'] = null
		},

		add_right(code) {
			console.log('add_right: ' + code)
			if (!this.rights.find(c=>c==code)) {
				this.rights.push(code)
				this.rights_changed()
			}
		},

		remove_right(code) {
			let i = this.rights.findIndex(c=>c==code)
			if (i > -1) {
				this.rights.splice(i, 1)
				this.rights_changed()
			}
		},

		remove_all_rights() {
			this.$confirm({
			    title: 'Are you sure?',
			    text: 'Are you sure you want to remove all admin rights for this user?',
			    acceptText: 'Remove Rights',
				acceptColor: 'red',
				dialogMaxWidth: 600
			}).then(y => {
				// just emit rights_edited with an empty array
				this.$emit('rights_edited', [])
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		save_edits() {
			let rights = []
			if (this.su) rights.push('su')
			else {
				if (this.pd) rights.push('pd')
				if (this.pg) rights.push('pg')
				if (this.mimic_all) rights.push('mimic_all')
				for (let lp of this.lps) rights.push(lp.code)
				for (let view_lp of this.view_lps) rights.push(view_lp.code)
			}

			this.$emit('rights_edited', rights)
		},
	}
}
</script>

<style lang="scss">
</style>
