<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div :class="outer_class" :data-case-identifier="item.identifier">
	<v-tooltip bottom><template v-slot:activator="{on}">
		<v-btn v-on="on" :color="btn_color" :small="small" class="k-case-item-btn k-tight-btn k-nocaps-btn" @click="btn_clicked">
			<span v-html="btn_text"></span>
			<v-icon v-if="show_delete_icon" small class="ml-2" @click.stop="$emit('delete')">fas fa-trash-alt</v-icon>
			<v-icon v-if="show_move_icon" small class="k-move-handle ml-2" @click.stop="">fas fa-up-down-left-right</v-icon>
		</v-btn>
	</template><div style="max-width:300px; font-size:14px; line-height:17px;" v-html="tooltip"></div></v-tooltip>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		item: { type: Object, required: true },
		outer_class: { type: String, required: false, default() { return ''} },
		btn_color: { type: String, required: false, default() { return 'primary'} },
		small: { type: Boolean, required: false, default() { return false} },
		show_delete_icon: { type: Boolean, required: false, default() { return false} },
		show_move_icon: { type: Boolean, required: false, default() { return false} },
		framework_identifier: { type: String, required: false, default() { return ''} },
		selected_items: { required: false, default() { return null } },
		limit_to_selected_items: { required: false, default() { return 'na'} },	// values: false, 'only', 'children'
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		btn_text() {
			if (this.item.humanCodingScheme) return this.item.humanCodingScheme
			if (this.item.fullStatement.length < 25) return this.item.fullStatement
			return this.item.fullStatement.substr(0, 15) + '…'
		},
		tooltip() {
			let s = this.item.fullStatement
			if (this.item.humanCodingScheme) s = sr('**$1**  $2', this.item.humanCodingScheme, s)
			return U.marked_latex(s)
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		btn_clicked($evt) {
			// if no framework_identifier specified, just emit
			if (!this.framework_identifier) {
				this.$emit('click', $evt)
				return
			}

			// else show the framework, highlight this item, and send in selected_items and limit_to_selected_items if there
			let data = { 
				framework_identifier: this.framework_identifier, 
				item_identifier: this.item.identifier, 
				no_framework_reset: true,
			}
			
			// if we have selected_items...
			if (this.selected_items && this.selected_items.length > 0) {
				data.selected_items = []
				for (let item of this.selected_items) {
					// allow selected_items to be full case item JSON or identifiers
					if (typeof(item) == 'string') data.selected_items.push(item)
					else data.selected_items.push(item.identifier)
				}

				// if we receive selected_items, default value for limit_to_selected_items is 'only'
				if (this.limit_to_selected_items != 'na') data.limit_to_selected_items = this.limit_to_selected_items
				else data.limit_to_selected_items = 'only'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})

		},
	}
}
</script>

<style lang="scss">
</style>