<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<div v-if="lesson" style="margin:32px auto 32px auto; max-width:900px;">
		<v-card class="k-resource-collection-item-lesson-card">
			<div class="d-flex align-center">
				<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
				<div class="k-lesson-title" style="font-weight:bold" v-html="lesson.lesson_title"></div>
				<v-spacer/>
				<div class="k-lesson-course-unit-header" v-html="course_unit_header"></div>
				<div class="mr-2" v-html="date_header"></div>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!lesson_being_edited" class="ml-1 mr-1" fab small dark color="#333" @click="print_lesson"><v-icon small>fas fa-print</v-icon></v-btn></template>Print Lesson</v-tooltip>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-1 mr-1" fab small color="purple" dark @click="share_lesson"><v-icon>fas fa-share-nodes</v-icon></v-btn></template>Share Lesson with other educators</v-tooltip>
				<v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-show="!lesson_being_edited" class="ml-1" fab small color="primary"><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
					<v-list dense>
						<v-list-item @click="print_lesson"><v-list-item-icon><v-icon small color="black">fas fa-print</v-icon></v-list-item-icon><v-list-item-title class="black--text">Print Lesson</v-list-item-title></v-list-item>
						<v-list-item @click="share_lesson"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title class="purple--text text--darken-2">Share Lesson with other educators</v-list-item-title></v-list-item>
						<v-list-item @click="copy_link_to_clipboard"><v-list-item-icon><v-icon small>fas fa-link</v-icon></v-list-item-icon><v-list-item-title>Copy lesson link to clipboard</v-list-item-title></v-list-item>
						<v-list-item v-if="true" @click="add_to_gc"><v-list-item-icon><img src="./../../images/google_classroom_logo.png" style="height:18px;margin-top:3px;margin-left:-4px;border-radius:3px;"></v-list-item-icon><v-list-item-title>Add to Google Classroom</v-list-item-title></v-list-item>
						<v-list-item v-if="lesson_editable" @click="lesson_being_edited=true"><v-list-item-icon><v-icon small>fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit Lesson</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>
			<div style="padding:0 24px 12px 24px">
				<LessonView v-if="!lesson_being_edited" :lesson="lesson" :print_lesson_dom_id="print_lesson_dom_id" />
				<div v-if="lesson_being_edited" class="k-lesson-edit-outer">
					<LessonEditor :original_lesson="lesson" :lesson_class="lesson_class" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />
				</div>
			</div>
		</v-card>
		<LessonPrint v-if="show_print_dialog" :lesson="lesson" :print_lesson_dom_id="print_lesson_dom_id" @dialog_cancel="show_print_dialog=false" />
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonView from '../lessons/LessonView'
import LessonEditor from '../lessons/LessonEditor'
import LessonPrint from '../lessons/LessonPrint'
export default {
	components: { LessonView, LessonEditor, LessonPrint },
	props: {
		lesson_id: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		lesson: null,
		lesson_being_edited: false,
		lp: null,

		case_tree_loaded: false,
		case_tree_showing: false,
		starting_lsitem_identifier: '',
		show_print_dialog: false,
		print_lesson_dom_id: U.new_uuid(),
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'frameworks_loaded']),
		course_unit_header() { return this.lesson.course_unit_header() },
		date_header() { return this.lesson.date_header() },
		lesson_class() {
			if (this.lesson.lesson_id == this.lesson.lesson_template_id) return 'template'
			else return 'teacher'
		},
		lesson_editable() {
			// template lessons are not editable here
			if (this.lesson_class == 'template') return false
			// otherwise you can edit only if you're the creator of the lesson
			return this.lesson.creator_user_id == this.user_info.user_id
		},
		case_framework_identifier() {
			if (!this.lp) return ''
			return this.$store.state.subjects[this.lp.subject]?.framework_identifier	// may return null
		},
	},
	created() {
		vapp.lesson_standalone_view = this
	},
	mounted() {
		U.loading_start()
		U.ajax('get_lesson', {user_id: this.user_info.user_id, lesson_id: this.lesson_id}, result=>{
			U.loading_stop()
			if (result.status == 'not_found') {
				this.$alert('The specified lesson (id #' + this.lesson_id + ') could not be found in the database.')
				return
			} else if (result.status != 'ok') {
				console.log('Error in ajax call'); vapp.ping(); return;
			}

			this.lesson = new Lesson(result.lesson)

			// if we received a learning_progression, store it in state.all_courses
			if (result.learning_progression) {
				let lp = this.all_courses.find(x=>x.course_code==result.learning_progression.course_code)
				if (empty(lp)) {
					lp = new Learning_Progression(result.learning_progression)
					this.$store.commit('set', [this.all_courses, 'PUSH', lp])
				}
				this.lp = this.all_courses.find(x=>x.course_code==lp.course_code)
			}
		});
	},
	methods: {
		add_to_gc() {
			let url = sr('https://classroom.google.com/share?url=$1', this.lesson.standalone_link())
			let school = (this.user_info.district_department) ? this.user_info.district_department.join(',') : ''
			let course_code = (this.lp) ? this.lp.course_code : ''

			this.$store.dispatch('log_resource_usage', {
				'resource_id': this.lesson.lesson_id,
				'resource_title': this.lesson.lesson_title,
				'action': 'add_to_gc_lesson',
				'user_id': this.user_info.user_id,
				'user_email': this.user_info.email,
				'system_role': this.user_info.system_role,
				'course_code': course_code,
				'school': school,
			})

			// add title to url
			url += sr('&title=Lesson: $1', U.html_to_text(this.lesson.lesson_title))
			window.open(url)
		},

		copy_link_to_clipboard() {
			U.copy_to_clipboard(this.lesson.standalone_link())
			this.$inform('Lesson link copied to clipboard.')
		},

		print_lesson() {
			this.show_print_dialog = true
		},

		share_lesson() {
			let shared_lesson_id = 'L' + this.lesson.lesson_id
			let msg = sr('<div class="mb-2">To share this $1, give the following $1 ID to your colleague:</div>', 'Lesson')
			msg += sr('<div class="mb-2 text-center" style="font-size:22px"><b>$1</b></div>', shared_lesson_id)
			msg += sr('<div style="font-size:14px; line-height:18px;">Your colleague can import this $1 by clicking the “+ CREATE” button from the “My Lessons & Activities” area of a course or unit, choosing “Import shared $1”, and entering this $1 ID.</div>', 'Lesson')

			this.$confirm({
				title: 'Share ' + 'Lesson',
				text: msg,
				cancelText: 'Copy Lesson ID',
				dialogMaxWidth: 600,
			}).catch(e=>{
				U.copy_to_clipboard(shared_lesson_id)
				this.$inform(sr('$1 ID copied to clipboard', 'Lesson'))
			})
		},

		edit_lesson_cancel() {
			this.lesson_being_edited = false
		},

		edit_lesson_saved(edited_lesson) {
			this.lesson = new Lesson(edited_lesson)
		},
	},
}
</script>

<style lang="scss">
.k-lesson-standalone-student-view {
	// background-color:$v-pink-lighten-5!important;
}
</style>
