<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<v-menu bottom left><template v-slot:activator="{on}">
		<v-btn class="k-user-menu-show-large k-nocaps-btnx k-tight-btnx" v-on="on" color="#666" dark><v-icon small class="mr-2">fas fa-user</v-icon><span class="k-user-menu-role-display">{{role_display}}</span></v-btn>
		<v-btn class="k-user-menu-show-small" v-on="on" color="#666" icon dark><v-icon small>fas fa-user</v-icon></v-btn>
	</template>
		<v-list dense min-width="180">
			<v-list-item class="k-user-menu-show-small text-center"><v-list-item-title><b>{{user_info.first_name}} {{user_info.last_name}}</b></v-list-item-title></v-list-item>
			<v-divider class="k-user-menu-show-small"/>
			<v-list-item @click="change_role('admin')" v-if="show_role('admin')"><v-list-item-icon><v-icon small v-show="role=='admin'">fas fa-check</v-icon><v-icon small v-show="role!='admin'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>Site Admin</v-list-item-title></v-list-item>
			<v-list-item @click="change_role('staff')" v-if="show_role('staff')"><v-list-item-icon><v-icon small v-show="role=='staff'">fas fa-check</v-icon><v-icon small v-show="role!='staff'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>Staff</v-list-item-title></v-list-item>
			<v-list-item @click="change_role('student')" v-if="show_role('student')"><v-list-item-icon><v-icon small v-show="role=='student'">fas fa-check</v-icon><v-icon small v-show="role!='student'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>Student</v-list-item-title></v-list-item>
			<v-list-item @click="change_role('parent')" v-if="show_role('parent')"><v-list-item-icon><v-icon small v-show="role=='parent'">fas fa-check</v-icon><v-icon small v-show="role!='parent'">fas fa-user</v-icon></v-list-item-icon><v-list-item-title>Parent</v-list-item-title></v-list-item>
			<!-- note that the 'Simulate another user role' menu is for "sandbox" instances; it's not the same as mimicking another user -->
			<v-list-item @click="show_simulate_role_menu" v-if="site_config.enable_simulate_role_menu=='yes'"><v-list-item-icon><v-icon small>fas fa-user-secret</v-icon></v-list-item-icon><v-list-item-title>Simulate another user role...</v-list-item-title></v-list-item>
			<v-list-item @click="mimic_another_user" v-if="enable_mimic_another_user"><v-list-item-icon><v-icon small>fas fa-user-secret</v-icon></v-list-item-icon><v-list-item-title>{{mimic_user_noun}}</v-list-item-title></v-list-item>
			<v-divider/>
			<v-list-item v-if="show_school_admin_btn" @click="school_admin_interface_showing=true"><v-list-item-icon><v-icon small>fas fa-screwdriver-wrench</v-icon></v-list-item-icon><v-list-item-title>School Leader Tools</v-list-item-title></v-list-item>
			<!-- <v-list-item @click="update_sis_data"><v-list-item-icon><v-icon small>fas fa-rotate</v-icon></v-list-item-icon><v-list-item-title>Update your class enrollments from Infinite Campus</v-list-item-title></v-list-item> -->
			<v-list-item v-if="false" @click="choose_beta_modes=true"><v-list-item-icon><v-icon small>fas fa-toggle-on</v-icon></v-list-item-icon><v-list-item-title>Choose <b style="font-weight:900"><span class="red--text text--darken-2">BETA FUNCTIONALITY</span></b></v-list-item-title></v-list-item>
			<!-- <v-list-item><v-list-item-icon><v-icon small>fas fa-calendar-alt</v-icon></v-list-item-icon><v-list-item-title>Academic Year {{academic_year_display}} <v-btn v-if="allow_academic_year_change" x-small color="secondary" style="margin-bottom:3px; margin-left:4px" @click="change_academic_year">Change</v-btn></v-list-item-title></v-list-item> -->
			<v-divider/>
			<v-list-item @click="change_password"><v-list-item-icon><v-icon small>fas fa-key</v-icon></v-list-item-icon><v-list-item-title>Change Password</v-list-item-title></v-list-item>
			<v-list-item @click="sign_out"><v-list-item-icon><v-icon small>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-title>Sign Out</v-list-item-title></v-list-item>
		</v-list>
	</v-menu>

	<v-dialog v-model="choose_beta_modes" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Beta Functionality</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="mb-2">Enable or disable the following {{ site_config.app_name }} <b style="font-weight:900"><span class="red--text text--darken-2">BETA FUNCTIONALITY</span></b>:</div>
			</v-card-text>

			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="choose_beta_modes=false">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<SchoolAdminInterface v-if="school_admin_interface_showing" @dialog_cancel="school_admin_interface_showing=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import SchoolAdminInterface from '../reports/SchoolAdminInterface.vue'

export default {
	components: { SchoolAdminInterface },
	data() { return {
		choose_beta_modes: false,
		choose_beta_modes_more_info: false,
		beta_options: Object.assign({}, this.$store.state.lst.beta_options),
		school_admin_interface_showing: false,
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters(['academic_year_display', 'user_is_principal_or_ap']),
		show_school_admin_btn() {
			// config option determines if this is ever available
			if (this.site_config.show_school_leader_tools == 'no') return false
			if (this.user_is_principal_or_ap) return true
			if (this.role == 'admin') return true
		},
		simulated_role: {
			get() { return this.$store.state.lst.simulated_role },
			set(val) { this.$store.commit('lst_set', ['simulated_role', val]) }
		},
		simulated_user: {
			get() { return this.$store.state.lst.simulated_user },
			set(val) { this.$store.commit('lst_set', ['simulated_user', val]) }
		},
		role() { return this.user_info.role },
		system_role() { return this.user_info.system_role },
		role_display() {
			if (this.role == 'parent') return 'Parent / Guardian'
			if (this.role == 'admin') return 'Site Admin'
			if (this.user_is_principal_or_ap) return 'School Leader'
			return U.capitalize_word(this.role)	// 'Staff' or 'Student'
		},
		allow_academic_year_change() {
			// show button to change AY if the user has rights to edit all LPs
			if (this.initialized < 0) return
			if (vapp.has_admin_right('lp.course.all')) return true
			return false
		},
		can_mimic_all_users() {
			if (vapp.has_admin_right('su')) return true
			if (vapp.has_admin_right('mimic_all')) return true
			return false
		},
		enable_mimic_another_user() {
			if (this.can_mimic_all_users) return true
			if (this.system_role == 'staff' && this.site_config.staff_can_mimic_users == 'yes') return true
			return false
		},
		mimic_user_noun() {
			if (this.can_mimic_all_users || this.user_is_principal_or_ap) return 'Mimic another user'
			else return 'Mimic one of your students'
		},
	},
	created() {
	},
	mounted() {
	},
	watch: {
	},
	methods: {
		update_beta_options() {
			console.log('setting beta options', this.beta_options)
			this.$store.commit('lst_set', ['beta_options', this.beta_options])
		},

		show_role(to_show) {
			// you can only adopt the admin role if your system_role is admin
			if (to_show == 'admin') {
				return this.system_role == 'admin'
			}

			if (to_show == 'staff') {
				return this.user_info.sis_identities.find(o=>{ return o.role == 'administrator' || o.role == 'teacher' }) || this.user_info.system_role == 'staff'
			}

			if (to_show == 'parent') {
				if (this.system_role == 'parent') return true
				if (this.site_config.show_parent_role_to_all_staff == 'yes') {
					if (this.system_role == 'staff' || this.system_role == 'admin') return true
				}
				return false
			}

			if (to_show == 'student') {
				return this.user_info.sis_identities.find(o=>{ return o.role == 'student' }) || this.user_info.system_role == 'student'
			}

			return false
		},

		change_role(new_role) {
			// for now we just set new role in local_storage and reload
			U.local_storage_set('district_portal_chosen_role_' + this.user_info.user_id, new_role)
			window.location.reload()
		},

		change_academic_year() {
			vapp.change_academic_year()
		},

		// TODO: fully implement this (from HenryConnects)
		mimic_another_user() {
			// superusers and users with mimic_all rights can mimic anyone
			if (this.can_mimic_all_users) {
				this.$prompt({
					text: 'Find the email address of the user you wish to mimic:',
					promptType: 'autocomplete',
					serviceName: 'email_search',
					initialValue: '',
					acceptText: 'Mimic User',
				}).then(email => {
					// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
					email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

					if (empty(email) || email.indexOf('@') == -1) {
						this.$alert('Please enter a valid user email.').then(x=>{this.mimic_another_user()})
						return
					}
					
					this.simulated_user = email
					this.reload()
					
				}).catch(n=>{console.log(n)}).finally(f=>{});

			} else if (this.user_is_principal_or_ap) {
				// principals/aps can mimic others from their schools; first get a list of them
				U.loading_start()
				// U.ajax('get_all_users_for_department', {user_id:this.user_info.user_id, department:['Impact Academy']}, result=>{
				U.ajax('get_all_users_for_department', {user_id:this.user_info.user_id, department:this.user_info.district_department}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error loading search results.')
						return
					}
					let arr = []
					for (let row of result.users) arr.push({value: row.email, text: `${row.first_name} ${row.last_name} (${row.email})`})
					this.$prompt({
						text: 'Choose a user from your school or department that you wish to mimic:',
						promptType: 'autocomplete',
						autocompleteItems: arr,
						initialValue: '',
						acceptText: 'Mimic User',
					}).then(email => {
						// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
						email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

						if (empty(email) || email.indexOf('@') == -1) {
							this.$alert('Please choose a user.').then(x=>{this.mimic_another_user()})
							return
						}
						
						this.simulated_user = email
						this.reload()
						
					}).catch(n=>{console.log(n)}).finally(f=>{});
				});

			} else if (this.system_role == 'staff') {
				// teachers can mimic their students; get a list of them
				let arr = []
				for (let cl of this.$store.state.sis_classes) {
					if (!empty(cl.students)) for (let sarr of cl.students) {
						if (!empty(sarr)) for (let s of sarr) {
							if (!empty(s) && !arr.find(x=>x.value == s.email)) {
								arr.push({value: s.email, text: `${s.givenName} ${s.familyName} (${s.email})`})
							}
						}
					}
				}
				console.log(arr.length)
				arr.sort((a,b) => U.natural_sort(a.text, b.text))
				this.$prompt({
					text: 'Choose a student that you wish to mimic:',
					promptType: 'autocomplete',
					autocompleteItems: arr,
					initialValue: '',
					acceptText: 'Mimic Student',
				}).then(email => {
					// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
					email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

					if (empty(email) || email.indexOf('@') == -1) {
						this.$alert('Please choose a student.').then(x=>{this.mimic_another_user()})
						return
					}
					
					this.simulated_user = email
					this.reload()
					
				}).catch(n=>{console.log(n)}).finally(f=>{});
			}
		},

		// TODO: fully implement this (from HenryConnects)
		update_sis_data() {
			this.$confirm({
				title: 'Update Class Enrollments',
				text: 'To update your class enrollments from Infinite Campus, click PROCEED below, then sign back in to HenryConnects.<div class="mt-2"><b>Note:</b> your class enrollments are automatically checked, and updated if necessary, the first time you sign in to HenryConnects each day, so the only reason you should need to run this “manual” update process is if you think your enrollments may have changed in the last 24 hours.</div>',
				acceptText: 'Proceed',
				dialogMaxWidth: 540,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(y => {
				this.$store.dispatch('update_sis_data')
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		show_simulate_role_menu() {
			this.$prompt({
				title: 'Simulate Another User Role',
				text: `By default, user accounts in this “sandbox” version of ${this.site_config.app_name} simulate an agency staff member with no courses specified in their SIS.<ul class="my-2"><li>Choose from the menu below, then click “SIMULATE ROLE” to simulate a different role.</li><li>Click “RETURN TO DEFAULT ROLE” to return to your default role.</li></ul>`,
				promptType: 'select',
				selectOptions: [{value:'', text: 'Choose a role…'}, 
					{value:'default', text:'My Demo Staff Account'},
					{value:'elena.espinoza@agency.edu', text:'Elena Espinoza (5th-grade teacher)'},
					{value:'mark.mcghee@agency.edu', text:'Mark Mcghee (8th-grade ELA teacher)'},
					{value:'hillary.hutchinson@agency.edu', text:'Hillary Hutchinson (HS social studies teacher)'},
					{value:'avery.park@agency.edu', text:'Avery Park (assistant principal)'},
					{value:'patpalmer@gmail.com', text:'Pat Palmer (parent)'},
				],
				dialogMaxWidth: 540,
				initialValue: '',
				acceptText: 'Choose Selected Role',
				acceptIcon: 'fas fa-user-secret',
				cancelText: 'Stay in Current Role',
				cancelIcon: 'fas fa-xmark',
			}).then(email => {
				if (email == 'default') email = ''
				vapp.simulate_sandbox_role(email)
				
			}).catch(n=>{
				
			})
		},

		reload() {
			// TODO: be a little more careful about what page we're sending the person to depending on the role?
			// for now, just reload if we're viewing a collection (since you may be viewing what the page looks like in the other role); otherwise send to /welcome
			if (window.location.toString().indexOf('/\bcollection\b/') > -1) {
				window.location.reload()
			} else {
				window.location = '/welcome'
			}
		},

		sign_out() {
			this.$store.dispatch('sign_out')
		},

		change_password() {
			vapp.change_password()
		},
	
	}
}
</script>

<style lang="scss">
.k-user-menu-show-large { display:flex; }
.k-user-menu-show-small { display:none; }
</style>
