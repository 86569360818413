<template>
	<div class="k-main-collection">
		<h2 class="k-page-title d-flex">
			<v-icon large color="primary" class="mr-4">fas fa-home-user</v-icon>
			<b>Welcome to {{ site_config.app_name }}!</b>
			<v-spacer />
		</h2>

		<div>
			<div class="k-parent-resources-announcements">
				<div class="k-parent-resources-student-list" :style="(user_info.role == 'parent') ? '' : 'opacity:0.5'">
					<div>
						<b style="font-size:18px">
							<v-icon color="primary" class="mr-2">fas fa-child</v-icon>Students In My Family:
						</b>
					</div>
					<ul v-if="child_count > 0 && user_info.role == 'parent'" class="mt-2">
						<li v-for="(child_data, child_email) in user_info.child_data">
							<a @click="child_selected(child_email)">
								{{ child_data.first_name }} {{ child_data.last_name }}
							</a>
						</li>
					</ul>
				</div>

				<div>
					<div class="fr-view" v-html="parent_announcements_content"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
	},
	data() {
		return {};
	},
	computed: {
		...mapState(['user_info', 'site_config', 'parent_announcements_content']),
		...mapGetters(['child_count']),
	},
	created() { },
	mounted() {
		// retrieve the Family View home page content, if necessary
		this.$store.dispatch('get_parent_announcements_content')
	},
	watch: {},
	methods: {
		child_selected(child_email) {
			// when a child's name is clicked, set child_email_showing and go to the courses page
			this.$store.commit('lst_set', ['child_email_showing', child_email])
			// Also, set default Course view to 'favorites'
			this.$store.commit('lst_set', ['index_view_flavor', 'favorites'])
			this.$router.push({ path: '/courses' })
		},
	},
};
</script>

<style lang="scss" scoped>
.k-parent-resources-announcements {
	// display:flex;
	background-color: #fff;
	border: 6px solid $secondary-color;
	border-radius: 10px;
	padding: 12px;
	// width: 60%;
	min-height: 200px;
	max-width: 1000px;
	margin: 0 auto;
	text-align: left;

	// align-items:flex-start;
	// flex:0 1 auto;
	.fr-view {
		font-size: 16px;
	}

	.k-parent-resources-student-list {
		// flex: 1 1 auto;
		float: right;
		width: 400px;
		margin: -12px -12px 18px 18px;
		// background-color: rgba(255, 240, 200, 0.5);
		// background-color:#fff;
		border-left: 6px solid $secondary-color;
		border-bottom: 6px solid $secondary-color;
		border-radius: 0 0 0 10px;
		padding: 12px;
		white-space: nowrap;
		text-align: left;

		ul {
			margin-left: -2px;
		}

		li {
			font-size: 16px;
		}

		a:hover {
			text-decoration: underline;
		}
	}
}
</style>
