// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class User_Course {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'course_code', '')
		sdp(this, data, 'course_sourcedIds', [])
		sdp(this, data, 'class_sourcedIds', [])
		sdp(this, data, 'titles', [])
		sdp(this, data, 'format', 'T', ['T', 'B'])
		sdp(this, data, 'title_codes', [])
		sdp(this, data, 'classCodes', [])
		sdp(this, data, 'school_sourcedIds', [])
		sdp(this, data, 'term_sourcedIds', [])
		sdp(this, data, 'students', [])
		sdp(this, data, 'teachers', [])

		// use the first titles entry as the title (they should all be the same)
		this.title = this.titles[0]
	}

	section_title(index, student_count) {
		let title_code = this.title_codes[index]

		// construct a section title from a title_code
		// title_code has three vals: course_code (which we ignore), "instruction type" (see below), and section number
		// for instruction type 0 (general), we don't add any parenthesized description
		// TODO: move this to server code
		let instruction_type = ''
		if (title_code[1] == 1) instruction_type = ' (Rem)'
		else if (title_code[1] == 2) instruction_type = ' (Gifted)'
		else if (title_code[1] == 3) instruction_type = ' (Dist)'
		else if (title_code[1] >= 4 && title_code[1] <= 6) instruction_type = sr(' (Lab [$1])', title_code[1])
		else if (title_code[1] == 7) instruction_type = ' (Work-Based Learning)'
		else if (title_code[1] >= 8 && title_code[1] <= 9) instruction_type = sr(' (Gen. Ed. Course [$1])', title_code[1])

		let t = 'Section ' + title_code[2] + instruction_type

		if (student_count === true) {
			t += sr(' - $1 $2', this.students[index], U.ps('student', this.students[index]))
		}

		return t
	}
}
window.User_Course = User_Course

class Google_Classroom_Class {
	constructor(data) {
		this.type = 'google_classroom'
		if (empty(data)) data = {}
		sdp(this, data, 'title', '')
		sdp(this, data, 'url', '')
		sdp(this, data, 'id', '')
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'hidden', false)
	}
}
window.Google_Classroom_Class = Google_Classroom_Class

class Schoology_Class {
	constructor(data) {
		this.type = 'schoology'
		if (empty(data)) data = {}
		sdp(this, data, 'title', '')
		sdp(this, data, 'url', '')
		sdp(this, data, 'id', '')
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'hidden', false)
	}
}
window.Schoology_Class = Schoology_Class
