// Part of the SPARKL educational activity system, Copyright 2020 by Pepper Williams

// fn for returning the "value" for a grade -- what we use to order the grades
U.grade_value = function(grade) {
	return vapp.$store.state.grades.findIndex(g=>g==grade)
}

// fn for determining if the given object o, which must include a grade_low and grade_high parameter, "includes" the given grade
U.grades_include = function(o, grade_low, grade_high, flag) {
	// if object doesn't have a grade_low or grade_high, return false
	if (empty(o.grade_low) || empty(o.grade_high)) return false

	// try to get the index of the given grade_low and o's grade_low and grade_high from the store's grades array; if any not found return false
	let grade_low_index = U.grade_value(grade_low)
	let o_grade_low_index = U.grade_value(o.grade_low)
	let o_grade_high_index = U.grade_value(o.grade_high)

	if (grade_low_index == -1) return false
	if (o_grade_low_index == -1) return false
	if (o_grade_high_index == -1) return false

	// grade_high is optional; if not provided, base just on grade_low
	if (!grade_high) {
		return (grade_low_index >= o_grade_low_index && grade_low_index <= o_grade_high_index)
	} else {
		// if we have a grade_low and a grade_high...
		let grade_high_index = U.grade_value(grade_high)
		if (empty(grade_high_index)) return false

		// if we're told that the object values have to *fully include* both grade_low *and* grade_high...
		if (flag == 'fully_include') {
			// return false if o_grade_lo is < grade_lo
			if (grade_low_index < o_grade_low_index) return false
			// return false if o_grade_hi is > grade_hi
			if (grade_high_index > o_grade_high_index) return false
			// otherwise both are in range, so return true
			return true

		} else {
			// return false if grade_high is < o_grade_low
			if (grade_high_index < o_grade_low_index) return false
			// return false if grade_low is > o_grade_high
			if (grade_low_index > o_grade_high_index) return false
			// else return true
			return true
		}
	}
}

// fn for consistently coloring things
U.subject_tile_css = function(o) {
	if (o.color) {
		return 'k-list-color-' + o.color
	}
	if (o.subject && vapp.$store.state.subjects[o.subject]) {
		return 'k-framework-color-' + vapp.$store.state.subjects[o.subject].color
	}

	if (!empty(o.title)) {
		return vapp.color_from_string(o.title)
	}

	return vapp.color_from_string(o + '')
}



class Learning_Progression {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'lp_id', 0)
		sdp(this, data, 'agency_sanctioned', false)
		sdp(this, data, 'updated_at', '')
		sdp(this, data, 'case_identifier', '')
		sdp(this, data, 'course_code', '')		// called a 'course_code' for legacy reasons; used for all collections, be they courses, repos, or 'my' collections
		sdp(this, data, 'title', '')
		sdp(this, data, 'subject_case_identifier', '')
		sdp(this, data, 'course_case_identifier', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'grade_low', '')
		sdp(this, data, 'grade_high', '')
		sdp(this, data, 'subject', '')
		sdp(this, data, 'color', '')
		sdp(this, data, 'active', 'yes')	// when set to 'no', only admins can see it
		sdp(this, data, 'featured', 0)	// for resource collections: if 0, not featured; if > 0, it's a timestamp, and is shown at the top of the index page with most recently-featured collections first
		sdp(this, data, 'sequence', 0)	// for resource collections: used to order collections
		sdp(this, data, 'owner_id', 0)

		sdp(this, data, 'subscription_code', '')	// allows users to share/subscribe to 'my' collections

		// lp_layout and collection_type added in 11/2023;
		// for legacy purposes, if values are not provided in incoming data, these default to 'tree' and 'repo' if course_code starts with 'C', or 'map' and 'course' otherwise
		if (!empty(data.lp_layout)) {
			sdp(this, data, 'lp_layout', 'tree', ['tree', 'map'])	// 'tree' = "content repository" -- content shown in a tree structure; 'map' = "curriculum map" -- units shown in a table
		} else if (data.course_code && data.course_code[0] == 'C') {
			this.lp_layout = 'tree'
		} else {
			this.lp_layout = 'map'
		}

		if (!empty(data.collection_type)) {
			sdp(this, data, 'collection_type', 'my', ['my', 'course', 'repo', 'pd'])
		
		// deal with some legacy Henry/Inspire things...
		} else if (data.course_code && data.course_code*1 > 90000 && !data.course_code.includes('.')) {
			this.collection_type = 'pd'
		} else if (data.course_code && data.course_code[0] == 'C') {
			this.collection_type = 'repo'
		
		// and by default collection_type is 'course'
		} else {
			this.collection_type = 'course'
		}
		// console.log(`${this.collection_type} - ${data.course_code}: ${data.title}`)

		// cmap_specified is sent in by get_all_courses, and tells us whether or not the curriculum map has been specified at all yet (separate from whether the course is marked as active)
		sdp(this, data, 'cmap_specified', false)

		// normalize subject capitalization, except for CTAE
		if (this.subject != 'CTAE') this.subject = U.capitalize_words(this.subject)

		// used in Collection.vue; removed before saving
		sdp(this, data, 'full_description_showing', false)
		sdp(this, data, 'full_description_height', -1)
		sdp(this, data, 'shifted_for_lesson', false)
		
		this.terms = []
		this.units = []
		if (!empty(data.terms)) {
			for (let t of data.terms) {
				// legacy LP's have units saved in terms; deal with those here
				if (!empty(t.units)) {
					// assume these terms are quarters -- 9 weeks
					this.terms.push({
						title: t.title,
						duration: '9',
					})
					for (let u of t.units) {
						this.units.push(new LP_Unit(u))
					}
				} else {
					// "new" terms just have titles and duration; assume 9 weeks if not given
					let term = {}
					sdp(term, t, 'title', '')
					sdp(term, t, 'duration', '9')
					this.terms.push(term)
				}
			}
		}

		// now LP units
		if (!empty(data.units)) {
			for (let u of data.units) {
				this.units.push(new LP_Unit(u))
			}
		}

		// flags for whether or not to use terms, unit numbers, and unit time intervals
		sdp(this, data, 'use_terms', false)
		sdp(this, data, 'use_unit_numbers', true)
		sdp(this, data, 'use_unit_intervals', false)

		// label to use for the collection of units, if use_terms is false
		sdp(this, data, 'unit_collection_title', 'Curriculum Map')

		// course-wide resources and assessment resources for the course; order by ids if we get them
		this.course_wide_resources = []
		if (!empty(data.course_wide_resources)) {
			if (!empty(data.course_wide_resources_ids)) {
				for (let id of data.course_wide_resources_ids) {
					let item = data.course_wide_resources.find(x=>x.resource_id == id)
					if (!item) {
						console.log('error ordering course_wide_resources array')
					} else {
						this.course_wide_resources.push(new Resource(item))
					}
				}
			} else {
				for (let r of data.course_wide_resources) this.course_wide_resources.push(new Resource(r))
			}
		}
		this.assessment_resources_general = []
		if (!empty(data.assessment_resources_general)) {
			if (!empty(data.assessment_resources_general_ids)) {
				for (let id of data.assessment_resources_general_ids) {
					let item = data.assessment_resources_general.find(x=>x.resource_id == id)
					if (!item) {
						console.log('error ordering assessment_resources_general array')
					} else {
						this.assessment_resources_general.push(new Resource(item))
					}
				}
			} else {
				for (let r of data.assessment_resources_general) this.assessment_resources_general.push(new Resource(r))
			}
		}
		this.assessment_resources_annotated_examples = []
		if (!empty(data.assessment_resources_annotated_examples)) {
			if (!empty(data.assessment_resources_annotated_examples_ids)) {
				for (let id of data.assessment_resources_annotated_examples_ids) {
					let item = data.assessment_resources_annotated_examples.find(x=>x.resource_id == id)
					if (!item) {
						console.log('error ordering assessment_resources_annotated_examples array')
					} else {
						this.assessment_resources_annotated_examples.push(new Resource(item))
					}
				}
			} else {
				for (let r of data.assessment_resources_annotated_examples) this.assessment_resources_annotated_examples.push(new Resource(r))
			}
		}
		this.assessment_resources_sample_items = []
		if (!empty(data.assessment_resources_sample_items)) {
			if (!empty(data.assessment_resources_sample_items_ids)) {
				for (let id of data.assessment_resources_sample_items_ids) {
					let item = data.assessment_resources_sample_items.find(x=>x.resource_id == id)
					if (!item) {
						console.log('error ordering assessment_resources_sample_items array')
					} else {
						this.assessment_resources_sample_items.push(new Resource(item))
					}
				}
			} else {
				for (let r of data.assessment_resources_sample_items) this.assessment_resources_sample_items.push(new Resource(r))
			}
		}

		// resource_ids for resource collection(s) -- e.g. resources loaded via thin common cartridges
		sdp(this, data, 'resource_collection_ids', [])

		// resource_collections may also be sent to the client; but we don't save these
		sdp(this, data, 'resource_collections', [])
		// Added 12/2023 for professional developments
		sdp(this, data, 'pd_resources', [])

		// a resource_collection is a special resource, with:
		// resource_id: a unique id (e.g. the manifest identifier from the common cartridge file)
		// description: title of the collection (e.g. "HMH Science 3rd Grade")
		// type: 'resource_collection'
		// collection_json: a big json object (with property names shortened for loading efficiency) specifying a tree-folder structure and resources.
		// Each item in collection_json may contain:
		//		t: title
		//		c: array of child items
		//		f: for an array of child items, the identiFier (unique within the collection) for the "folder" (from the common cartridge file)
		//		r: resource_id of a resource (the resource will have its own table entry in the resources table)
		//		i: 1 if the resource is teacher-facing only
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		delete o.resource_collections
		delete o.cmap_specified

		// remove these things that are used in Collection.vue
		delete o.full_description_showing
		delete o.full_description_height
		delete o.shifted_for_lesson

		// SF: owner_id is injected into lp.data in get_learning_progression service
		delete o.owner_id

		o.units = []
		for (let unit of this.units) {
			o.units.push(unit.copy_for_save())
		}

		// remove full resource objects for course_wide and assessment resources; attach resource_ids instead
		delete o.course_wide_resources
		delete o.assessment_resources_general
		delete o.assessment_resources_annotated_examples
		delete o.assessment_resources_sample_items
		o.course_wide_resources_ids = []
		o.assessment_resources_general_ids = []
		o.assessment_resources_annotated_examples_ids = []
		o.assessment_resources_sample_items_ids = []
		for (let r of this.course_wide_resources) o.course_wide_resources_ids.push(r.resource_id)
		for (let r of this.assessment_resources_general) o.assessment_resources_general_ids.push(r.resource_id)
		for (let r of this.assessment_resources_annotated_examples) o.assessment_resources_annotated_examples_ids.push(r.resource_id)
		for (let r of this.assessment_resources_sample_items) o.assessment_resources_sample_items_ids.push(r.resource_id)

		return o
	}

	vue_route(unit_route) {
		if (empty(unit_route)) unit_route = 0
		
		return `/collection/${this.course_code}/${unit_route}`
		// if (this.collection_type == 'course') return `/course/${this.course_code}/${unit_route}`
		// if (this.collection_type == 'repo') return `/repo/${this.course_code}/${unit_route}`
		// if (this.collection_type == 'my') return `/mycollection/${this.course_code}/${unit_route}`
		return '/welcome'
	}

	user_is_lp_admin() {
		if (vapp.has_admin_right('lp.course.' + this.course_code)) return true

		// check based on subject
		if (vapp.has_admin_right('lp.subject.' + this.subject)) return true

		// check based on level
		if (U.grades_include(this, 'K') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '1') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '2') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '3') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '4') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '5') && vapp.has_admin_right('lp.level.Elementary')) return true
		if (U.grades_include(this, '6') && vapp.has_admin_right('lp.level.Middle School')) return true
		if (U.grades_include(this, '7') && vapp.has_admin_right('lp.level.Middle School')) return true
		if (U.grades_include(this, '8') && vapp.has_admin_right('lp.level.Middle School')) return true
		if (U.grades_include(this, '9') && vapp.has_admin_right('lp.level.High School')) return true
		if (U.grades_include(this, '10') && vapp.has_admin_right('lp.level.High School')) return true
		if (U.grades_include(this, '11') && vapp.has_admin_right('lp.level.High School')) return true
		if (U.grades_include(this, '12') && vapp.has_admin_right('lp.level.High School')) return true

		// if we get to here, not an admin
		return false
	}

	user_can_view_lp() {
		// console.log('---- user_can_view_lp - ' + this.course_code)

		// admins can obviously view
		if (this.user_is_lp_admin()) return true

		if (vapp.has_admin_right('view_lp.course.' + this.course_code)) return true

		// check based on subject
		if (vapp.has_admin_right('view_lp.subject.' + this.subject)) return true

		// check based on level
		if (U.grades_include(this, 'K') && vapp.has_admin_right('view_lp.level.Elementary')) return true
		if (U.grades_include(this, '1') && vapp.has_admin_right('view_lp.level.Elementary')) return true
		if (U.grades_include(this, '2') && vapp.has_admin_right('view_lp.level.Elementary')) return true
		if (U.grades_include(this, '3') && vapp.has_admin_right('view_lp.level.Elementary')) return true
		if (U.grades_include(this, '4') && vapp.has_admin_right('view_lp.level.Elementary')) return true
		if (U.grades_include(this, '5') && vapp.has_admin_right('view_lp.level.Elementary')) return true
		if (U.grades_include(this, '6') && vapp.has_admin_right('view_lp.level.Middle School')) return true
		if (U.grades_include(this, '7') && vapp.has_admin_right('view_lp.level.Middle School')) return true
		if (U.grades_include(this, '8') && vapp.has_admin_right('view_lp.level.Middle School')) return true
		if (U.grades_include(this, '9') && vapp.has_admin_right('view_lp.level.High School')) return true
		if (U.grades_include(this, '10') && vapp.has_admin_right('view_lp.level.High School')) return true
		if (U.grades_include(this, '11') && vapp.has_admin_right('view_lp.level.High School')) return true
		if (U.grades_include(this, '12') && vapp.has_admin_right('view_lp.level.High School')) return true

		// if we get to here, can't view
		return false
	}

	user_is_subscribed_to_lp() {
		// you can only be subscribed to 'my' collections
		if (this.collection_type != 'my') return false

		// the collection owner owns the collection; they aren't subscribed
		if (this.owner_id == vapp.user_info.user_id) return false

		// return true if the user has a specific right to edit or view the collection
		if (vapp.has_specific_admin_right('lp.course.' + this.course_code)) return true
		if (vapp.has_specific_admin_right('view_lp.course.' + this.course_code)) return true
		return false
	}

	// this fn currently (12/2023) tells us if the lp was imported from Henry, so we can do some hacky things for demo purposes
	lp_source() {
		if (this.course_code == Math.round(this.course_code*1)) return 'henry'
		return false
	}

	default_collection_id_for_collection_asset_mapping(user_id) {
		// return the user's default collection lp_id -- maps on to fn in learning_progression.php
		if (empty(user_id)) user_id = vapp.$store.state.user_info.user_id
		return `default_collection_${user_id}`
	}

}
window.Learning_Progression = Learning_Progression

// Legacy; no longer used
// class LP_Term {
// 	constructor(data) {
// 		if (empty(data)) data = {}
// 		sdp(this, data, 'title', '')
//
// 		this.units = []
// 		if (!empty(data.units)) {
// 			for (let u of data.units) {
// 				this.units.push(new LP_Unit(u))
// 			}
// 		}
// 	}
//
// 	copy_for_save() {
// 		let o = $.extend(true, {}, this)
// 		o.units = []
// 		for (let unit of this.units) {
// 			o.units.push(unit.copy_for_save())
// 		}
// 		return o
// 	}
// }
// window.LP_Term = LP_Term

class LP_Unit {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'lp_unit_id', 0)

		// note: "shadow" units are currently not ever saved (though we might save them in the future)
		sdp(this, data, 'shadows_lp_id', 0)
		sdp(this, data, 'shadows_lp_unit_id', 0)
		sdp(this, data, 'shadow_unit_owner_id', 0)

		// for courses, we originally (and unfortunately) coded "Unit 1" in "title" and "The Civil War" in "description"; whereas for repos we coded the actual title in title.
		// as of 11/2023, we're re-coding so that the title is always in the title, and the number is in display_number
		// in course units, title -> display_number and description -> title
		if (data.display_number === undefined && !empty(data.description)) {
			data.display_number = data.title
			data.title = data.description
		}

		sdp(this, data, 'title', '')
		sdp(this, data, 'display_number', '')
		sdp(this, data, 'duration', '0')	// as of June 2023, durations can be strings

		sdp(this, data, 'text', '')

		// used in Collection.vue; removed before saving
		sdp(this, data, 'full_description_showing', false)
		sdp(this, data, 'full_description_height', -1)

		this.standards = []
		if (!empty(data.standards)) {
			for (let standard of data.standards) {
				this.standards.push(new LP_Unit_Standard(standard))
			}
		}

		// this structure marks parts (e.g. "folders") of TCC resource collections that are to be highlighted for this unit
		// the structure is an object, with one member per collection (identified by the collection's resource_id),
		// which includes an array of resource "folder" id's (or potentially resource_ids from actual resources) that are to be included
		// This is legacy data from Henry; in cureum we transfer the tcc data to the course collection units in create_folders_for_legacy_courses
		this.resource_collection_inclusions = {}
		if (!empty(data.resource_collection_inclusions)) {
			for (let rcid in data.resource_collection_inclusions) {
				this.resource_collection_inclusions[rcid] = data.resource_collection_inclusions[rcid].concat([])
			}
		}

		// this structure is for holding the tree structure for resources in the unit
		if (!empty(data.resource_tree)) {
			// this.resource_tree = $.extend(true, {}, data.resource_tree)
			this.resource_tree = {folders: [], folder_assignments:[]}
			for (let folder of data.resource_tree.folders) {
				let f = {}
				sdp(f, folder, 'folder_id', '')
				sdp(f, folder, 'title', '')
				sdp(f, folder, 'color', '')
				sdp(f, folder, 'parent_folder_id', '')
				sdp(f, folder, 'seq', 0)
				this.resource_tree.folders.push(f)
			}
			let placed_resource_ids = []
			for (let folder_assignment of data.resource_tree.folder_assignments) {
				// only place each item once; bugs could creep in that would lead to spurious folder_assignments
				if (placed_resource_ids.includes(folder_assignment.resource_id)) {
					console.log('spurious folder_assignment ignored', folder_assignment)
					continue
				}
				let fa = {}
				sdp(fa, folder_assignment, 'type', 'resource', ['resource', 'lesson', 'activity'])	// default value needs to be 'resource' for historical reasons; 'activity' no longer used as of 11/2023
				sdp(fa, folder_assignment, 'resource_id', '')	// note that this could instead be a lesson_id; named resource_id for historical reasons
				sdp(fa, folder_assignment, 'parent_folder_id', '')
				sdp(fa, folder_assignment, 'seq', 0)
				this.resource_tree.folder_assignments.push(fa)
				placed_resource_ids.push(folder_assignment.resource_id)
			}
		} else {
			this.resource_tree = {
				folders: [{
					folder_id: 'top',
					title: '',
					parent_folder_id: '',
					seq: 0,
				}],
				folder_assignments: []	
			}
		}

		// individual resources associated with the unit; put them in the order matching resource_ids if we have them; otherwise use the original order
		this.resources = []
		if (!empty(data.resources)) {
			if (!empty(data.resource_ids)) {
				for (let resource_id of data.resource_ids) {
					let r = data.resources.find(x=>x.resource_id==resource_id)
					if (!r) {
						// shouldn't happen, but just in case...
						console.log('couldn’t find resource for resource_id ' + resource_id)
					} else if (this.resources.find(x=>x.resource_id == r.resource_id)) {
						console.log(`duplicate resource_id ${r.resource_id} in unit ${this.lp_unit_id}`)
					} else {
						r = new Resource(r)
						this.resources.push(r)
					}
				}
			} else {
				for (let r of data.resources) {
					this.resources.push(new Resource(r))
				}
			}
		}

		// lessons associated with the unit
		this.lessons = []
		if (!empty(data.lessons)) {
			if (!empty(data.lesson_ids)) {
				for (let lesson_id of data.lesson_ids) {
					let r = data.lessons.find(x=>x.lesson_id==lesson_id)
					if (!r) {
						// shouldn't happen, but just in case...
						console.log('couldn’t find lesson for lesson_id ' + lesson_id)
					} else if (this.lessons.find(x=>x.lesson_id == r.lesson_id)) {
						console.log(`duplicate lesson_id ${r.lesson_id} in unit ${this.lp_unit_id}`)
					} else {
						r = new Lesson(r)
						this.lessons.push(r)
					}
				}
			} else {
				for (let r of data.lessons) {
					this.lessons.push(new Lesson(r))
				}
			}
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		// remove these things that are used in Collection.vue
		delete o.full_description_showing
		delete o.full_description_height

		// remove full resource objects; attach resource_ids instead
		delete o.resources
		o.resource_ids = []
		for (let r of this.resources) {
			// skip 'placeholder' resources from CollectionResourceFolder
			if (r.placeholder === true) continue
			
			o.resource_ids.push(r.resource_id)
		}

		// remove full lesson objects; attach lesson_ids instead
		delete o.lessons
		o.lesson_ids = []
		for (let r of this.lessons) {
			o.lesson_ids.push(r.lesson_id)
		}

		for (let i = 0; i < this.standards.length; ++i) {
			o.standards[i] = o.standards[i].copy_for_save()
		}

		// remove resource_tree if there aren't any folder_assignments or folders (keeping in mind that there will always be a 'top' folder)
		if (o.resource_tree.folder_assignments.length == 0 && o.resource_tree.folders.length < 2) {
			delete o.resource_tree
		} else {
			// else save them; code here is in case we later need to exclude some temporary folders/folder assignments
			let resource_tree = { folder_assignments: [], folders: [] }
			for (let folder of o.resource_tree.folders) {
				resource_tree.folders.push(folder)
			}
			for (let folder_assignment of o.resource_tree.folder_assignments) {
				resource_tree.folder_assignments.push(folder_assignment)
			}
			o.resource_tree = resource_tree
		}

		return o
	}

	get_max_folder_sequence(folder_id) {
		// look through folder_assignments and folder, and get the max seq value for items connected to the given folder_id
		let last_seq = -1
		for (let fa of this.resource_tree.folder_assignments) {
			if (fa.parent_folder_id == folder_id && fa.seq > last_seq) {
				last_seq = fa.seq
			}
		}
		for (let folder of this.resource_tree.folders) {
			if (folder.parent_folder_id == folder_id && folder.seq > last_seq) {
				last_seq = folder.seq
			}
		}
		return last_seq
	}

	create_resource_folder(parent_folder_id, folder_title, folder_items, folder_color, folder_id) {
		// create the folder
		if (empty(folder_id)) folder_id = U.new_uuid()
		this.resource_tree.folders.push({
			folder_id: folder_id,
			title: folder_title,
			color: folder_color ?? '',
			parent_folder_id: parent_folder_id,
			seq: this.get_max_folder_sequence(parent_folder_id) + 1,
		})
		// then add all folder_items to the folder
		for (let i = 0; i < folder_items.length; ++i) {
			let item = folder_items[i]
			this.resource_tree.folder_assignments.push({
				type: (item.lesson_id) ? 'lesson' : 'resource',
				resource_id: (item.lesson_id) ? item.lesson_id : item.resource_id,
				parent_folder_id: folder_id,
				seq: i,
			})
		}

		// note that this just adds the folder; the caller is responsible for adding the resources
	}
}
window.LP_Unit = LP_Unit

// a LP standard has a case identifier, a case_item, associated text, and any number of associated resources
class LP_Unit_Standard {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'text', '')
		sdp(this, data, 'identifier', '')
		this.case_item = new CASE_Item(data.case_item)
		this.resources = []
		if (!empty(data.resources)) {
			for (let r of data.resources) {
				this.resources.push(new Resource(r))
			}
		}
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)

		// remove full resource objects; attach resource_ids instead
		delete o.resources
		o.resource_ids = []
		for (let r of this.resources) {
			o.resource_ids.push(r.resource_id)
		}

		return o
	}
}
window.LP_Unit_Standard = LP_Unit_Standard

class CASE_Item {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'case_item_id', 0)
		sdp(this, data, 'framework_identifier', '')
		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')

		sdp(this, data, 'humanCodingScheme', '')
		sdp(this, data, 'fullStatement', '')

		// recode CASE educationLevel to grade_low/grade_high for convenience
		sdp(this, data, 'grade_low', '')
		sdp(this, data, 'grade_high', '')
		if (empty(data.grade_low) && !empty(data.educationLevel)) {
			this.code_grades_from_education_level(data.educationLevel)
		}

		// rather than using separate CASE associations, we'll just use recursive arguments to code is_child_of relationships
		// however, note that for curriculum maps, we don't currently "nest" CASE items in any case
		this.children = []
		if (!empty(data.children)) {
			for (let c of data.children) {
				this.children.push(new CASE_Item(c))
			}
		}
	}

	code_grades_from_education_level(el) {
		let gvl = 10000
		let gvh = -10000
		for (let g of el) {
			if (g == 'KG') g = 'K'
			else if (!isNaN(g*1)) g = g*1+''
			else {
				console.log('unknown grade: ' + g)
				continue
			}
			let gv = U.grade_value(g)
			if (gv < gvl) {
				gvl = gv
				this.grade_low = g
			}
			if (gv > gvh) {
				gvh = gv
				this.grade_high = g
			}
		}
	}
}
window.CASE_Item = CASE_Item
