<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<div class="k-page-wrapper">
		<h2 class="k-page-title">
			<v-icon @click="return_to_admin_main" class="k-page-title-btn k-elevated">fas fa-home</v-icon>
			Home Page Content
		</h2>

		<div style="width:860px" class="mx-auto my-4">
			<v-tabs v-model="tab" bg-color="primary">
				<v-tab key="home_page">Home Page</v-tab>
				<v-tab key="parent_announcements">Parent Announcements</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item key="home_page">
					<froala-wrapper :config="editor_config" v-model="home_page_content" />
				</v-tab-item>
				<v-tab-item key="parent_announcements">
					<froala-wrapper :config="editor_config" v-model="parent_announcements_content" />
				</v-tab-item>
			</v-tabs-items>
			<div class="text-right mt-4">
				<v-btn color="primary" @click="save_home_page_content">
					<v-icon small class="mr-2">fas fa-save</v-icon>
					Save Changes
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

const edit_target = Object.freeze({"HOME_PAGE": 0, "PARENT_ANNOUNCEMENTS": 1});

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		page_to_edit: edit_target.HOME_PAGE,
		tab: 'home_page'
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		editor_config() {
			return U.get_froala_config({
			})
		},
		home_page_content: {
			get() { return this.$store.state.home_page_content },
			set(val) { this.$store.commit('set', ['home_page_content', val]) }
		},
		parent_announcements_content: {
			get() { return this.$store.state.parent_announcements_content },
			set(val) { this.$store.commit('set', ['parent_announcements_content', val]) }
		},
		is_editing_parent_announcements() {
			return this.page_to_edit === edit_target.PARENT_ANNOUNCEMENTS
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// retrieve the home_page_content, if necessary
		this.$store.dispatch('get_home_page_content').then(() => this.$store.dispatch('get_parent_announcements_content'))
	},
	methods: {
		save_home_page_content() {
			let payload = {
				user_id: this.$store.state.user_info.user_id,
				home_page_content: this.home_page_content,
				parent_announcements_content: this.parent_announcements_content,
			}

			U.ajax('admin_save_home_content', payload, result=>{
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to save the home page content.')
					return
				}
				this.$inform('Home page content saved.')
			});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		},

		set_page_to_edit() {
			this.page_to_edit = !is_editing_parent_announcements ? edit_target.PARENT_ANNOUNCEMENTS : edit_target.HOME_PAGE
		}
	}
}
</script>

<style lang="scss">
</style>
