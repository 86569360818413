<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title d-flex align-center">
		<v-icon @click="return_to_admin_main" class="k-page-title-btn k-elevated">fas fa-cog</v-icon>
		<div>Admin User Privileges</div>
		<v-spacer/>
		<v-btn v-if="site_config.enable_new_user_creation_from_admin_users=='yes'" small color="primary" @click="user_being_created=true">TEMP: Add New User Record</v-btn>
		<v-btn class="ml-3" @click="load_admin_user_list">Load / Reload Admin User List</v-btn>
	</h2>

	<div v-if="admin_users" style="clear:both">
		<div class="py-4 d-flex">
			<v-text-field
				v-model="search"
				prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
				label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
			></v-text-field>
			<v-btn class="ml-8" color="primary" @click="new_user_clicked"><v-icon small class="mr-2">fas fa-plus</v-icon> Add New Admin User</v-btn>
		</div>

		<v-data-table light
			:headers="headers"
			:items="rows"
			:sort-by="['su','user']"
			:sort-desc="[true,false]"
			:must-sort="true"
			:custom-filter="table_search_filter"
			:search="search"
			hide-default-footer
			:footer-props="footer_options"
			:items-per-page="-1"
			class="k-admin-users-table"
		>
			<template v-slot:item="{ item }"><tr>
				<td><v-btn icon x-small class="mr-2" @click="edit_user(item.identifier)"><v-icon>fas fa-edit</v-icon></v-btn><span v-html="item.user"></span></td>
				<td><v-icon small v-if="item.su">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.pd">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.pg">fas fa-check</v-icon></td>
				<td><v-icon small v-if="item.mimic_all">fas fa-check</v-icon></td>
				<td style="width:33%; border-left:1px solid #ccc"><v-chip v-for="lp in item.lps" :key="lp.code" x-small class="mr-1">{{lp.text}}</v-chip></td>
				<td style="width:33%; border-left:1px solid #ccc"><v-chip v-for="view_lp in item.view_lps" :key="view_lp.code" x-small class="mr-1">{{view_lp.text}}</v-chip></td>
			</tr></template>
		</v-data-table>
	</div>
	<div v-else class="mt-12 mb-3"><i>This area allows you to manage which {{ site_config.app_name }} users have administrative access to do such things as authoring/edit Courses. To get started, click the “LOAD / RELOAD ADMIN USER LIST” button above.</i></div>
	<AdminUserEdit v-if="user_being_edited" :admin_user="user_being_edited" @editor_cancel="user_being_edited=null" @rights_edited="rights_edited"></AdminUserEdit>
	<AdminNewUser v-if="user_being_created" @editor_cancel="user_being_created=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminUserEdit from './AdminUserEdit'
import AdminNewUser from './AdminNewUser'

export default {
	components: { AdminUserEdit, AdminNewUser },
	props: {
	},
	data() { return {
		admin_users: null,
		headers: [
			{ text: 'User', align: 'left', sortable: true, value:'user' },
			{ text: 'SU', align: 'left', sortable: true, value:'su' },
			{ text: 'PD', align: 'left', sortable: true, value:'pd' },
			{ text: 'P/G', align: 'left', sortable: true, value:'pg' },
			{ text: 'Mimic All', align: 'left', sortable: true, value:'mimic_all' },
			{ text: 'Edit Courses', align: 'center', sortable: false, value:'lp' },
			{ text: 'View Courses', align: 'center', sortable: false, value:'view_lp' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		user_being_edited: null,
		user_being_created: false,
	}},
	computed: {
		...mapState(['user_info', 'all_courses_loaded', 'all_courses', 'site_config']),
		rows() {
			let arr = []

			for (let identifier in this.admin_users) {
				let u = this.admin_users[identifier]
				let o = {
					identifier: identifier,
					user: sr('$1, $2 ($3)', u.last_name, u.first_name, u.email),
				}
				o.user_lc = o.user.toLowerCase()

				// NOTE THAT CODE BELOW IS LARGELY MIRRORED IN AdminUserEdit.vue
				// if user has su rights, they can do everything else too
				if (u.rights.findIndex(s=>s=='su') > -1) {
					o.su = true
					o.pd = true
					o.pg = true
					o.lps = [
						{ code: 'lp.level.all', text: 'ALL COURSES', text_lc: 'all courses' },
					]
					o.view_lps = [
						{ code: 'view_lp.level.all', text: 'ALL COURSES', text_lc: 'all courses' },
					]

				} else {
					// else build up list from rights
					o.lps = []
					o.su = false
					o.pd = false
					o.pg = false
					o.view_lps = []
					for (var r of u.rights) {
						// Professional Development
						if (r == 'pd') o.pd = true

						// Parent/Guardian
						else if (r == 'pg') o.pg = true

						// Mimic All Users
						else if (r == 'mimic_all') o.mimic_all = true
						// Edit courses
						else if (r.search(/^lp\.(.*?)\.(.*)/) > -1) {
							let l1 = RegExp.$1
							let l2 = RegExp.$2
							let text
							if (l2 == 'all') {
								text = 'ALL COURSES'
							} else if (l1 == 'level') {
								text = 'ALL ' + l2
							} else if (l1 == 'subject') {
								text = 'ALL ' + l2
							} else {
								// lp.course.1355
								let course = this.all_courses.find(x=>x.course_code == l2)
								if (!empty(course)) {
									text = course.title
								} else {
									text = 'Course ID ' + l2
								}
							}
							o.lps.push({ code: r, text: text, text_lc: text.toLowerCase() })
						}

						// View courses
						else if (r.search(/^view_lp\.(.*?)\.(.*)/) > -1) {
							let l1 = RegExp.$1
							let l2 = RegExp.$2
							let text
							if (l2 == 'all') {
								text = 'ALL COURSES'
							} else if (l1 == 'level') {
								text = 'ALL ' + l2
							} else if (l1 == 'subject') {
								text = 'ALL ' + l2
							} else {
								// view_lp.course.1355
								let course = this.all_courses.find(x=>x.course_code == l2)
								if (!empty(course)) {
									text = course.title
								} else {
									text = 'Course ID ' + l2
								}
							}
							o.view_lps.push({ code: r, text: text, text_lc: text.toLowerCase() })
						}
					}
				}

				arr.push(o)
			}
			console.log(arr)

			return arr
		},
	},
	created() {
		// DEBUG
		vapp.admin_users_component = this
	},
	mounted() {
	},
	methods: {
		load_admin_user_list() {
			// we must have all_courses loaded first; then we get the list of admins
			if (!this.all_courses_loaded) {
				this.$store.dispatch('get_all_courses').then(()=>{
					this.load_admin_user_list_2()
				}).catch(()=>{
					this.$alert('error in get_all_courses')
				})
			} else {
				this.load_admin_user_list_2()
			}
		},
		load_admin_user_list_2() {
			let payload = {
				user_id: this.user_info.user_id,
				user_identifier: 'all',
			}
			U.loading_start()
			U.ajax('admin_get_user_admin_rights', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				this.admin_users = result.admin_users
				// format for each admin_user: { user_id, email, first_name, last_name, rights["su", "lp.course.1345", etc.]}
			});
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			// check user name
			if (item.user_lc.search(re) > -1) return true

			// check lps and view_lps
			for (let lp of item.lps) {
				if (lp.text_lc.search(re) > -1) return true
			}
			for (let view_lp of item.view_lps) {
				if (view_lp.text_lc.search(re) > -1) return true
			}

			// check su
			if (search == 'su' && item.su) return true

			// if we get to here return false
			return false
		},

		edit_user(identifier) {
			this.user_being_edited = this.admin_users[identifier]
		},

		rights_edited(new_rights) {
			let payload = {
				user_id: this.user_info.user_id,
				user_identifier: this.user_being_edited.user_id,
				rights_arr: new_rights,
			}
			console.log(payload)

			U.loading_start()
			U.ajax('admin_update_admin_rights', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				this.user_being_edited.rights = new_rights
				this.user_being_edited = null
			});
		},

		new_user_clicked() {
			this.$prompt({
				title: 'Add New Admin User',
				text: 'Enter user email:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				acceptText: 'Add User',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')

				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x=>{this.new_user_clicked()})
					return
				}
				for (let identifier in this.admin_users) {
					if (this.admin_users[identifier].email == email) {
						this.search = email
						this.$alert('This user already has some admin rights. Click the edit button next to the user’s name to edit their rights.')
						return
					}
				}
				this.new_user_validate(email)
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		new_user_validate(email) {
			let payload = {
				user_id: this.user_info.user_id,
				email: email,
			}
			U.loading_start()
			U.ajax('admin_validate_email', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in admin ajax call'); vapp.ping(); return;
				}

				if (empty(result.user_id)) {
					this.$alert('We couldn’t find that email address in the system.').then(x=>{this.new_user_clicked()})
				} else {
					this.$set(this.admin_users, result.user_id+'', {
						user_id: result.user_id,
						email: result.email,
						first_name: result.first_name,
						last_name: result.last_name,
						rights: [],
					})
					this.user_being_edited = this.admin_users[result.user_id]
				}
			});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		},
	}
}
</script>

<style lang="scss">
</style>
