<template>
	<div @click="$emit('show_course', course, $event)" class="k-main-collection-item k-cv-lp-tile" :class="lp_css">
		<div class="k-cv-lp-tile__title" :class="lp_css_color">
			<div class="k-cv-lp-tile__title-title" v-html="lp_title"></div>
			<!-- <div class="k-cv-lp-tile__title-teacher" v-if="teacher_display&&!course_showing">{{teacher_display}}</div> -->
			<div v-if="teacher_name" class="k-cv-lp-tile__title-teacher">{{ teacher_name }}</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
		course: { type: Object, required: true },
		course_showing: { type: String, required: true },
		welcome_section_collection_types: { type: Array, required: true },
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'course_update_trigger']),
		teacher_name() {
			if (this.user_info.role !== 'parent') return ''
			const teacher = this.course?.teachers?.slice(0, 1)?.pop() ?? null
			if (!teacher) return ''
			if (teacher.familyName && teacher.givenName) return `${teacher.familyName}, ${teacher.givenName}${teacher.middleName ? ' ' + teacher.middleName : ''}`
			return ''
		},
		lp_css() {
			let s = ''
			if (this.course_showing) s += ' k-cv-lp-tile--course_showing'
			if (this.course_showing == this.course.course_code) s += ' k-cv-lp-tile--this_course_showing'
			// add a style determined by whether or not we're viewing the "tab" for the collection_type of this LP
			if (this.welcome_section_collection_types.includes(this.lp.collection_type)) {
				s += ' k-cv-lp-tile--in-welcome-section'
			} else {
				s += ' k-cv-lp-tile--not-in-welcome-section'
			}
			// legacy from old assignment system
			// s += ' k-cv-lp-tile--no-asns'
			return s
		},
		lp_css_color() {
			return U.subject_tile_css(this.lp)
		},
		lp() {
			return this.all_courses.find(c=>c.course_code == this.course.course_code)
		},
		lp_title() {
			// course.title
			let s = ''
			s += '<div>'
			// start with icon indicating collection type
			if (this.lp.collection_type == 'course') s += '<i style="font-size:20px; margin-right:8px;" class="fas fa-chalkboard"></i>'
			else if (this.lp.collection_type == 'repo') s += '<i style="font-size:20px; margin-right:8px;" class="fas fa-diagram-project"></i>'
			else if (this.lp.collection_type == 'pd') s += '<i style="font-size:20px; margin-right:8px;" class="fas fa-user-graduate"></i>'
			else s += '<i style="font-size:20px; margin-right:8px;" class="fas fa-cubes-stacked"></i>'
			// add <wbr> tags after slashes
			s += this.lp.title.replace(/\//g, '/<wbr>')
				if (this.lp.active == 'no') {
					s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'

					// s += ' <b class="red--text">[Inactive]</b>'
				}
			s += '</div>'

			return s
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {

	}
}
</script>

<style lang="scss">

.k-main-wrapper .k-main-collection-item.k-cv-lp-tile {
	width:190px;
	height:190px;
	border-radius:35px;
	text-align:left;
	padding:0;
	// margin:12px;
	flex-direction: row;
	align-items: center;
	// background-color:rgba(255,255,255, 0);

	.k-cv-lp-tile__title {
		flex:1 1 190px;
		display:flex;
		flex-direction: column;
		justify-content: space-around;
		height:190px;
		border-radius:35px;
		font-size:22px;
		line-height:28px;
		font-weight:bold;
		color:#fff;
		// color:#444;
		padding:15px;

		.k-cv-lp-tile__title-teacher {
			font-size:12px;
			line-height:17px;
			font-weight:normal;
		}
	}

	// .k-cv-lp-tile__extra {
	// 	flex:1 1 40px;
	// 	text-align:center;
	// 	height:190px;
	// 	width:40px;
	// 	align-self: center;
	//
	// 	.k-cv-lp-tile__extra-teacher {
	// 		padding-left:5px;
	// 	}
	//
	// 	.k-cv-lp-tile__extra-student {
	// 		transform: rotate(90deg);
	// 		white-space:nowrap;
	// 		padding-left:5px;
	//
	// 		.k-cv-lp-tile__extra-student--inner {
	// 			width:170px;
	// 			overflow:hidden;
	// 			text-overflow: ellipsis;
	// 			padding-left:20px;
	// 		}
	//
	// 		.k-cv-lp-tile-ha .k-cv-lp-tile-hatext:hover {
	// 			text-decoration:underline;
	// 		}
	//
	// 		.k-cv-lp-tile-hatext {
	// 			padding-left:5px;
	// 		}
	// 	}
	// }

	// .k-cvlp-unit__status {
	// 	display:inline-block;
	// 	font-size:16px;
	// 	width:28px;
	// 	height:28px;
	// 	line-height:28px;
	// 	border-radius:28px;
	// 	text-align:center;
	// 	font-weight:bold;
	// 	margin:0 1px;
	// 	transform:rotate(-90deg);
	// }
}

// .k-main-wrapper .k-main-collection-item.k-cv-lp-tile--no-asns {
// 	width:190px;
// 	.k-cv-lp-tile__extra {
// 		display:none;
// 	}
// }

.k-main-wrapper .k-main-collection-item.k-cv-lp-tile--course_showing {
	width:100px;
	height:100px;
	min-width:100px;
	border-radius:16px;
	opacity:0.6;
	margin:10px;
	background-color:#f8f8f8;
	-webkit-box-shadow: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;

	.k-cv-lp-tile__title {
		flex:1 1 100px;
		height:100px;
		border-radius:16px;
		font-weight:normal;
		font-size:12px;
		line-height:14px;
		padding:8px;
	}

	// .k-cv-lp-tile__extra {
	// 	flex:1 1 30px;
	// 	height:100px;
	// 	width:30px;
	//
	// 	.k-cv-lp-tile__extra-student {
	// 		.k-cv-lp-tile__extra-student--inner {
	// 			width:90px;
	// 			padding-left:10px;
	// 		}
	//
	// 		.k-cv-lp-tile-hatext {
	// 			display:none;
	// 		}
	// 	}
	// }

	.k-cvlp-unit__status {
		font-size:10px;
		width:18px;
		height:18px;
		line-height:18px;
		border-radius:18px;
		margin:0px;
	}

}

// .k-main-wrapper .k-main-collection-item.k-cv-lp-tile--course_showing.k-cv-lp-tile--no-asns {
// 	width:100px;
// }


.k-main-wrapper .k-main-collection-item.k-cv-lp-tile--this_course_showing {
	font-weight:bold;
	background-color:#eee;
	opacity:1.0;
	-webkit-box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;

	.k-cv-lp-tile__title {
		font-weight:bold;
	}
}

.k-cv-lp-tile-caught-up {
	color:#555;
}

.k-cv-lp-tile--in-welcome-section {
	// border:3px solid #000;
}
.k-cv-lp-tile--not-in-welcome-section {
	opacity:0.3;
	box-shadow:none!important;
}
</style>
