class Resource {
	constructor(data) {
		if (empty(data)) data = {}

		// generate a new resource_id if needed
		if (data.resource_id == 'new') this.resource_id = U.new_uuid()
		else if (!empty(data.resource_id)) this.resource_id = data.resource_id
		else {
			console.log('Resource created with no resource_id')
			// this.resource_id = U.new_uuid()
		}

		// resource_template_id: if set, this resource is based on the resource that has resource_id == resource_template_id; see ResourceCollectionItem
		sdp(this, data, 'resource_template_id', '')

		sdp(this, data, 'agency_sanctioned', false)

		// we may retrieve a resource's lti_form from the get_resource_record service
		sdp(this, data, 'lti_form', '')

		sdp(this, data, 'type', 'none', ['none', 'upload', 'html', 'website', 'document', 'video', 'interactive', 'safari', 'lti', 'resource_collection', 'collection_item', 'assessment', 'sparkl'])
		// a 'resource_collection' is an externally-defined collection -- e.g. a publisher's textbook resources, loaded via common cartridge

		sdp(this, data, 'url', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'long_description', '')
		sdp(this, data, 'lti_params', {})
		sdp(this, data, 'case_identifiers', [])
		sdp(this, data, 'target_students', 'all', ['all', 'ese', 'adv'])
		// 'lp_category' isn't really about the lp; it's a legacy thing from HenryConnects. We should change this to an array of metadata values...
		sdp(this, data, 'lp_category', '')	// unit_planning, leader_resource, course_guidance, etc.
		sdp(this, data, 'cross_unit', false)	
		sdp(this, data, 'creator', 0)
		sdp(this, data, 'shareable', true)	// is this resource shareable? (not currently used for anything)
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'mappings', [])	// this will be an array of values such as 'course-1234', 'grade-4', or 'subject-Math'
		sdp(this, data, 'todo', false)		// is this resource marked as a "todo" item for users (i.e. for professional development)?

		// standards stored like lessons
		this.standards = []
		if (!empty(data.standards)) {
			for (let standard of data.standards) {
				// for now at least we'll use the learning progression CASE_Item structure for standards; this is a bit simpler than the full CFItem structure
				this.standards.push(new CASE_Item(standard))
			}
		}
		
		sdp(this, data, 'stars_available', -1)	// this should be filled in for sparkl activities (?)

		if (!empty(data.lti_params)) {
			// some legacy HMH resources were saved without setting district_sanctioned and/or teacher_facing properly, so...
			// default value for district_sanctioned is true for lti links
			sdp(this, data, 'district_sanctioned', true)

			// and if lti_params.custom_resource_url has "/teacher/" in it, default value for teacher_facing is true (otherwise false)
			if (!empty(data.lti_params.custom_resource_url) && data.lti_params.custom_resource_url.search(/\/teacher\b/)) {
				sdp(this, data, 'teacher_facing', true)
			} else {
				sdp(this, data, 'teacher_facing', false)
			}

		} else {
			// if not an LTI link, default value for district_sanctioned is false and default value for teacher_facing is false
			sdp(this, data, 'district_sanctioned', false)
			sdp(this, data, 'teacher_facing', false)
			// caveat: assessments are always teacher-facing
			if (this.type == 'assessment') this.teacher_facing = true
		}
		// resource is district-sanctioned if it's added in a LP

		// supplemental urls, e.g. for assessments (blueprints and paper copies)
		this.supp_links = []
		if (!empty(data.supp_links)) {
			for (let sl of data.supp_links) {
				let o = {}
				sdp(o, sl, 'description', '')
				sdp(o, sl, 'type', '')
				sdp(o, sl, 'url', '')
				this.supp_links.push(o)
			}
		}

		// temp things that we delete before saving
		sdp(this, data, 'full_resource_data_loaded', false)
		sdp(this, data, 'quick_look_showing', false)
		sdp(this, data, 'search_match', false)
	}

	copy_for_save() {
		let o = $.extend(true, {}, this)
		delete o.editing
		delete o.quick_look_showing
		delete o.created_at
		delete o.lti_form
		delete o.full_resource_data_loaded
		delete o.search_match

		// set shareable and professional_development and todo to 1 or 0
		o.shareable = (this.shareable) ? 1 : 0
		o.todo = (this.todo) ? 1 : 0
		o.professional_development = (this.professional_development) ? 1 : 0
		return o
	}

	// return the fontawesome icon class that should go with this resource type. this should be paired with class `fas`
	icon() {
		if (this.type == 'website') {
			// for google drive links, return generic document icon
			if (this.url.search(/(drive|docs)\.google/) > -1) return 'fa-file'
			let ext = this.url.replace(/.*?\.(\w+)$/, '$1')
			if (ext == 'pdf') return 'fa-file-pdf'
			if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			if (ext.indexOf('doc') == 0) return 'fa-file-word'
			if (ext.indexOf('ppt') == 0) return 'fa-file-powerpoint'
			return 'fa-link'
		}
		if (this.type == 'assessment') return 'fa-clipboard-list'	// list-alt / list-ol
		if (this.type == 'video' || this.type == 'interactive') return 'fa-video'
		if (this.type == 'sparkl') return 'fa-star'
		if (this.type == 'upload') {
			let ext = this.url.replace(/.*?\.(\w+)$/, '$1')
			if (ext == 'pdf') return 'fa-file-pdf'
			if (ext == 'jpg' || ext == 'jpeg' || ext == 'gif' || ext == 'pdf') return 'fa-file-image'
			if (ext.indexOf('doc') == 0) return 'fa-file-word'
			if (ext.indexOf('ppt') == 0) return 'fa-file-powerpoint'
		}
		if (this.type == 'html') return 'fa-file-alt'
		if (this.type == 'folder') return 'fa-folder'
		return 'fa-file'
	}

	type_label() {
		if (this.type == 'upload') return 'Uploaded File'
		if (this.type == 'html') return 'Text'
		if (this.type == 'website') return 'Website'
		if (this.type == 'document') return 'Document'
		if (this.type == 'video') return 'Video'
		if (this.type == 'interactive') return 'Interactive'
		if (this.type == 'safari') return 'Safari Resource'
		if (this.type == 'lti') return 'LTI Resource'
		if (this.type == 'resource_collection') return 'Resource Collection'
		if (this.type == 'collection_item') return 'Collection Item'
		if (this.type == 'assessment') return 'Assessment'
		if (this.type == 'sparkl') return 'Sparkl Activity'
		return ('unknown type')
	}

	has_openable_url() {
		// if we don't have a url obviously we can't open
		if (empty(resource.url)) return false

		// lti, assessment resources, and sparkl activities don't open directly as links
		if (resource.type == 'lti' || resource.type == 'assessment' || resource.type == 'sparkl') return false

		// if we get to here, assume the url is openable
		return true
	}

	// /course/41.0140/0
	full_url() {
		if (this.type == 'upload' || this.type == 'html') {
			// if the uploaded file or html has been sent to edelex, it will be a fully-qualified url
			if (this.url.includes('https:')) return this.url

			// otherwise construct from base
			let base = sr('$1//$2/user-files/', location.protocol, location.host)
			return base + this.url

		} else if (this.type == 'sparkl') {
			// for now set sparkl links to use sparkl-ed.com, though we'll open with LTI
			return 'https://sparkl-ed.com/' + this.url

		} else {
			return this.url
		}
	}

	// return true iff this resource is visible by the given user role; if role not specified, use state role
	// note that callers of this fn should check themselves for whether or not the user is an editor for the collection, as collection editors can always see everything
	is_visible_by_user(role) {
		// if show_all_items_when_not_signed_in is 'yes', everyone gets to see everything
		if (vapp.site_config.show_all_items_when_not_signed_in == 'yes') return true

		// for lti/collection_item items, which we assume (at least for now) are publisher-supplied
		if (this.type == 'collection_item' || this.type == 'lti') {
			// don't show if not signed in
			if (!vapp.$store.getters.signed_in) return false
		}

		if (empty(role)) role = vapp.$store.getters.role

		// student-facing (non-teacher_facing) items are available to everyone
		if (!this.teacher_facing) return true

		// parents and students can't see anything else
		if (role == 'student' || role == 'parent') return false

		// items marked as leader_resource can only be viewed by admins and principals/assistant principals (not "regular" teachers)
		if (this.lp_category == 'leader_resource') {
			return (role == 'admin' || vapp.$store.getters.user_is_principal_or_ap)
		}

		// if we get to here the user can see the resource
		return true
	}

}
window.Resource = Resource

// standard sorting algorithm for resources: arr.sort(U.resources_sort)
U.resources_sort = function(a,b) {
	// teacher-facing on top
	if (a.teacher_facing && !b.teacher_facing) return -1
	if (b.teacher_facing && !a.teacher_facing) return 1

	// district_sanctioned above non-district_sanctioned
	if (a.district_sanctioned && !b.district_sanctioned) return -1
	if (b.district_sanctioned && !a.district_sanctioned) return 1

	// unit planning guides on top
	let a_planning = (a.description.search(/sample.unit/i) > -1 || a.description.search(/document.set/i) > -1) ? 1 : 0
	let b_planning = (b.description.search(/sample.unit/i) > -1 || b.description.search(/document.set/i) > -1) ? 1 : 0
	let dif = b_planning - a_planning
	if (dif != 0) return dif

	// for student resources, order by target_students: 'all', then 'ese', then 'adv'
	if (!a.teacher_facing && a.target_students != b.target_students) {
		if (a.target_students == 'all' && b.target_students != 'all') return -1
		if (b.target_students == 'all' && a.target_students != 'all') return 1
		// if we get here, neither a nor b is 'all', and they're different, so one must be ese and one must be adv

		if (a.target_students == 'adv') return -1
		else return 1
	}

	// if we get to here, order alphabetically by description
	if (a.description < b.description) return -1
	if (b.description < a.description) return 1
	return 0
}
