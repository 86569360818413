<template>
	<v-dialog v-model="dialog_open" max-width="600" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b v-html="resource.description"></b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="mb-3 text-center">Assessment Resources:</div>
				<div v-if="blueprint_link||paper_link" class="mb-3 d-flex justify-center flex-wrap">
					<!-- TEMP: these links won't work for now -->
					<v-btn v-if="blueprint_link" small color="pink accent-4" dark class="ma-1 k-tight-btn" target='_blank'><v-icon small class="mr-2">fas fa-map</v-icon>Assessment Blueprint<v-icon x-small class="ml-1" color="#999">fas fa-ban</v-icon></v-btn>
					<v-btn v-if="paper_link" small color="pink accent-4" dark class="ma-1 k-tight-btn" target='_blank'><v-icon small class="mr-2">fas fa-file-alt</v-icon>Teacher Test Booklet<v-icon x-small class="ml-1" color="#999">fas fa-ban</v-icon></v-btn>
					<!-- <v-btn v-if="blueprint_link" small color="pink accent-4" dark class="ma-1 k-tight-btn" target='_blank' :href="blueprint_link.url"><v-icon small class="mr-2">fas fa-map</v-icon>Assessment Blueprint</v-btn>
					<v-btn v-if="paper_link" small color="pink accent-4" dark class="ma-1 k-tight-btn" target='_blank' :href="paper_link.url"><v-icon small class="mr-2">fas fa-file-alt</v-icon>Teacher Test Booklet</v-btn> -->
				</div>
				<div class="text-center"><v-btn color="pink accent-4" dark class="k-tight-btn" target='_blank' :href="illuminate_link"><v-icon small class="mr-2">fas fa-clipboard-list</v-icon>Open Assessment in Illuminate</v-btn></div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn small color="secondary" target="_blank"><v-icon small class="mr-2">fas fa-tools</v-icon>Assessment Toolkit<v-icon x-small class="ml-1" color="#999">fas fa-ban</v-icon></v-btn>
				<!-- <v-btn small color="secondary" target="_blank" :href="assessment_toolkit_url"><v-icon small class="mr-2">fas fa-tools</v-icon>Assessment Toolkit</v-btn> -->
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done <v-icon small class="ml-2">fas fa-check</v-icon></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		resource: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		assessment_toolkit_url: 'https://sites.google.com/henry.k12.ga.us/performance/home',	// ?authuser=0
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		paper_link() { return this.resource.supp_links.find(x=>x.type == 'paper') },
		blueprint_link() { return this.resource.supp_links.find(x=>x.type == 'blueprint') },
		illuminate_link() {
			// for illuminate assessments the url contains the assessment_id; use this format to link to the assessment:
			return sr('https://henry.illuminateed.com/live/?assessment_id=$1&page=Assessment_DetailsController&overview_redirect=1', this.resource.url)
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
